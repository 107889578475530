@use "mixin";

/*----------------------------------------------------
MV
------------------------------------------------------*/
// スライダー
.top-mv {
  .swiper-area {
    margin: 0 auto;
    position: relative;
  }

  .swiper {
    width: 100%;
  }

  .mvSwiper .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    position: absolute;
    top: 220px;
    bottom: 0;
    right: 40px;
    margin: auto 0 auto auto;
    z-index: 1;

    width: 32px;
    @include mixin.flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;

    @include mixin.pcXxl {
      top: 0;
      height: 316px;
    }

    @include mixin.tab {
      top: calc(82% - 500px);
      bottom: inherit;
      // margin: 0;
      right: 15px;
      height: auto;
      justify-content: flex-start;
    }

    @include mixin.tabXs {
      left: 15px;
    }

    @include mixin.sp {
      top: 490px;
      right: 0;
      left: inherit;
      background-color: rgba(218, 238, 246, 0.4);
      border: 1px solid rgba(mixin.$mainWhite, 0.4);
      border-right-color: rgba(mixin.$mainWhite, 0.2);
      border-bottom-color: rgba(mixin.$mainWhite, 0.2);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      border-radius: 10px 0 0 10px;
      z-index: 1;

      padding: 15px 120px 15px 30px;
      width: 80vw;
      flex-direction: row;
      margin: 0;
    }
  }

  /* 円形のプログレスバー */
  .swiper-pagination {
    isolation: isolate;
  }

  .swiper-pagination-bullet.circle {
    position: relative;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: rgba(mixin.$mainWhite, 0.2);

    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    margin: auto;
    cursor: pointer;
    opacity: 1;

    box-shadow: inset 0 0 4px 0 rgba(mixin.$mainWhite, 0.5), inset 0 0 2px 0 rgba(mixin.$mainWhite, 0.5), inset 0 0 1px 0 rgba(mixin.$mainWhite, 0.5), 0 0 4px 0 rgba(mixin.$mainWhite, 0.5);
  }

  .swiper-pagination-bullet.circle .circle-svg {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(-90deg);

    .circle01 {
      stroke: mixin.$mainBlue;
      stroke-width: 2px;
      fill: none;
    }

    .circle02 {
      stroke: mixin.$mainWhite;
      stroke-width: 3px;
      fill: none;
      visibility: hidden;
    }
  }

  .circle .circle_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    color: mixin.$mainBlue;

    text-shadow: 0 0 8px mixin.$mainWhite, 0 0 6px mixin.$mainWhite, 0 0 4px mixin.$mainWhite;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active.circle {
    .circle_inner {
      color: mixin.$mainWhite;
      text-shadow: none;
    }

    .circle02 {
      animation: mv-circle linear both;
      animation-duration: 14000ms;
      visibility: visible;
      stroke-dasharray: 201.056px;
      stroke-dashoffset: 201.056px;
    }
  }

  @keyframes mv-circle {
    100% {
      stroke-dashoffset: 0;
    }
  }

  /* スライド アクティブ時 */
  .swiper-slide {
    z-index: 1;

    &[class*="-visible"] {
      z-index: 2;
    }

    &[class*="-active"] {
      img {
        transition-delay: 0s;
        transform: translateX(1.5%) scale(1.05);
      }
    }

    img {
      transition: 7s 1s linear;
      transform: translateX(-1.5%) scale(1.1);
      opacity: 1;
    }

    .title-lead,
    .title,
    .lead,
    .button-common {
      animation: 4s cubic-bezier(0.2, 1, 0.2, 1) both;
      // opacity: 0;
    }

    .title {
      animation-delay: 0.2s;
    }

    .lead {
      animation-delay: 0.4s;
    }

    .button-common {
      animation-delay: 0.6s;
    }

    &.anm-started {
      .title-lead,
      .title,
      .lead,
      .button-common {
        animation-name: mvSwiper-fadeIn;
      }
    }

    &.anm-finished {
      .title-lead,
      .title,
      .lead,
      .button-common {
        animation-name: mvSwiper-fadeOut;
      }
    }
  }

  @keyframes mvSwiper-fadeOut {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-3rem);
      transform: translateY(-3rem);
      opacity: 0;
    }
  }

  @keyframes mvSwiper-fadeIn {
    0% {
      -webkit-transform: translateY(3rem);
      transform: translateY(3rem);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.top-mv {
  position: relative;
  margin-bottom: 80px;

  @include mixin.sp {
    background-color: mixin.$mainBlue;
  }

  .swiper {
    clip-path: polygon(100% 0, 100% 72%, calc(50% - 608px) 100%, 0 82%, 0 0);
    overflow: hidden;

    @include mixin.pcLg {
      clip-path: polygon(100% 0, 100% 72%, calc(50% - 568px) 100%, 0 88%, 0 0);
    }

    @include mixin.pc {
      clip-path: polygon(100% 0, 100% 72%, 50px 100%, 0 88%, 0 0);
    }

    @include mixin.pcSm {
      clip-path: polygon(100% 0, 100% 82%, 50px 100%, 0 92%, 0 0);
    }

    @include mixin.tab {
      clip-path: polygon(100% 0, 100% 82%, 38px 100%, 0 94%, 0 0);
    }
  }

  .swiper-slide {
    position: relative;
    width: 100%;
    height: auto;

    .mv-contants {
      position: absolute;
      // top: 220px;
      top: 0;
      bottom: 100px;
      left: calc(50% - 600px);
      margin: auto 0;
      z-index: 2;
      pointer-events: auto;
      height: fit-content;

      @include mixin.flex;
      justify-content: flex-start;
      flex-direction: column;

      @include mixin.pcXxl {
        top: 0;
        justify-content: center;
      }

      @include mixin.pcXl {
        left: calc(50% - 568px);
      }

      @include mixin.pc {
        left: 120px;
      }

      @include mixin.tab {
        top: inherit;
        bottom: 22%;
        left: 40px;
        margin: 0 auto;
        justify-content: center;
      }

      @include mixin.tabXs {
        left: 0;
        right: 0;
        width: calc(100% - 30px);
        align-items: flex-start;
      }

      @include mixin.sp {
        bottom: 24%;
      }

      .inner {
        // height: 316px;
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        @include mixin.tab {
          height: auto;
        }

        .title-lead,
        .title-wrap {
          margin-bottom: 16px;
        }

        .title {
          font-size: 3.4rem;

          @include mixin.pcXl {
            font-size: 2.8rem;
          }

          @include mixin.tabSm {
            font-size: 2.4rem;
          }
        }

        .lead {
          margin-bottom: 32px;
        }
      }

      * {
        color: mixin.$mainWhite;
      }
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 24/11;
      min-height: 768px;

      @include mixin.pc {
        height: 56vw;
      }

      @include mixin.tab {
        max-height: inherit;
        min-height: 604px;
        height: 96vh;
      }

      @include mixin.sp {
        min-height: inherit;
        height: 604px;
      }
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      background: linear-gradient(147deg, rgba(36, 67, 119, 0.5) 0%, rgba(101, 123, 159, 0.2) 100%) 0% 0% no-repeat;
    }
  }

  .scrolldown1 {
    /*描画位置※位置は適宜調整してください*/
    position: absolute;
    left: calc(50% - 616px);
    bottom: 64px;
    /*全体の高さ*/
    height: 100px;
    z-index: 1;

    @include mixin.pcLg {
      left: calc(50% - 576px);
      bottom: 40px;
    }

    @include mixin.pc {
      left: 44px;
    }

    @include mixin.tab {
      left: 30px;
    }

    @include mixin.sp {
      bottom: inherit;
      top: 490px;
    }
  }

  /*Scrollテキストの描写*/
  .scrolldown1 {
    width: 15px;
  }

  .scrolldown1 span {
    /*テキストの形状*/
    color: mixin.$mainWhite;
    font-size: 1rem;
    letter-spacing: 0.05em;
    /*縦書き設定*/
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
  }

  /* 線の描写 */
  .scrolldown1::after {
    content: "";
    /*描画位置*/
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    margin: auto;

    /*線の形状*/
    width: 1px;
    height: 48px;
    background-color: rgba(mixin.$mainWhite, 0.25);
    animation: pathmove 3s cubic-bezier(0.17, 0.67, 0.8, 0.98) infinite;
  }

  /*高さ・位置・透過が変化して線が上から下に動く*/
  @keyframes pathmove {
    0% {
      transform: scale(1, 0);
      transform-origin: 0 0;
    }

    50% {
      transform: scale(1, 1);
      transform-origin: 0 0;
    }

    50.1% {
      transform: scale(1, 1);
      transform-origin: 0 100%;
    }

    100% {
      transform: scale(1, 0);
      transform-origin: 0 100%;
    }
  }
}

_::-webkit-full-page-media,
_:future,
:root .text {
  .top-mv .scrolldown1::after {
    left: 7.5px;
  }
}

/* フェードモード時 共通調整 */
.swiper-fade {
  .swiper-slide {
    -webkit-transition-property: opacity, -webkit-transform !important;
    transition-property: opacity, -webkit-transform !important;
    transition-property: opacity, transform !important;
    transition-property: opacity, transform, -webkit-transform !important;
  }
}

/*----------------------------------------------------
update
------------------------------------------------------*/
.update-wrap {
  position: absolute;
  top: 72%;
  right: 0;
  z-index: 1;

  @include mixin.pcSm {
    top: 82%;
  }

  @include mixin.sp {
    position: relative;
  }
}

.update {
  width: 64vw;
  padding: 0 calc(50vw - 640px) 24px 80px;

  background-color: rgba(218, 238, 246, 0.2);
  border: 1px solid mixin.$mainWhite;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  border-radius: 10px 0 0 10px;

  @include mixin.pc1728 {
    padding-bottom: 16px;
    width: 60vw;
  }

  @include mixin.pcXl {
    padding: 0 calc(50vw - 640px) 16px 60px;
  }

  @include mixin.pcLg {
    padding: 16px 40px;
  }

  @include mixin.pc {
    padding: 16px 20px 16px 40px;
    width: 56vw;
  }

  @include mixin.pcSm {
    width: 80vw;
    // top: 82%;
  }

  @include mixin.tab {
    padding: 15px 15px 15px 30px;
  }

  @include mixin.sp {
    position: relative;
    width: 100%;
    padding: 15px;
    border-radius: 0 0;
    background-color: mixin.$mainBlue;
    border-color: mixin.$mainBlue;
  }
}

.update-item {
  width: 100%;
  @include mixin.flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px 20px;
  padding: 12px 20px 8px;
  background-color: mixin.$mainWhite;
  border: 1px solid mixin.$mainLightGray;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  @include mixin.pc {
    padding: 4px 20px;
    gap: 5px 10px;
  }

  @include mixin.tab {
    gap: 5px;
  }

  .inner {
    width: 100%;
    > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  > p,
  span {
    @include mixin.flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  strong,
  b {
    min-width: 140px;

    @media (max-width: 1300px) {
      width: fit-content;
    }

    // @include mixin.pcSm {
    //     width: fit-content;
    // }

    // @include mixin.tabSm {
    //     width: 100%;
    // }

    + span,
    + a {
      display: inline-block;
      max-width: calc(100% - 160px);

      @media (max-width: 1300px) {
        width: 100%;
        max-width: 100%;
      }

      @include mixin.pcSm {
        max-width: calc(100% - 160px);
      }

      @include mixin.tabSm {
        max-width: 100%;
      }
    }
  }

  a {
    display: inline-block;

    &::after {
      position: relative;
      content: "";
      top: 4px;
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(../images/icon/icon-allow01-blue.svg);
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

:where(body) {
  &.en {
    .update-item {
      strong,
      b {
        + span,
        + a {
          max-width: calc(100% - 180px);

          @media (max-width: 1300px) {
            max-width: 100%;
          }

          @include mixin.pcSm {
            max-width: calc(100% - 180px);
          }

          @include mixin.tabSm {
            max-width: 100%;
          }
        }
      }
    }
  }
}

/*----------------------------------------------------
Related Company
------------------------------------------------------*/
.related-company {
  position: relative;

  &::before {
    content: "";
    display: block;
    max-width: 1280px;
    width: 100%;
    height: 1px;
    margin: 0 auto;
    background-color: mixin.$mainBlue;

    @include mixin.pcLg {
      width: calc(100% - 80px);
    }

    @include mixin.pc {
      width: calc(100% - 40px);
    }

    @include mixin.tab {
      width: calc(100% - 30px);
    }
  }

  .inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .flex-area {
    max-width: 1280px;
    margin: 0 auto;

    @include mixin.flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    @include mixin.tab {
      justify-content: center;
      gap: 20px 10px;
    }

    > * {
      width: calc(25% - 20px);

      @include mixin.pcSm {
        width: calc(50% - 10px);
      }

      @include mixin.sp {
        width: 100%;
      }
    }
  }
}

.button-company {
  position: relative;
  @include mixin.flex;
  justify-content: center;
  width: 100%;
  max-width: 310px;
  height: 80px;
  padding: 0 32px;
  border-radius: 10px;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: mixin.$mainWhite;
  border: 1px solid mixin.$mainBlue;

  @include mixin.pcSm {
    max-width: 100%;
  }

  &:hover {
    outline: 1px solid mixin.$mainBlue;
    box-shadow: none;
  }

  .title {
    color: mixin.$darkBlue;
    font-weight: 700;
  }

  img {
    width: 80%;
    height: 70%;
    object-fit: contain;
  }

  .text-more {
    position: absolute;
    @include mixin.flex;
    justify-content: flex-start;
    gap: 8px;
    right: 14px;
    bottom: 5px;
    font-size: 1.4rem;

    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-image: url(../images/icon/icon-external.svg);
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.mgc {
    img {
      width: 50%;
    }
  }

  &.gpac {
    img {
      width: 60%;
    }
  }

  &.mti {
    img {
      width: 90%;
    }

    &-en {
      padding: 0 16px;

      img {
        width: 100%;
      }
    }
  }
}

:where(body) {
  &.en {
    .button-company {
      &.mcm {
        .title {
          text-align: center;
        }
      }
    }
  }
}

/*----------------------------------------------------
top-main
------------------------------------------------------*/
.top-main {
  position: relative;

  .top-block01 {
    @include mixin.articleBody;
    @include mixin.flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    padding-bottom: 160px;

    @include mixin.pcLg {
      padding-bottom: 40px;
    }

    @include mixin.pc {
      padding-bottom: 24px;
    }

    @include mixin.tab {
      padding-bottom: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 140px;

      @include mixin.pcSm {
        margin-bottom: 160px;
      }

      @include mixin.tab {
        margin-bottom: 120px;
      }
    }

    .image {
      position: absolute;
      bottom: 0;
      // right: -140px;
      right: -200px;
      z-index: -1;

      width: 50vw;
      height: 50vw;
      max-width: 800px;
      max-height: 800px;

      @include mixin.pc1728 {
        // right: -100px;
        // right: -80px;
      }

      @include mixin.pcXxl {
        // right: -60px;
        right: -160px;
      }

      @include mixin.pcXl {
        right: -40px;
        max-width: 600px;
        max-height: 600px;
      }

      @include mixin.pcLg {
        right: -20px;
      }

      @include mixin.pc {
        bottom: inherit;
        top: 75px;
      }

      @include mixin.sp {
        top: 10px;
      }

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;

        image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .description {
      width: 100%;
      @include mixin.flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .headingOne02 {
        margin-bottom: 24px;
      }

      .lead {
        margin-bottom: 80px;
        max-width: 700px;
        width: 100%;
        text-shadow: 0 0 3px mixin.$mainWhite, 0 0 2px mixin.$mainWhite, 0 0 6px mixin.$mainWhite, 0 0 6px mixin.$mainWhite;

        @include mixin.pc {
          margin-bottom: 64px;
        }

        @include mixin.pcSm {
          margin-bottom: 40px;
        }
      }

      .search-products-area {
        width: 100%;
        display: grid;
        gap: 10px 20px;
        grid-template-columns: repeat(2, 1fr);

        @include mixin.tab {
          gap: 20px;
          grid-template-columns: 1fr;
        }

        .search-products {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 3;

          @include mixin.tab {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
        }

        .search-youto {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;

          @include mixin.tab {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
          }
        }

        .search-kinou {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;

          @include mixin.tab {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;
          }
        }
      }
    }

    &.reverse {
      align-items: flex-end;

      .image {
        right: initial;
        // left: -140px;
        left: -200px;

        @include mixin.pc1728 {
          // left: -100px;
        }

        @include mixin.pcXxl {
          left: -60px;
          left: -160px;
        }

        @include mixin.pcXl {
          left: -40px;
        }

        @include mixin.pcLg {
          left: -20px;
        }
      }

      .description {
        align-items: flex-end;
        text-align: right;

        @include mixin.sp {
          text-align: left;
        }

        .headingOne02 {
          &::before {
            left: initial;
            right: 16px;

            @include mixin.tab {
              left: initial;
              right: 14px;
            }

            @include mixin.sp {
              left: initial;
              right: 2px;
            }
          }

          &::after {
            left: initial;
            right: 0;
          }
        }

        .headingOne02-2 {
          &::before {
            left: initial;
            right: 16px;

            @include mixin.pcLg {
              left: initial;
              right: 6px;
            }

            @include mixin.tab {
              left: initial;
              right: 4px;
            }

            @include mixin.sp {
              left: initial;
              right: 2px;
            }
          }

          &::after {
            left: initial;
            right: 0;
          }
        }
      }
    }

    &.products {
      padding-bottom: 72px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        // width: calc(var(--vw) * 100);
        width: 100vw;
        height: 58.6vw;
        margin: 0 calc(50% - 50vw);
        top: -8.3vw;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        background-image: url(../images/top/bg-top01.svg);
        background-repeat: no-repeat;
        background-size: calc(var(--vw) * 100) 58.6vw;
        background-position: center top;
      }

      @include mixin.pcSm {
        padding-bottom: 24px;
      }

      .image {
      }

      .button-search {
        background-color: rgba(mixin.$mainWhite, 0.48);

        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        &:hover {
          background-color: rgba(mixin.$mainWhite, 0.8);
        }
      }
    }

    &.environment {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        // width: calc(var(--vw) * 100);
        width: 100vw;
        height: 33.333vw;
        margin: 0 calc(50% - 50vw);
        top: 15vw;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        background-image: url(../images/top/bg-top02.svg);
        background-repeat: no-repeat;
        background-size: calc(var(--vw) * 100) 33.333vw;
        background-position: center top;
      }

      .image {
      }
    }

    &.technology {
      margin-bottom: 240px;
      position: relative;

      @include mixin.pc {
        margin-bottom: 200px;
      }

      @include mixin.pcSm {
        margin-bottom: 160px;
      }

      @include mixin.tab {
        margin-bottom: 120px;
      }

      &::before {
        content: "";
        position: absolute;
        // width: calc(var(--vw) * 100);
        width: 100vw;
        height: 100%;
        margin: 0 calc(50% - 50vw);
        background: linear-gradient(180deg, rgba(mixin.$bgBlue, 0) 0%, rgba(mixin.$bgBlue, 0.4) 100%) 0% 0% no-repeat;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        clip-path: polygon(100% 0, 100% 50%, calc(50% - 618px) 100%, 0 59%, 0 0);
        overflow: hidden;

        @include mixin.pcLg {
          clip-path: polygon(100% 0, 100% 50%, calc(50% - 580px) 100%, 0 64%, 0 0);
        }

        @include mixin.pc {
          clip-path: polygon(100% 0, 100% 50%, 40px 100%, 0 64%, 0 0);
        }

        @include mixin.pcSm {
          clip-path: polygon(100% 0, 100% 59%, 40px 100%, 0 78%, 0 0);
        }

        @include mixin.tab {
          clip-path: polygon(100% 0, 100% 59%, 28px 100%, 0 83%, 0 0);
        }
      }

      .image {
        @include mixin.pcXl {
          max-width: 720px;
          max-height: 720px;
          width: 60vw;
          height: 60vw;
        }

        @include mixin.pcLg {
          right: -40px;
          top: 50px;
        }

        // @include mixin.pc {
        //     top: 50px;
        // }
      }
    }
  }

  .top-block02 {
    @include mixin.articleBody;

    &:not(:last-child) {
      margin-bottom: 240px;

      @include mixin.pc {
        margin-bottom: 200px;
      }

      @include mixin.pcSm {
        margin-bottom: 160px;
      }

      @include mixin.tab {
        margin-bottom: 120px;
      }
    }

    > *:not(:last-child) {
      margin-bottom: 40px;

      @include mixin.tab {
        margin-bottom: 20px;
      }
    }

    .contents-area {
      > *:not(:last-child) {
        margin-bottom: 32px;

        @include mixin.tab {
          margin-bottom: 16px;
        }
      }
    }

    &.search {
      position: relative;

      // &::before {
      //     content: "";
      //     position: absolute;
      //     // width: calc(var(--vw) * 100);
      //     width: 100vw;
      //     height: 125%;
      //     margin: 0 calc(50% - 50vw);
      //     background: linear-gradient(180deg, rgba(mixin.$bgBlue, 0) 0%, rgba(mixin.$bgBlue, 0.4) 100%) 0% 0% no-repeat;
      //     top: 0;
      //     bottom: 0;
      //     left: 0;
      //     right: 0;
      //     z-index: -1;

      //     clip-path: polygon(100% 0, 100% 50%, calc(50% - 618px) 100%, 0 59%, 0 0);
      //     overflow: hidden;

      //     @include mixin.pcLg {
      //         clip-path: polygon(100% 0, 100% 50%, calc(50% - 580px) 100%, 0 64%, 0 0);
      //     }

      //     @include mixin.pc {
      //         clip-path: polygon(100% 0, 100% 50%, 40px 100%, 0 64%, 0 0);
      //     }

      //     @include mixin.pcSm {
      //         clip-path: polygon(100% 0, 100% 59%, 40px 100%, 0 78%, 0 0);
      //     }

      //     @include mixin.tab {
      //         clip-path: polygon(100% 0, 100% 59%, 28px 100%, 0 83%, 0 0);
      //     }
      // }
      &::before {
        content: "";
        position: absolute;
        // width: calc(var(--vw) * 100);
        width: 100vw;
        height: 33.333vw;
        margin: 0 calc(50% - 50vw);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        background-image: url(../images/top/bg-top03.svg);
        background-repeat: no-repeat;
        background-size: calc(var(--vw) * 100) 33.333vw;
        background-position: center top;

        @include mixin.tabSm {
          margin: auto calc(50% - 50vw);
        }
      }

      .contents-area {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 20px;

        @include mixin.tab {
          grid-template-columns: 1fr;
        }

        @include mixin.tabSm {
          font-size: 1.6rem;

          a.allow01 {
            font-size: 1.4rem;
          }
        }

        .input-area {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;

          @include mixin.tab {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
        }

        .button-search:nth-child(2) {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;

          @include mixin.tab {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
          }
        }

        .button-search:nth-child(3) {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;

          @include mixin.tab {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;
          }
        }

        .list-common {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 4;

          @include mixin.tab {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 5;
          }
        }
      }
    }

    // &.news {
    //     position: relative;

    //     &::before {
    //         content: '';
    //         position: absolute;
    //         // width: calc(var(--vw) * 100);
    //         width: 100vw;
    //         height: 33.333vw;
    //         margin: 0 calc(50% - 50vw);
    //         top: 0;
    //         bottom: 0;
    //         left: 0;
    //         right: 0;
    //         z-index: -1;

    //         background-image: url(../images/top/bg-top03.svg);
    //         background-repeat: no-repeat;
    //         background-size: calc(var(--vw) * 100) 33.333vw;
    //         background-position: center top;

    //         @include mixin.tabSm {
    //             margin: auto calc(50% - 50vw);
    //         }
    //     }

    //     .title-area {
    //         align-items: flex-end;

    //         display: grid;
    //         grid-template-columns: 180px 1fr 140px;
    //         gap: 20px 80px;

    //         @include mixin.pcXl {
    //             grid-template-columns: 144px 1fr 140px;
    //         }

    //         @include mixin.pc {
    //             gap: 20px 40px;
    //         }

    //         @include mixin.pcSm {
    //             grid-template-columns: 1fr 140px;
    //         }

    //         .headingTwo02 {
    //             width: 180px;

    //             @include mixin.pcXl {
    //                 width: 144px;
    //             }

    //             @include mixin.pcSm {
    //                 grid-column-start: 1;
    //                 grid-column-end: 2;
    //                 grid-row-start: 1;
    //                 grid-row-end: 2;
    //             }
    //         }

    //         .lead {
    //             width: 100%;
    //             padding-bottom: 6px;

    //             @include mixin.pcSm {
    //                 grid-column-start: 1;
    //                 grid-column-end: 3;
    //                 grid-row-start: 2;
    //                 grid-row-end: 3;
    //             }

    //         }

    //         .button-small01 {
    //             padding-bottom: 2px;

    //             @include mixin.pcSm {
    //                 grid-column-start: 2;
    //                 grid-column-end: 3;
    //                 grid-row-start: 1;
    //                 grid-row-end: 2;
    //             }

    //             @include mixin.sp {
    //                 width: 140px;
    //                 max-width: 100%;
    //                 padding: 0 16px 0 0;
    //             }
    //         }

    //         >*:not(:last-child) {
    //             margin-bottom: 0;
    //         }
    //     }

    // }
  }

  > *[class^="top-block"] {
    p:not(:last-child) {
      margin-bottom: 24px;
    }

    p.block-child-prev,
    .block-child-prev {
      margin-bottom: 40px;
    }
  }
}

:where(body).en {
  .top-main {
    .top-block02 {
      &.news {
        .title-area {
          grid-template-columns: 180px 1fr 168px;

          @include mixin.pcXl {
            grid-template-columns: 144px 1fr 168px;
          }

          @include mixin.pcSm {
            grid-template-columns: 1fr 168px;
          }

          .button-small01 {
            width: 168px;
          }
        }
      }
    }
  }
}
