@use "mixin";

/*----------------------------------------------------
Body
------------------------------------------------------*/
body {
    overflow: visible;
    position: relative;

    &.active {
        overflow: hidden;
    }

    &.active02 {
        overflow: hidden;

        .header-english,
        .header-common {
            z-index: 1;
        }

        .top-main,
        .page-main {
            z-index: 3;

            >*[class^="page-block-"].search-list-area-include {
                z-index: 3;
            }
        }
    }
}

.overlay {
    content: '';
    position: fixed;
    // width: calc( var(--vw) * 100 );
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.48);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 1001;
}

*[class^="page-block-"],
*[class^="top-block"] {
    &.active {
        z-index: 1001;
    }
}

.top-contents-wrap {
    &.active {
        position: relative;

        &::after {
            position: fixed;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            background-color: rgba(28, 53, 94, .8);
        }
    }
}

/*----------------------------------------------------
Accordion
------------------------------------------------------*/
.toggle {
    display: none;
}

.option {
    position: relative;
}

.accordion {
    &-title {
        display: block;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all 0.3s;

        &::before {
            content: "";
            position: absolute;
            right: 1.25em;
            top: 1.25em;
            width: 2px;
            height: 0.75em;
            background-color: mixin.$mainBlue;
            transition: all 0.3s;
        }

        &::after {
            content: "";
            position: absolute;
            right: 1.25em;
            top: 1.25em;
            width: 2px;
            height: 0.75em;
            background-color: mixin.$mainBlue;
            transition: all 0.3s;
            transform: rotate(90deg);
        }
    }

    &-content {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all 0.3s;

        max-height: 0;
        overflow: hidden;
    }

}

.toggle:checked+.accordion-title+.accordion-content {
    max-height: 1000px;
    transition: all 1.5s;
}

.toggle:checked+.accordion-title::before {
    transform: rotate(90deg) !important;
}

/*----------------------------------------------------
Print
------------------------------------------------------*/
.print-off {
    display: none;
}

@media print {

    //印刷時に適用したいスタイルをここに記載
    button {
        display: none;
    }

    .button-movie-area,
    .button-map-area,
    .button-search-list-area {
        padding: 0;
        background-color: transparent;
    }

    :where(.button-movie-area, .button-map-area, .button-search-list-area) .inner {
        max-height: fit-content;
    }
}

/*----------------------------------------------------
Animation
------------------------------------------------------*/
// フェードアウト
.fadeOut {
    animation-name: fadeOutAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 1;
}

@keyframes fadeOutAnime {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/*==================================================
ふわっ
===================================*/

/* その場で */
.fadeIn {
    animation-name: fadeInAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadeInAnime {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* 下から */

.fadeUp {
    animation-name: fadeUpAnime;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadeUpAnime {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 上から */

.fadeDown {
    animation-name: fadeDownAnime;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadeDownAnime {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 左から */

.fadeLeft {
    animation-name: fadeLeftAnime;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadeLeftAnime {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* 右から */

.fadeRight {
    animation-name: fadeRightAnime;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadeRightAnime {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/

.fadeInTrigger,
.fadeUpTrigger,
.fadeDownTrigger,
.fadeLeftTrigger,
.fadeRightTrigger {
    opacity: 0;
}

/*==================================================
    パタッ
    ===================================*/


/* 下へ */
.flipDown {
    animation-name: flipDownAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes flipDownAnime {
    from {
        transform: perspective(2500px) rotateX(100deg);
        opacity: 0;
    }

    to {
        transform: perspective(2500px) rotateX(0);
        opacity: 1;
    }
}


/* 左へ */
.flipLeft {
    animation-name: flipLeftAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    perspective-origin: left center;
    opacity: 0;
}

@keyframes flipLeftAnime {
    from {
        transform: perspective(600px) translate3d(0, 0, 0) rotateY(30deg);
        opacity: 0;
    }

    to {
        transform: perspective(600px) translate3d(0, 0, 0) rotateY(0deg);
        opacity: 1;
    }
}


/* 左上へ */
.flipLeftTop {
    animation-name: flipLeftTopAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes flipLeftTopAnime {
    from {
        transform: translate(-20px, 80px) rotate(-15deg);
        opacity: 0;
    }

    to {
        transform: translate(0, 0) rotate(0deg);
        opacity: 1;
    }
}

/* 右へ */
.flipRight {
    animation-name: flipRightAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    perspective-origin: right center;
    opacity: 0;
}

@keyframes flipRightAnime {
    from {
        transform: perspective(600px) translate3d(0, 0, 0) rotateY(-30deg);
        opacity: 0;
    }

    to {
        transform: perspective(600px) translate3d(0, 0, 0) rotateY(0deg);
        opacity: 1;
    }
}

/* 右上へ */
.flipRightTop {
    animation-name: flipRightTopAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes flipRightTopAnime {
    from {
        transform: translate(-20px, 80px) rotate(25deg);
        opacity: 0;
    }

    to {
        transform: translate(0, 1) rotate(0deg);
        opacity: 1;
    }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/

.flipDownTrigger,
.flipLeftTrigger,
.flipLeftTopTrigger,
.flipRightTrigger,
.flipRightTopTrigger {
    opacity: 0;
}

/*==================================================
    くるっ
    ===================================*/


/* X 軸（縦へ） */
.rotateX {
    animation-name: rotateXAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes rotateXAnime {
    from {
        transform: rotateX(0);
        opacity: 0;
    }

    to {
        transform: rotateX(-360deg);
        opacity: 1;
    }
}

/*　Y軸（横へ） */
.rotateY {
    animation-name: rotateYAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes rotateYAnime {
    from {
        transform: rotateY(0);
        opacity: 0;
    }

    to {
        transform: rotateY(-360deg);
        opacity: 1;
    }
}

/* Z 軸（左へ） */
.rotateLeftZ {
    animation-name: rotateLeftZAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes rotateLeftZAnime {
    from {
        transform: rotateZ(0);
        opacity: 0;
    }

    to {
        transform: rotateZ(-360deg);
        opacity: 1;
    }
}

/*　Z 軸（右へ） */
.rotateRightZ {
    animation-name: rotateRightZAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes rotateRightZAnime {
    from {
        transform: rotateZ(0);
        opacity: 0;
    }

    to {
        transform: rotateZ(360deg);
        opacity: 1;
    }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/

.rotateXTrigger,
.rotateYTrigger,
.rotateLeftZTrigger,
.rotateRightZTrigger {
    opacity: 0;
}

/*==================================================
    ボンッ、ヒュッ
    ===================================*/

/* 拡大 */
.zoomIn {
    animation-name: zoomInAnime;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes zoomInAnime {
    from {
        transform: scale(0.6);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

/* 縮小 */
.zoomOut {
    animation-name: zoomOutAnime;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes zoomOutAnime {
    from {
        transform: scale(1.2);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/

.zoomInTrigger,
.zoomOutTrigger {
    opacity: 0;
}

/*==================================================
    じわっ
    ===================================*/

/* ぼかしから出現 */
.blur {
    animation-name: blurAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes blurAnime {
    from {
        filter: blur(10px);
        transform: scale(1.02);
        opacity: 0;
    }

    to {
        filter: blur(0);
        transform: scale(1);
        opacity: 1;
    }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/

.blurTrigger {
    opacity: 0;
}

/*==================================================
    にゅーん
    ===================================*/

/* 滑らかに変形して出現 */
.smooth {
    animation-name: smoothAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    transform-origin: left;
    opacity: 0;
}

@keyframes smoothAnime {
    from {
        transform: translate3d(0, 100%, 0) skewY(12deg);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0) skewY(0);
        opacity: 1;
    }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/

.smoothTrigger {
    opacity: 0;
}

/*==================================================
    スーッ（枠線が伸びて出現）
    ===================================*/

/*枠線が伸びて出現*/

.lineTrigger {
    position: relative;
    /* 枠線が書かれる基点*/
    opacity: 0;
}

.lineTrigger.lineanime {
    animation-name: lineAnimeBase;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes lineAnimeBase {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/*上下線*/
.lineTrigger::before,
.lineTrigger::after {
    position: absolute;
    content: "";
    width: 0;
    height: 1px;
    background: #333;
    /* 枠線の色*/
}

/*左右線*/
.line2::before,
.line2::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 0;
    background: #333;
    /* 枠線の色*/
}

/*上線*/
.lineTrigger::before {
    top: 0;
    left: 0;
}

.lineTrigger.lineanime::before {
    animation: lineAnime .5s linear 0s forwards;
    /*表示されて0秒後に上線が0.5秒かけて表示*/
}

/*右線*/
.line2::before {
    top: 0;
    right: 0;
}

.lineTrigger.lineanime .line2::before {
    animation: lineAnime2 .5s linear .5s forwards;
    /*表示されて0.5秒後に右線が0.5秒かけて表示*/
}

/*下線*/
.lineTrigger::after {
    bottom: 0;
    right: 0;
}

.lineTrigger.lineanime::after {
    animation: lineAnime .5s linear 1s forwards;
    /*表示されて1秒後に下線が0.5秒かけて表示*/
}

/*左線*/
.line2::after {
    bottom: 0;
    left: 0;
}

.lineTrigger.lineanime .line2::after {
    animation: lineAnime2 .5s linear 1.5s forwards;
    /*表示されて1.5秒後に左線が0.5秒かけて表示*/
}

@keyframes lineAnime {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes lineAnime2 {
    0% {
        height: 0%;
    }

    100% {
        height: 100%;
    }
}

/*枠線内側の要素*/

.lineTrigger.lineanime .lineinappear {
    animation: lineInnerAnime .5s linear 1.5s forwards;
    /*1.5秒後に中央のエリアが0.5秒かけて表示*/
    opacity: 0;
    /*初期値を透過0にする*/
}

@keyframes lineInnerAnime {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


/*==================================================
    シャッ（背景色が伸びて出現）
    ===================================*/

/*背景色が伸びて出現（共通）*/
.bgextend {
    animation-name: bgextendAnimeBase;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: relative;
    overflow: hidden;
    /*　はみ出た色要素を隠す　*/
    opacity: 0;
}

@keyframes bgextendAnimeBase {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/*中の要素*/
.bgappear {
    animation-name: bgextendAnimeSecond;
    animation-duration: 1s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes bgextendAnimeSecond {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*左から*/
.bgLRextend::before {
    animation-name: bgLRextendAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #666;
    /*伸びる背景色の設定*/
}

@keyframes bgLRextendAnime {
    0% {
        transform-origin: left;
        transform: scaleX(0);
    }

    50% {
        transform-origin: left;
        transform: scaleX(1);
    }

    50.001% {
        transform-origin: right;
    }

    100% {
        transform-origin: right;
        transform: scaleX(0);
    }
}

/*右から*/
.bgRLextend::before {
    animation-name: bgRLextendAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #666;
    /*伸びる背景色の設定*/
}

@keyframes bgRLextendAnime {
    0% {
        transform-origin: right;
        transform: scaleX(0);
    }

    50% {
        transform-origin: right;
        transform: scaleX(1);
    }

    50.001% {
        transform-origin: left;
    }

    100% {
        transform-origin: left;
        transform: scaleX(0);
    }
}

/*下から*/
.bgDUextend::before {
    animation-name: bgDUextendAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #666;
    /*伸びる背景色の設定*/
}

@keyframes bgDUextendAnime {
    0% {
        transform-origin: bottom;
        transform: scaleY(0);
    }

    50% {
        transform-origin: bottom;
        transform: scaleY(1);
    }

    50.001% {
        transform-origin: top;
    }

    100% {
        transform-origin: top;
        transform: scaleY(0);
    }
}

/*上から*/
.bgUDextend::before {
    animation-name: bgUDextendAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #666;
    /*伸びる背景色の設定*/
}

@keyframes bgUDextendAnime {
    0% {
        transform-origin: top;
        transform: scaleY(0);
    }

    50% {
        transform-origin: top;
        transform: scaleY(1);
    }

    50.001% {
        transform-origin: bottom;
    }

    100% {
        transform-origin: bottom;
        transform: scaleY(0);
    }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.bgappearTrigger,
.bgUDextendTrigger,
.bgDUextendTrigger,
.bgRLextendTrigger,
.bgLRextendTrigger {
    opacity: 0;
}


/*========= レイアウトのためのCSS ===============*/

.wrapper {
    overflow: hidden;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.box {
    width: 220px;
    padding: 20px;
    margin: 0 20px 20px 20px;
    background: #666;
    color: #fff;
    box-sizing: border-box;
}

.bgextend,
.lineTrigger {
    width: 220px;
    padding: 20px;
    margin: 0 20px 20px 20px;
    box-sizing: border-box;
}



/*==================================================
    アニメーション設定
    ===================================*/

/* アニメーションの回数を決めるCSS*/

.count2 {
    animation-iteration-count: 2;
    /*この数字を必要回数分に変更*/
}

.countinfinite {
    animation-iteration-count: infinite;
    /*無限ループ*/
}

/* アニメーションスタートの遅延時間を決めるCSS*/

.delay-time05 {
    animation-delay: 0.5s;
}

.delay-time1 {
    animation-delay: 1s;
}

.delay-time15 {
    animation-delay: 1.5s;
}

.delay-time2 {
    animation-delay: 2s;
}

.delay-time25 {
    animation-delay: 2.5s;
}

/* アニメーション自体が変化する時間を決めるCSS*/

.change-time05 {
    animation-duration: 0.5s;
}

.change-time1 {
    animation-duration: 1s;
}

.change-time15 {
    animation-duration: 1.5s;
}

.change-time2 {
    animation-duration: 1s;
}

.change-time25 {
    animation-duration: 2.5s;
}