@use "mixin";

/*----------------------------------------------------
result
------------------------------------------------------*/
.page-block-common.result {

    .mf-search-box,
    .mf_finder_searchBox {

        .mf-search-bar,
        &_items {
            >div {
                @include mixin.tab {
                    margin: 0;
                }

            }

            input {
                &.mf-search-bar_input,
                &.mf_finder_searchBox_query_input {
                    height: 48px;
                    font-size: 1.6rem;

                    @include mixin.tab {
                        height: 40px;
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .mf-search-bar_input,
        &_query_input {
            &.mf_finder_searchBox_items {
                input {
                    height: 48px;
                    font-size: 1.6rem;
    
                    @include mixin.tab {
                        height: 40px;
                        font-size: 1.4rem;
                    }
                }
            } 
        }
        .mf-search-bar_input_alt-btn,
        &_submit {
            height: 48px;
            padding: 0 18px;

            @include mixin.tab {
                height: 40px;
                padding: 0 10px;
            }

        }

        .mf-search-bar_doc-type,
        &_doctype {
            margin: 0 10px 0 0 !important;
            // overflow: hidden;
            border-radius: 6px !important;

            &::after {
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                font-size: 1.8rem;
                color: mixin.$mainGreen;

                @include mixin.tab {
                    right: 7px;
                    font-size: 1.4rem;
                }
            }

            .mf-custom-select-wrapper_custom-select_caret {
                border-color: mixin.$mainGreen;
                top: 50%;
                right: 15px;
                @include mixin.tab {
                    right: 7px;
                }
            }

            .mf-search-bar_doc-type_select,
            &_select {
                width: 100px;
                height: 48px;
                font-size: 1.6rem;
                color: mixin.$mainGreen;
                padding: 5px 36px 6px 10px;
                border-radius: 6px !important;
                box-shadow: inset 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);
                border: 1px solid mixin.$mainLightGray !important;

                @include mixin.tab {
                    width: 80px;
                    height: 40px;
                    padding: 5px 20px 5px 10px;
                    font-size: 1.4rem;
                }
            }
        }

        .mf-filters,
        &_selects {
            margin: 15px 0 0;
            padding: 15px 0;
            @include mixin.flex;
            justify-content: flex-start;
            align-items: center;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;

            @include mixin.tab {
                margin: 10px 0 0;
            }

            .mf-filters_selects,
            .mf_finder_searchBox_misc {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: center;
                margin: 0;
            }

            .mf-filters_selects_other_item,
            label {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: center;

                &::before {
                    display: block;
                    font-size: 1.6rem;
                    width: fit-content;
                    color: mixin.$mainGreen;
                    margin-right: 10px;

                    @include mixin.tab {
                        font-size: 1.4rem;
                        margin-right: 5px;
                    }
                }

                &::after {
                    font-size: 2rem;
                    color: mixin.$mainGreen;
                    top: inherit;
                }

               .mf-filters_selects_other_item_icon {
                filter: invert(34%) sepia(68%) saturate(383%) hue-rotate(131deg) brightness(95%) contrast(92%);
                }

                select {
                    width: 100px;
                    height: 48px !important;
                    font-size: 1.6rem;
                    color: mixin.$mainGreen;
                    padding: 5px 36px 6px 10px;
                    border-radius: 6px !important;
                    box-shadow: inset 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);
                    border: 1px solid mixin.$mainLightGray !important;
    
                    @include mixin.tab {
                        width: 80px;
                        height: 40px !important;
                        padding: 5px 20px 5px 10px;
                        font-size: 1.4rem;
                    }

                    &#mf-filters_selects_other_sort_select,
                    &.mf_finder_searchBox_sort_select {
                        width: 140px;
                    }

                    &#mf-custom-select-wrapper_custom-select,
                    &.mf_finder_searchBox_pagemax_select {
                        width: 88px;
                    }
                }

                &:not(:last-child) {
                    margin-right: 20px;

                    @include mixin.sp {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    /* 結果一覧 */
    .mf_finder_organic {
        .mf_finder_logo {
            display: none;
        }
    }

    .mf-search-results_mars-logo {
        display: none;
    }

    .mf-search-results_no-results,
    .mf-search-results .mf-search-results_header,
    .mf_finder_organic_header_wrapper {
        margin: 40px 0 10px;

        @include mixin.tab {
            margin: 25px 0 10px;
        }

        &:lang(en),
        &:lang(ja) {
            .mf_finder_organic_header {
                flex-direction: row-reverse;

                @include mixin.tab {
                    flex-direction: column-reverse;
                }
            }

            .mf-search-results_header_results-start,
            .mf-search-results_header_results-end,
            .mf-search-results_header_results-count,
            .mf-search-results_header_search-query,
            .mf_finder_organic_range_from,
            .mf_finder_organic_range_to,
            .mf_finder_organic_total,
            .mf_finder_query,
            .mf_finder_organic_range_from:after {
                font-size: 1.8rem;

                @include mixin.tab {
                    font-size: 1.6rem;
                }
            }

            .mf_finder_query {

                &::before,
                &::after {
                    font-size: 1.4rem;

                    @include mixin.tab {
                        font-size: 1.2rem;
                    }
                }
            }

            .mf_finder_organic_total,
            .mf_finder_organic_range_to {
                &::after {
                    font-size: 1.4rem;

                    @include mixin.tab {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    .mf-search-results_no-results {
        font-size: 1.8rem;

        @include mixin.tab {
            font-size: 1.6rem;
        }
    }

    .mf-search-results_header {
        font-size: 1.4rem;

        @include mixin.tab {
            font-size: 1.2rem;
        }
    }

    .mf_finder_organic_range_from,
    .mf_finder_organic_range_to,
    .mf_finder_organic_total,
    .mf_finder_query {

        &::before,
        &::after {
            font-size: 1.4rem;

            @include mixin.tab {
                font-size: 1.2rem;
            }
        }
    }

    /* 結果一覧内 */
    .mf-search-results .mf-search-results_body .mf-search-results_body_result,
    .mf_finder_organic_doc {
        padding: 20px 0;
        display: flex;
        border-bottom: 1px solid #ddd;
        list-style: none;

        &:first-child {
            border-top: 1px solid #ddd;
        }

        .mf-search-results_body_result_texts,
        &_contents_wrapper {
            margin: 0 0 0 20px;

            @include mixin.tab {
                margin: 0 0 0 10px;
            }
        }

        .mf-search-results_body_result_texts .mf-search-results_body_result_texts_title .mf-search-results_body_result_texts_title_link,
        &_title_wrapper {
            font-size: 1.8rem;
            font-weight: 400;
            letter-spacing: .1em;
            color: #244377;
            transition: all 0.2s;

            display: inline-block;
            margin-bottom: .65rem;
            text-decoration: none;

            @include mixin.tab {
                font-size: 1.6rem;
            }

            &:hover {
                text-decoration: none;
                color: #0072bf;
                text-decoration-color: #0072bf;
            }
        }
        .mf-search-results_body_result_texts .mf-search-results_body_result_texts_url,
        &_url {
            margin-top: 10px;
            font-size: 1.4rem;
            word-break: break-all;

            @include mixin.tab {
                font-size: 1.2rem;
            }

            &:hover {
                text-decoration: none;

                .mf-search-results_body_result_texts_url_text,
                span {
                    color: #0072bf;
                    text-decoration-color: #0072bf;
                    text-underline-offset: 4px;
                }
            }
            .mf-search-results_body_result_texts_url_text,
            span {
                text-decoration: underline;
                transition: all 0.2s;
                margin-top: 10px;
                font-size: 1.4rem;
                word-break: break-all;

                overflow: visible;
                -webkit-line-clamp: none;
                line-clamp: none;

    
                @include mixin.tab {
                    font-size: 1.2rem;
                }
    
            }

            &::before {
                content: "URL";
                color: #FFF;
                white-space: nowrap;
            }

            &::before,
            &::after {
                background-color: #37474f;
                font-size: 1.2rem;
                padding: 4px 8px;
                margin-right: 10px;

                @include mixin.tab {
                    font-size: 1rem;
                    padding: 3px 5px;
                    margin-right: 5px;
                }
            }
        }

        .mf-search-results_body_result_texts .mf-search-results_body_result_texts_description,
        &_body {
            line-height: 1.5;
            height: 60px;
            font-size: 1.4rem;

            @include mixin.tab {
                font-size: 1.2rem;
                height: 52px;
            }
            @media (max-width: 576px) {
                height: auto;
            }
        }

        .mf-search-results_body_result_image,
        &_img_wrapper {
            &.mf_finder_link,
            &.mf_gui_img_frame {
                transition: all 0.2s;
                margin-right: 0;
            }
        }

        &_zoom {
            &::before {
                margin-right: 5px;
                font-size: 1.3rem;
            }
        }

        .mf-highlighted-text {
            background: #ffea00; 
        }

    }

    .mf_finder_organic_wrapper {
        &.mf_available_resultframe {
            .mf_finder_organic_doc_zoom {
                display: none;
                align-items: center;
                justify-content: center;
                font-size: 1.4rem;
                color: #005e8c;

                @include mixin.tab {
                    display: none;
                }
            }
        }
    }

}

 

.mf-pagination,
.mf_finder_organic_pager {
    margin: 30px 0 0;

    .mf-pagination_button {
        @include mixin.flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        border: none !important;

        background-color: mixin.$mainWhite;
        box-shadow: 0 1px 3px mixin.$mainLightGray;

        &:hover {
            background-color: mixin.$mainBlue;
            color: mixin.$mainWhite !important;
            opacity: 1;

            span {
                color: mixin.$mainWhite;
            }
        }


        &.active-page {
            background-color: #244377 !important;
        }

        &:disabled {
            display: none;
        }
    }
}

.mf_finder_pager_items {
    li {

        &.mf_finder_pager_item_current,
        a {
            @include mixin.flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 32px;
            height: 32px;
            border-radius: 6px;
        }

        &.mf_finder_pager_item_current {
            // background: linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;
            background-color: mixin.$mainBlue;

            span {
                color: mixin.$mainWhite;
            }
        }

        &.mf_finder_pager_item_first a,
        &.mf_finder_pager_item_prev a,
        &.mf_finder_pager_item_next a,
        a {
            background-color: mixin.$mainWhite;
            box-shadow: 0 1px 3px mixin.$mainLightGray;

            &:hover {
                background-color: mixin.$mainBlue;

                span {
                    color: mixin.$mainWhite;
                }
            }
        }
    }
}