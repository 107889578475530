@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Shippori+Mincho:wght@400;700&family=Noto+Serif+SC:wght@400;700&display=swap');

/*----------------------------------------------------
SETTING
------------------------------------------------------*/
$mainLightGray: #D0D0D0;
$mainLightGray02: #EEEEEE;
$mainBlue: #244377;
$subBlue: #657B9F;
$darkBlue: #1C355E;
$bgBlue:#dcf3fc;
$mainWhite: #FFFFFF;
$mainRed: #FF0066;
$mainPink:#FE6369;
$subPink:#FBB7A5;
$mainGreen:#267272;
$mainBlack :#333333;

$transparentMainBlue:rgba($mainBlue, 0.8);
$transparentSubBlue:rgba(lighten(saturate($subBlue, 40%), 40%), 0.2);
$transparentSubBlueAlpha:rgba(lighten(saturate($subBlue, 40%), 40%), 0.4);

$font-family-serif01: 'Shippori Mincho',
serif;
$font-family-sans-serif01: 'Noto Sans JP',
sans-serif;
$font-family-eng: "Shippori Mincho",'Noto Serif SC',
cursive;
$font-family-ch: 'Noto Serif SC', serif;

$pc: 1280px; // PC
$tab: 870px; // タブレット
$sp: 480px; // スマホ

// その他サイズ
$pc1920: 1920px;
$pc1728: 1728px;
$pcXxl: 1536px;
$pcXl: 1440px;
$pcLg: 1360px;
$pc1080: 1080px;
$pcSm: 1024px;
$pcXs: 962px;

$tabSm: 768px;
$tabXs: 600px;

/*----------------------------------------------------
mixin
------------------------------------------------------*/
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

// 調整用
@mixin pc1920 {
  @media (max-width: ($pc1920)) {
    @content;
  }
}

@mixin pc1728 {
  @media (max-width: ($pc1728)) {
    @content;
  }
}

@mixin pcXxl {
  @media (max-width: ($pcXxl)) {
    @content;
  }
}

@mixin pcXl {
  @media (max-width: ($pcXl)) {
    @content;
  }
}

@mixin pcLg {
  @media (max-width: ($pcLg)) {
    @content;
  }
}

@mixin pc1080 {
  @media (max-width: ($pc1080)) {
    @content;
  }
}

@mixin pcSm {
  @media (max-width: ($pcSm)) {
    @content;
  }
}

@mixin pcXs {
  @media (max-width: ($pcXs)) {
    @content;
  }
}

@mixin tabSm {
  @media (max-width: ($tabSm)) {
    @content;
  }
}

@mixin tabXs {
  @media (max-width: ($tabXs)) {
    @content;
  }
}


@mixin flex($justify: space-between, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin articleBody($margin: 0 auto 0, $padding: 0) {
  max-width: 1280px;
  width: 100%;
  margin: $margin;
  padding: $padding;

  @include pcLg {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include pc {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include tab {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@mixin articleBodyLg($margin: 0 auto 0, $padding: 0) {
  max-width: 1440px;
  width: 100%;
  margin: $margin;
  padding: $padding;
  padding-left: 40px;
  padding-right: 40px;

  @include pc {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include tab {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@mixin articleBodyFluid($margin: 0 auto 0) {
  width: 100%;
  margin: $margin;
  padding-left: 40px;
  padding-right: 40px;

  @include pc {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include tab {
    padding-left: 15px;
    padding-right: 15px;
  }
}