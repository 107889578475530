@use "mixin";

/*----------------------------------------------------
result
------------------------------------------------------*/
.page-block-common.result {
    .mf_finder_searchBox {
        &_items {
            >div {
                @include mixin.tab {
                    margin: 0;
                }

            }

            input {
                &.mf_finder_searchBox_query_input {
                    height: 48px;
                    font-size: 1.6rem;

                    @include mixin.tab {
                        height: 40px;
                        font-size: 1.4rem;
                    }
                }
            }


        }

        &_query_input {
            &.mf_finder_searchBox_items input {
                height: 48px;
                font-size: 1.6rem;

                @include mixin.tab {
                    height: 40px;
                    font-size: 1.4rem;
                }
            }

        }

        &_submit {
            height: 48px;
            padding: 0 18px;

            @include mixin.tab {
                height: 40px;
                padding: 0 10px;
            }

        }

        &_doctype {
            margin: 0 10px 0 0;

            &::after {
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                font-size: 1.8rem;
                color: mixin.$mainGreen;

                @include mixin.tab {
                    right: 7px;
                    font-size: 1.4rem;
                }
            }

            &_select {
                width: 100px;
                height: 48px;
                font-size: 1.6rem;
                color: mixin.$mainGreen;
                padding: 5px 36px 6px 10px;
                border-radius: 6px;
                box-shadow: inset 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);

                @include mixin.tab {
                    width: 80px;
                    height: 40px;
                    padding: 5px 20px 5px 10px;
                    font-size: 1.4rem;
                }
            }
        }

        &_selects {
            margin: 15px 0 0;
            padding: 15px 0;
            @include mixin.flex;
            justify-content: flex-start;
            align-items: center;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;

            @include mixin.tab {
                margin: 10px 0 0;
            }

            // .mf_finder_searchBox_category,
            // .mf_finder_searchBox_imgsize,
            // .mf_finder_searchBox_pagemax,
            // .mf_finder_searchBox_sort {
            //     margin: 0 20px 0 0;
            //     padding: 15px 0;
            //     border: none;

            //     @include mixin.tab {
            //         padding: 10px 0;
            //     }

            //     &_selects {
            //         padding-left: 30px;
            //         font-size: 1.4rem;

            //         &::before {
            //             top: 50%;
            //             left: 0;
            //             font-size: 1.8rem;
            //             transform: translateY(-50%);
            //         }
            //     }
            // }
            .mf_finder_searchBox_misc {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: center;
            }

            label {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: center;

                &::before {
                    display: block;
                    font-size: 1.6rem;
                    width: fit-content;
                    color: mixin.$mainGreen;
                    margin-right: 10px;

                    @include mixin.tab {
                        font-size: 1.4rem;
                        margin-right: 5px;
                    }
                }

                &::after {
                    font-size: 2rem;
                    color: mixin.$mainGreen;
                    top: inherit;
                }

                select {
                    color: mixin.$mainGreen;
                    font-size: 1.6rem;
                    padding: 5px 36px 6px 10px;
                    border-radius: 6px;
                    height: 48px;
                    box-shadow: inset 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);

                    @include mixin.tab {
                        font-size: 1.4rem;
                        height: 40px;
                    }

                    &.mf_finder_searchBox_sort_select {
                        width: 140px;
                    }

                    &.mf_finder_searchBox_pagemax_select {
                        width: 88px;
                    }
                }

                &:not(:last-child) {
                    margin-right: 20px;

                    @include mixin.sp {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    /* 結果一覧 */
    .mf_finder_organic {
        .mf_finder_logo {
            display: none;
        }

        &_pager {
            margin: 30px 0 0;
        }
    }

    .mf_finder_organic_header_wrapper {
        margin: 40px 0 10px;

        @include mixin.tab {
            margin: 25px 0 10px;
        }

        &:lang(en),
        &:lang(ja) {
            .mf_finder_organic_header {
                flex-direction: row-reverse;

                @include mixin.tab {
                    flex-direction: column-reverse;
                }
            }

            .mf_finder_organic_range_from,
            .mf_finder_organic_range_to,
            .mf_finder_organic_total,
            .mf_finder_query,
            .mf_finder_organic_range_from:after {
                font-size: 1.8rem;

                @include mixin.tab {
                    font-size: 1.6rem;
                }
            }

            .mf_finder_query {

                &::before,
                &::after {
                    font-size: 1.4rem;

                    @include mixin.tab {
                        font-size: 1.2rem;
                    }
                }
            }

            .mf_finder_organic_total,
            .mf_finder_organic_range_to {
                &::after {
                    font-size: 1.4rem;

                    @include mixin.tab {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    .mf_finder_organic_range_from,
    .mf_finder_organic_range_to,
    .mf_finder_organic_total,
    .mf_finder_query {

        &::before,
        &::after {
            font-size: 1.4rem;

            @include mixin.tab {
                font-size: 1.2rem;
            }
        }
    }

    /* 結果一覧内 */
    .mf_finder_organic_doc {
        padding: 20px 0;

        &_contents_wrapper {
            margin: 0 0 0 20px;

            @include mixin.tab {
                margin: 0 0 0 10px;
            }
        }

        &_title_wrapper {
            font-size: 1.8rem;
            color: #0072bf;
            transition: all 0.2s;

            @include mixin.tab {
                font-size: 1.6rem;
            }

            &:hover {
                text-decoration: none;
            }
        }

        &_url {
            margin-top: 10px;
            font-size: 1.4rem;
            word-break: break-all;

            @include mixin.tab {
                font-size: 1.2rem;
            }

            &:hover {
                text-decoration: none;

                span {
                    color: #0072bf;
                    text-decoration-color: #0072bf;
                    text-underline-offset: 4px;
                }
            }

            span {
                text-decoration: underline;
                transition: all 0.2s;
            }

            &::before,
            &::after {
                background-color: #37474f;
                font-size: 1.2rem;
                padding: 4px 8px;
                margin-right: 10px;

                @include mixin.tab {
                    font-size: 1rem;
                    padding: 3px 5px;
                    margin-right: 5px;
                }
            }
        }

        &_body {
            line-height: 1.5;
            height: 60px;
            font-size: 1.4rem;

            @include mixin.tab {
                font-size: 1.2rem;
                height: 52px;
            }
        }

        &_img_wrapper {
            &.mf_gui_img_frame {
                transition: all 0.2s;
            }
        }

        &_zoom {
            &::before {
                margin-right: 5px;
                font-size: 1.3rem;
            }
        }
    }

    .mf_finder_organic_wrapper {
        &.mf_available_resultframe {
            .mf_finder_organic_doc_zoom {
                display: none;
                align-items: center;
                justify-content: center;
                font-size: 1.4rem;
                color: #005e8c;

                @include mixin.tab {
                    display: none;
                }
            }
        }
    }

}

.mf_finder_pager_items {
    li {

        &.mf_finder_pager_item_current,
        a {
            @include mixin.flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 32px;
            height: 32px;
            border-radius: 6px;
        }

        &.mf_finder_pager_item_current {
            // background: linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;
            background-color: mixin.$mainBlue;

            span {
                color: mixin.$mainWhite;
            }
        }

        &.mf_finder_pager_item_first a,
        &.mf_finder_pager_item_prev a,
        &.mf_finder_pager_item_next a,
        a {
            background-color: mixin.$mainWhite;
            box-shadow: 0 1px 3px mixin.$mainLightGray;

            &:hover {
                background-color: mixin.$mainBlue;

                span {
                    color: mixin.$mainWhite;
                }
            }
        }
    }
}