@use "mixin";


/*--------------------------------------------------------------------------------------------------------
layout
----------------------------------------------------------------------------------------------------------*/
/*----------------------------------------------------
header
------------------------------------------------------*/
.header-common,
.header-result {
    @include mixin.articleBodyFluid;

    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;

    .inner {
        display: grid;
        grid-template-columns: 344px 1fr;
        padding: 8px 25px 28px;
        gap: 72px;

        @include mixin.pcLg {
            padding: 8px 25px 20px;
            grid-template-columns: 272px 1fr;
            gap: 32px;
        }

        @include mixin.pc {
            padding: 15px;
        }

        @include mixin.pcSm {
            grid-template-columns: 1fr 55px;
            padding: 0 0 0 10px;
            gap: 0;
        }

        .logo {
            grid-column-start: 1;
            grid-column-end: 2;
            height: 48px;

            @include mixin.pcLg {
                height: 46px;
            }

            @include mixin.pc {
                height: auto;
            }

            @include mixin.tab {}
        }

        .drawer {
            grid-column-start: 2;
            grid-column-end: 3;
        }

        .header-contents {
            display: grid;
            grid-template-columns: 1fr minmax(220px, 328px);
            gap: 72px;

            @include mixin.pcLg {
                gap: 32px;
                grid-template-columns: 1fr 280px;
            }

            @include mixin.pc {
                grid-template-columns: 1fr 148px;
                padding: 2px 0 0;
            }


            @include mixin.pcSm {
                grid-template-columns: 1fr;
                grid-template-rows: max-content;
                padding: 0;
                gap: 0;
            }

            >.head {
                grid-column-start: 1;
                grid-column-end: 2;

                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 24px 0 0;

                @include mixin.pc {
                    padding: 0;
                }

            }

            >.foot {
                grid-column-start: 2;
                grid-column-end: 3;

                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: min-content;
                gap: 4px 2vw;

                @include mixin.pc {
                    grid-template-columns: max-content 40px;
                    padding: 2px 0 0;
                    gap: 4px 20px;
                }

                @include mixin.pcSm {
                    grid-column-start: 1;
                    grid-column-end: 2;

                    grid-template-columns: 1fr;
                    padding: 0;
                }

                .list-foot {
                    @include mixin.pcSm {
                        grid-row-start: 2;
                        grid-row-end: 3;

                    }
                }

                .header-search {
                    @include mixin.pcSm {
                        grid-row-start: 1;
                        grid-row-end: 2;
                    }
                }
            }
        }
    }

    .megamenu {
        @include mixin.flex;
        justify-content: center;
        align-items: flex-end;

        @include mixin.pcLg {
            align-items: center;
        }

        .inner {
            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-template-rows: auto;
            gap: 40px;

            width: 100%;
            padding: 8px 25px 28px 56px;

            @include mixin.pc {
                padding: 24px 15px 24px 48px;
            }

            @include mixin.pcSm {
                grid-template-columns: 1fr;
                padding: 8px 24px 24px;
                gap: 0;
            }

            .head {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                @include mixin.pcSm {
                    grid-template-columns: 1fr;

                    grid-row-start: 1;
                    grid-row-end: 2;

                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;

                    display: none;
                }

            }

            .foot {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: center;

                @include mixin.pcSm {
                    grid-template-columns: 1fr;

                    grid-row-start: 2;
                    grid-row-end: 3;
                }

            }
        }

    }
}

.header-english,
.header-english-result {
    @include mixin.articleBodyFluid;

    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;

    .inner {
        display: grid;
        grid-template-columns: 344px 1fr;
        padding: 8px 25px 28px;
        gap: 72px;

        @include mixin.pcXl {
            gap: 32px;
        }

        @include mixin.pcLg {
            padding: 8px 25px 20px;
            grid-template-columns: 272px 1fr;
            gap: 16px;
        }

        @include mixin.pc {
            padding: 15px;
        }

        @include mixin.pc1080 {
            grid-template-columns: 1fr 55px;
            padding: 0 0 0 10px;
            gap: 0;
        }

        .logo {
            grid-column-start: 1;
            grid-column-end: 2;
            height: 48px;

            @include mixin.pcLg {
                height: 46px;
            }

            @include mixin.pc {
                padding: 4px 0 0;
                height: fit-content;
            }

            @include mixin.pc1080 {
                height: 50px;
                padding: 0;
            }
        }

        .drawer {
            grid-column-start: 2;
            grid-column-end: 3;
        }

        .header-contents {
            display: grid;
            grid-template-columns: 1fr minmax(220px, 328px);
            gap: 72px;

            @include mixin.pcXxl {
                grid-template-columns: 1fr 148px;
            }

            @include mixin.pcXl {
                gap: 32px;
            }

            @include mixin.pcLg {
                gap: 16px;
                // grid-template-columns: 1fr 280px;
            }

            @include mixin.pc {
                grid-template-columns: 1fr 148px;
                padding: 2px 0 0;
            }


            @include mixin.pc1080 {
                grid-template-columns: 1fr;
                grid-template-rows: max-content;
                padding: 0;
                gap: 0;
            }

            >.head {
                grid-column-start: 1;
                grid-column-end: 2;

                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 24px 0 0;

                @include mixin.pc {
                    padding: 0;
                }

            }

            >.foot {
                grid-column-start: 2;
                grid-column-end: 3;

                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: min-content;
                gap: 4px 2vw;

                @include mixin.pcXxl {
                    grid-template-columns: max-content 40px;
                    padding: 28px 0 0;
                    gap: 4px 20px;
                }

                @include mixin.pc {
                    padding: 2px 0 0;
                }

                @include mixin.pc1080 {
                    grid-column-start: 1;
                    grid-column-end: 2;

                    grid-template-columns: 1fr;
                    padding: 0;
                }

                .list-foot {
                    @include mixin.pc1080 {
                        grid-row-start: 2;
                        grid-row-end: 3;

                    }
                }

                .header-search {
                    @include mixin.pc1080 {
                        grid-row-start: 1;
                        grid-row-end: 2;
                    }
                }
            }
        }
    }

    .megamenu {
        @include mixin.flex;
        justify-content: center;
        align-items: flex-end;

        @include mixin.pcLg {
            align-items: center;
        }

        .inner {
            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-template-rows: auto;
            gap: 40px;

            width: 100%;
            padding: 8px 25px 28px 56px;

            @include mixin.pc {
                padding: 24px 15px 24px 48px;
            }

            @include mixin.pc1080 {
                grid-template-columns: 1fr;
                padding: 8px 24px 24px;
                gap: 0;
            }

            .head {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                @include mixin.pc1080 {
                    grid-template-columns: 1fr;

                    grid-row-start: 1;
                    grid-row-end: 2;

                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;

                    display: none;
                }

            }

            .foot {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: center;

                @include mixin.pc1080 {
                    grid-template-columns: 1fr;

                    grid-row-start: 2;
                    grid-row-end: 3;
                }

            }
        }

    }
}

.header-chinese,
.header-chinese-result {
    @include mixin.articleBodyFluid;

    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;

    .inner {
        display: grid;
        grid-template-columns: 240px 1fr;
        padding: 8px 25px 28px;
        gap: 72px;

        @include mixin.pcLg {
            padding: 8px 25px 20px;
            grid-template-columns: 200px 1fr;
            gap: 32px;
        }

        @include mixin.pc {
            padding: 15px;
        }

        @include mixin.pcSm {
            grid-template-columns: 1fr 55px;
            padding: 0 0 0 10px;
            gap: 0;
        }

        .logo {
            grid-column-start: 1;
            grid-column-end: 2;
            height: 48px;

            @include mixin.pcLg {
                height: 46px;
            }

            @include mixin.pc {
                height: auto;
            }

            @include mixin.tab {}
        }

        .drawer {
            grid-column-start: 2;
            grid-column-end: 3;
        }

        .header-contents {
            display: grid;
            grid-template-columns: 1fr minmax(220px, 328px);
            gap: 72px;

            @include mixin.pcLg {
                gap: 32px;
                grid-template-columns: 1fr 280px;
            }

            @include mixin.pc {
                grid-template-columns: 1fr 148px;
                padding: 2px 0 0;
            }


            @include mixin.pcSm {
                grid-template-columns: 1fr;
                grid-template-rows: max-content;
                padding: 0;
                gap: 0;
            }

            >.head {
                grid-column-start: 1;
                grid-column-end: 2;

                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 24px 0 0;

                @include mixin.pc {
                    padding: 0;
                }

            }

            >.foot {
                grid-column-start: 2;
                grid-column-end: 3;

                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: min-content;
                gap: 4px 2vw;

                @include mixin.pc {
                    grid-template-columns: max-content 40px;
                    padding: 2px 0 0;
                    gap: 4px 20px;
                }

                @include mixin.pcSm {
                    grid-column-start: 1;
                    grid-column-end: 2;

                    grid-template-columns: 1fr;
                    padding: 0;
                }

                .list-foot {
                    @include mixin.pcSm {
                        grid-row-start: 2;
                        grid-row-end: 3;

                    }
                }

                .header-search {
                    @include mixin.pcSm {
                        grid-row-start: 1;
                        grid-row-end: 2;
                    }
                }
            }
        }
    }

    .megamenu {
        @include mixin.flex;
        justify-content: center;
        align-items: flex-end;

        @include mixin.pcLg {
            align-items: center;
        }

        .inner {
            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-template-rows: auto;
            gap: 40px;

            width: 100%;
            padding: 8px 25px 28px 56px;

            @include mixin.pc {
                padding: 24px 15px 24px 48px;
            }

            @include mixin.pcSm {
                grid-template-columns: 1fr;
                padding: 8px 24px 24px;
                gap: 0;
            }

            .head {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                @include mixin.pcSm {
                    grid-template-columns: 1fr;

                    grid-row-start: 1;
                    grid-row-end: 2;

                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;

                    display: none;
                }

            }

            .foot {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: center;

                @include mixin.pcSm {
                    grid-template-columns: 1fr;

                    grid-row-start: 2;
                    grid-row-end: 3;
                }

            }
        }

    }
}

/*--------------------------------------------------------------------------------------------------------
module
----------------------------------------------------------------------------------------------------------*/
/*----------------------------------------------------
header
------------------------------------------------------*/
.header-common,
.header-result {

    .logo {
        @include mixin.flex;
        justify-content: flex-end;
        align-items: flex-end;

        @include mixin.pcSm {
            position: relative;
            z-index: 2;
        }

        @include mixin.pc {
            align-items: flex-start;
        }

        @include mixin.pcSm {
            justify-content: flex-start;
            align-items: center;
        }

        &-image {
            img {
                width: 336px;
                height: auto;

                @include mixin.pcLg {
                    width: 272px;
                }

                @include mixin.pcSm {}
            }
        }
    }

    .header-contents {

        @include mixin.pcSm {
            position: relative;
            z-index: 2;
        }

        >.foot {
            @include mixin.pcSm {
                // border-top: 1px solid mixin.$mainLightGray;
            }
        }
    }

    .list-nav {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2vw;

        color: mixin.$mainBlack;
        font-size: 1.8rem;

        @include mixin.pc {
            font-size: 1.6rem;
        }

        @include mixin.pcSm {
            flex-direction: column;
            gap: 0;
        }

        >.list-item {
            @include mixin.pcSm {
                width: 100%;
            }

            >label,
            >a {
                display: block;
                font-size: clamp(1.6rem, 1vw, 1.8rem);
                white-space: nowrap;

                @include mixin.pc {
                    font-size: 1.5rem;
                }

                @include mixin.pcSm {
                    @include mixin.flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    // height: 50px;
                    height: 72px;
                    // border-top: 1px solid mixin.$mainLightGray;

                    font-size: 1.6rem;
                    justify-content: flex-start;
                    padding: 0 24px;
                }

            }

        }

    }

    .list-foot {
        @include mixin.flex;
        justify-content: flex-end;
        color: mixin.$mainGreen;
        height: 20px;

        @include mixin.pcSm {
            justify-content: flex-start;
            padding: 0 24px;
        }

        .list-item {
            position: relative;
            white-space: nowrap;
            font-size: 1.4rem;


            &:not(:last-of-type) {
                margin-right: 1.4rem;

                &::before {
                    content: "|";
                    position: absolute;
                    top: 0;
                    right: -0.7rem;
                }
            }

            .language {
                display: block;

                @include mixin.pc {
                    display: none;
                }

                @include mixin.pcSm {
                    display: block;
                }

                &-pc {
                    display: none;

                    @include mixin.pc {
                        display: block;
                    }

                    @include mixin.pcSm {
                        display: none;
                    }
                }
            }

        }
    }

    .header-search {
        height: fit-content;

        @include mixin.pcSm {
            @include mixin.flex;
            justify-content: center;
            align-items: center;
            // height: 64px;
            height: 80px;
            padding: 0 24px;
            background-color: mixin.$mainBlue;
        }
        
        .mf-search-bar,
        .mf_finder_searchBox_items {
            .mf-search-bar_input,
            input.mf_finder_searchBox_query_input {
                font-size: 1.4rem !important;
                height: 32px !important;
            }

        }

        .accordion-title {
            height: 100%;
            @include mixin.flex;
            justify-content: center;
            align-items: flex-start;
            padding: 2px 0 0;
        }

    }

    .megamenu {
        .head {
            box-shadow: inset -2px 0 mixin.$mainLightGray;

            @include mixin.pcSm {
                box-shadow: none;
            }

            .title {
                font-size: 2.4rem;
                line-height: 1.1;
                position: relative;

                @include mixin.pc {
                    font-size: 2rem;
                }

                @include mixin.pcSm {
                    font-size: 1.6rem;
                }
            }

            a.button-megamenu {
                @include mixin.flex;
                justify-content: center;
                width: 140px;
                height: 32px;
                padding-bottom: 2px;
                border-radius: 9999px;
                background-color: mixin.$mainWhite;
                color: mixin.$mainBlue;
                border: 2px solid mixin.$mainBlue;

                font-weight: 700;
                font-size: 1.4rem;
                letter-spacing: 0.1em;
                cursor: pointer;

                @include mixin.pc {
                    font-size: 1.2rem;
                    width: 120px;
                }

                &:hover {
                    background-color: mixin.$mainBlue;
                    color: mixin.$mainWhite;
                    opacity: 1;
                }

            }

            *:not(:last-child) {
                margin-bottom: 24px;

                @include mixin.pcSm {
                    margin-bottom: 0;
                }
            }
        }

        .card-grid-area {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 40px;
            justify-content: space-between;
            max-width: 1320px;

            @include mixin.pcXxl {
                gap: 40px;
            }

            @include mixin.pcLg {
                gap: 20px;
            }

            @include mixin.pcSm {
                gap: 10px;
                grid-template-columns: repeat(3, 1fr);
            }

            @include mixin.tabXs {
                margin-bottom: 20px;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }

            @include mixin.sp {
                gap: 20px 10px;
            }

            &:last-child {
                margin-bottom: 0;
            }

        }

        .card-vertical02 {
            p.title {
                font-size: clamp(1.4rem, 1vw, 1.6rem);
                white-space: initial;

                @include mixin.pcSm {
                    font-size: 1.6rem;
                }
            }
        }

    }
}

.header-english,
.header-english-result {

    .logo {
        @include mixin.flex;
        justify-content: flex-end;
        align-items: flex-end;

        @include mixin.pc1080 {
            position: relative;
            z-index: 2;
            justify-content: flex-start;
            align-items: center;
        }

        &-image {
            img {
                width: 336px;
                height: auto;

                @include mixin.pcLg {
                    width: 272px;
                }

                @include mixin.pc1080 {}
            }
        }
    }

    .header-contents {

        @include mixin.pc1080 {
            position: relative;
            z-index: 2;
        }

        >.foot {
            @include mixin.pc1080 {
                // border-top: 1px solid mixin.$mainLightGray;
            }
        }
    }

    .list-nav {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2vw;

        color: mixin.$mainBlack;
        font-size: 1.8rem;

        @include mixin.pc {
            font-size: 1.6rem;
        }

        @include mixin.pc1080 {
            flex-direction: column;
            gap: 0;
        }

        >.list-item {
            @include mixin.pc1080 {
                width: 100%;
            }

            >label,
            >a {
                display: block;
                font-size: clamp(1.6rem, 1vw, 1.8rem);
                white-space: nowrap;

                @include mixin.pc {
                    font-size: 1.5rem;
                }

                @include mixin.pc1080 {
                    @include mixin.flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    // height: 50px;
                    height: 72px;
                    // border-top: 1px solid mixin.$mainLightGray;

                    font-size: 1.6rem;
                    justify-content: flex-start;
                    padding: 0 24px;
                }

            }

        }

    }

    .list-foot {
        @include mixin.flex;
        justify-content: flex-end;
        color: mixin.$mainGreen;
        height: 20px;

        @include mixin.pc1080 {
            justify-content: flex-start;
            padding: 0 24px;
        }

        .list-item {
            position: relative;
            white-space: nowrap;
            font-size: 1.4rem;


            &:not(:last-of-type) {
                margin-right: 1.4rem;

                &::before {
                    content: "|";
                    position: absolute;
                    top: 0;
                    right: -0.7rem;
                }
            }

            .language {
                display: block;

                @include mixin.pcXxl {
                    display: none;
                }

                @include mixin.pc1080 {
                    display: block;
                }

                &-pc {
                    display: none;

                    @include mixin.pcXxl {
                        display: block;
                    }

                    @include mixin.pc1080 {
                        display: none;
                    }
                }
            }

        }
    }

    .header-search {
        height: fit-content;

        @include mixin.pc1080 {
            @include mixin.flex;
            justify-content: center;
            align-items: center;
            // height: 64px;
            height: 80px;
            padding: 0 24px;
            background-color: mixin.$mainBlue;
        }

        .mf-search-bar,
        .mf_finder_searchBox_items {
            .mf-search-bar_input,
            input.mf_finder_searchBox_query_input {
                font-size: 1.4rem !important;
                height: 32px !important;
            }

        }

        .accordion-title {
            height: 100%;
            @include mixin.flex;
            justify-content: center;
            align-items: flex-start;
            padding: 2px 0 0;
        }

    }

    .megamenu {
        .head {
            box-shadow: inset -2px 0 mixin.$mainLightGray;

            @include mixin.pc1080 {
                box-shadow: none;
            }

            .title {
                font-size: 2.4rem;
                line-height: 1.1;
                position: relative;

                @include mixin.pc {
                    font-size: 2rem;
                }

                @include mixin.pc1080 {
                    font-size: 1.6rem;
                }
            }

            a.button-megamenu {
                @include mixin.flex;
                justify-content: center;
                width: 140px;
                height: 32px;
                padding-bottom: 2px;
                border-radius: 9999px;
                background-color: mixin.$mainWhite;
                color: mixin.$mainBlue;
                border: 2px solid mixin.$mainBlue;

                font-weight: 700;
                font-size: 1.4rem;
                letter-spacing: 0.1em;
                cursor: pointer;

                @include mixin.pc {
                    font-size: 1.2rem;
                    width: 120px;
                }

                &:hover {
                    background-color: mixin.$mainBlue;
                    color: mixin.$mainWhite;
                    opacity: 1;
                }

            }

            *:not(:last-child) {
                margin-bottom: 24px;

                @include mixin.pc1080 {
                    margin-bottom: 0;
                }
            }
        }

        .card-grid-area {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 40px;
            justify-content: space-between;
            max-width: 1320px;

            @include mixin.pcXxl {
                gap: 40px;
            }

            @include mixin.pcLg {
                gap: 20px;
            }

            @include mixin.pc1080 {
                gap: 10px;
                grid-template-columns: repeat(3, 1fr);
            }

            @include mixin.tabXs {
                margin-bottom: 20px;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }

            @include mixin.sp {
                gap: 20px 10px;
            }

            &:last-child {
                margin-bottom: 0;
            }

        }

        .card-vertical02 {
            p.title {
                font-size: clamp(1.4rem, 1vw, 1.6rem);
                white-space: initial;

                @include mixin.pc1080 {
                    font-size: 1.6rem;
                }
            }
        }

    }
}

.header-chinese,
.header-chinese-result {

    .logo {
        @include mixin.flex;
        justify-content: flex-end;
        align-items: flex-end;

        @include mixin.pcSm {
            position: relative;
            z-index: 2;
        }

        @include mixin.pc {
            align-items: flex-start;
        }

        @include mixin.pcSm {
            justify-content: flex-start;
            align-items: center;
        }

        &-image {
            img {
                width: 240px;
                height: auto;

                @include mixin.pcLg {
                    width: 200px;
                }

                @include mixin.pcSm {}
            }
        }
    }

    .header-contents {

        @include mixin.pcSm {
            position: relative;
            z-index: 2;
        }

        >.foot {
            @include mixin.pcSm {
                // border-top: 1px solid mixin.$mainLightGray;
            }
        }
    }

    .list-nav {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2vw;

        color: mixin.$mainBlack;
        font-size: 1.8rem;

        @include mixin.pc {
            font-size: 1.6rem;
        }

        @include mixin.pcSm {
            flex-direction: column;
            gap: 0;
        }

        >.list-item {
            @include mixin.pcSm {
                width: 100%;
            }

            >label,
            >a {
                display: block;
                font-size: clamp(1.6rem, 1vw, 1.8rem);
                white-space: nowrap;

                @include mixin.pc {
                    font-size: 1.5rem;
                }

                @include mixin.pcSm {
                    @include mixin.flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    // height: 50px;
                    height: 72px;
                    // border-top: 1px solid mixin.$mainLightGray;

                    font-size: 1.6rem;
                    justify-content: flex-start;
                    padding: 0 24px;
                }

            }

        }

    }

    .list-foot {
        @include mixin.flex;
        justify-content: flex-end;
        color: mixin.$mainGreen;
        height: 20px;

        @include mixin.pcSm {
            justify-content: flex-start;
            padding: 0 24px;
        }

        .list-item {
            position: relative;
            white-space: nowrap;
            font-size: 1.4rem;


            &:not(:last-of-type) {
                margin-right: 1.4rem;

                &::before {
                    content: "|";
                    position: absolute;
                    top: 0;
                    right: -0.7rem;
                }
            }

            .language {
                display: block;

                @include mixin.pc {
                    display: none;
                }

                @include mixin.pcSm {
                    display: block;
                }

                &-pc {
                    display: none;

                    @include mixin.pc {
                        display: block;
                    }

                    @include mixin.pcSm {
                        display: none;
                    }
                }
            }

        }
    }

    .header-search {
        height: fit-content;

        @include mixin.pcSm {
            @include mixin.flex;
            justify-content: center;
            align-items: center;
            // height: 64px;
            height: 80px;
            padding: 0 24px;
            background-color: mixin.$mainBlue;
        }

        .mf-search-bar,
        .mf_finder_searchBox_items {
            .mf-search-bar_input,
            input.mf_finder_searchBox_query_input {
                font-size: 1.4rem !important;
                height: 32px !important;
            }

        }

        .accordion-title {
            height: 100%;
            @include mixin.flex;
            justify-content: center;
            align-items: flex-start;
            padding: 2px 0 0;
        }

    }

    .megamenu {
        .head {
            box-shadow: inset -2px 0 mixin.$mainLightGray;

            @include mixin.pcSm {
                box-shadow: none;
            }

            .title {
                font-size: 2.4rem;
                line-height: 1.1;
                position: relative;

                @include mixin.pc {
                    font-size: 2rem;
                }

                @include mixin.pcSm {
                    font-size: 1.6rem;
                }
            }

            a.button-megamenu {
                @include mixin.flex;
                justify-content: center;
                width: 140px;
                height: 32px;
                padding-bottom: 2px;
                border-radius: 9999px;
                background-color: mixin.$mainWhite;
                color: mixin.$mainBlue;
                border: 2px solid mixin.$mainBlue;

                font-weight: 700;
                font-size: 1.4rem;
                letter-spacing: 0.1em;
                cursor: pointer;

                @include mixin.pc {
                    font-size: 1.2rem;
                    width: 120px;
                }

                &:hover {
                    background-color: mixin.$mainBlue;
                    color: mixin.$mainWhite;
                    opacity: 1;
                }

            }

            *:not(:last-child) {
                margin-bottom: 24px;

                @include mixin.pcSm {
                    margin-bottom: 0;
                }
            }
        }

        .card-grid-area {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 40px;
            justify-content: space-between;
            max-width: 1320px;

            @include mixin.pcXxl {
                gap: 40px;
            }

            @include mixin.pcLg {
                gap: 20px;
            }

            @include mixin.pcSm {
                gap: 10px;
                grid-template-columns: repeat(3, 1fr);
            }

            @include mixin.tabXs {
                margin-bottom: 20px;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }

            @include mixin.sp {
                gap: 20px 10px;
            }

            &:last-child {
                margin-bottom: 0;
            }

        }

        .card-vertical02 {
            p.title {
                font-size: clamp(1.4rem, 1vw, 1.6rem);
                white-space: initial;

                @include mixin.pcSm {
                    font-size: 1.6rem;
                }
            }
        }

    }
}

/*--------------------------------------------------------------------------------------------------------
State
----------------------------------------------------------------------------------------------------------*/
/*----------------------------------------------------
Hamburger Menu
------------------------------------------------------*/
.header-common,
.header-result {
    .drawer-hidden {
        display: none;
    }

    @include mixin.pcSm {
        .drawer-open {
            @include mixin.flex;
            justify-content: center;
            height: 50px;
            width: 55px;
            position: relative;
            z-index: 98;
            /* 重なり順を一番下に */
            cursor: pointer;
            margin: 0;
            padding: 0 5px 0 0;

            span,
            span:before,
            span:after {
                content: "";
                display: block;
                height: 3px;
                width: 30px;
                border-radius: 3px;
                background-color: mixin.$mainBlue;
                transition: 0.5s;
                position: absolute;
            }

            span:before {
                bottom: 8px;
            }

            span:after {
                top: 8px;
            }
        }

        #drawer-check:checked~.drawer-open span {
            background-color: rgba(255, 255, 255, 0);
        }

        #drawer-check:checked~.drawer-open span::before {
            bottom: 0;
            transform: rotate(45deg);
        }

        #drawer-check:checked~.drawer-open span::after {
            top: 0;
            transform: rotate(-45deg);
        }

        #drawer-check:checked~.drawer-content {
            top: 50px;
            transition: 0.5s;
        }

        .drawer-content {
            width: 100%;
            height: calc(100vh - 50px);
            position: fixed;
            top: calc(50px - 100vh);
            left: 0;
            z-index: -1;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            overflow-y: auto;

            background-color: mixin.$mainWhite;
        }
    }

    @supports selector(:has(*)) {
        &:has(#drawer-check:checked) {
            @include mixin.pcSm {
                background: mixin.$mainWhite;
                transition: all 0.5s;
            }
        }
    }

    .inner {
        @supports selector(:has(*)) {
            &:has(#drawer-check:checked) {
                @include mixin.pcSm {
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                    background: mixin.$mainWhite;
                    transition: all 0.5s;

                    .drawer-content {
                        width: calc(var(--vw) * 100);
                        margin-left: calc(50% - 50vw);
                    }
                }
            }
        }

        @supports not selector(:has(*)) {
            #drawer-check:checked~.drawer-content {

                @include mixin.pcSm {
                    top: 0;
                    left: -20px;
                    padding: 50px 0 0;
                    width: calc(var(--vw) * 100);
                    height: 100vh;
                }

                @include mixin.tab {
                    left: -15px;
                }
            }


        }
    }

}

.header-english,
.header-english-result {
    .drawer-hidden {
        display: none;
    }

    @include mixin.pc1080 {
        .drawer-open {
            @include mixin.flex;
            justify-content: center;
            height: 50px;
            width: 55px;
            position: relative;
            z-index: 98;
            /* 重なり順を一番下に */
            cursor: pointer;
            margin: 0;
            padding: 0 5px 0 0;

            span,
            span:before,
            span:after {
                content: "";
                display: block;
                height: 3px;
                width: 30px;
                border-radius: 3px;
                background-color: mixin.$mainBlue;
                transition: 0.5s;
                position: absolute;
            }

            span:before {
                bottom: 8px;
            }

            span:after {
                top: 8px;
            }
        }

        #drawer-check:checked~.drawer-open span {
            background-color: rgba(255, 255, 255, 0);
        }

        #drawer-check:checked~.drawer-open span::before {
            bottom: 0;
            transform: rotate(45deg);
        }

        #drawer-check:checked~.drawer-open span::after {
            top: 0;
            transform: rotate(-45deg);
        }

        #drawer-check:checked~.drawer-content {
            top: 50px;
            transition: 0.5s;
        }

        .drawer-content {
            width: 100%;
            height: calc(100vh - 50px);
            position: fixed;
            top: calc(50px - 100vh);
            left: 0;
            z-index: -1;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            overflow-y: auto;

            background-color: mixin.$mainWhite;
        }
    }

    @supports selector(:has(*)) {
        &:has(#drawer-check:checked) {
            @include mixin.pc1080 {
                background: mixin.$mainWhite;
                transition: all 0.5s;
            }
        }
    }

    .inner {
        @supports selector(:has(*)) {
            &:has(#drawer-check:checked) {
                @include mixin.pc1080 {
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                    background: mixin.$mainWhite;
                    transition: all 0.5s;

                    .drawer-content {
                        width: calc(var(--vw) * 100);
                        margin-left: calc(50% - 50vw);
                    }
                }
            }
        }

        @supports not selector(:has(*)) {
            #drawer-check:checked~.drawer-content {

                @include mixin.pc1080 {
                    top: 0;
                    left: -20px;
                    padding: 50px 0 0;
                    width: calc(var(--vw) * 100);
                    height: 100vh;
                }

                @include mixin.tab {
                    left: -15px;
                }
            }


        }
    }

}

.header-chinese,
.header-chinese-result {
    .drawer-hidden {
        display: none;
    }

    @include mixin.pcSm {
        .drawer-open {
            @include mixin.flex;
            justify-content: center;
            height: 50px;
            width: 55px;
            position: relative;
            z-index: 98;
            /* 重なり順を一番下に */
            cursor: pointer;
            margin: 0;
            padding: 0 5px 0 0;

            span,
            span:before,
            span:after {
                content: "";
                display: block;
                height: 3px;
                width: 30px;
                border-radius: 3px;
                background-color: mixin.$mainBlue;
                transition: 0.5s;
                position: absolute;
            }

            span:before {
                bottom: 8px;
            }

            span:after {
                top: 8px;
            }
        }

        #drawer-check:checked~.drawer-open span {
            background-color: rgba(255, 255, 255, 0);
        }

        #drawer-check:checked~.drawer-open span::before {
            bottom: 0;
            transform: rotate(45deg);
        }

        #drawer-check:checked~.drawer-open span::after {
            top: 0;
            transform: rotate(-45deg);
        }

        #drawer-check:checked~.drawer-content {
            top: 50px;
            transition: 0.5s;
        }

        .drawer-content {
            width: 100%;
            height: calc(100vh - 50px);
            position: fixed;
            top: calc(50px - 100vh);
            left: 0;
            z-index: -1;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            overflow-y: auto;

            background-color: mixin.$mainWhite;
        }
    }

    @supports selector(:has(*)) {
        &:has(#drawer-check:checked) {
            @include mixin.pcSm {
                background: mixin.$mainWhite;
                transition: all 0.5s;
            }
        }
    }

    .inner {
        @supports selector(:has(*)) {
            &:has(#drawer-check:checked) {
                @include mixin.pcSm {
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                    background: mixin.$mainWhite;
                    transition: all 0.5s;

                    .drawer-content {
                        width: calc(var(--vw) * 100);
                        margin-left: calc(50% - 50vw);
                    }
                }
            }
        }

        @supports not selector(:has(*)) {
            #drawer-check:checked~.drawer-content {

                @include mixin.pcSm {
                    top: 0;
                    left: -20px;
                    padding: 50px 0 0;
                    width: calc(var(--vw) * 100);
                    height: 100vh;
                }

                @include mixin.tab {
                    left: -15px;
                }
            }


        }
    }

}

/*----------------------------------------------------
Header accordion
------------------------------------------------------*/
.header-common,
.header-result {
    >.inner {
        box-shadow: 0 1px 1px mixin.$mainLightGray;
        border-radius: 0 0 10px 10px;
        position: relative;

        pointer-events: none;

        // background-color: rgba(255, 255, 255, 0.48);
        background-color: mixin.$mainWhite;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-right-color: rgba(255, 255, 255, 0.2);
        border-bottom-color: rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        &:hover {
            background: mixin.$mainWhite;
        }

        >* {
            pointer-events: auto;
        }

    }

    .list-nav {
        >.list-item {
            &:hover {
                >.accordion-title {
                    padding-bottom: 15.4vw;

                    @include mixin.pcLg {
                        padding-bottom: 18vw;
                    }

                    @include mixin.pc {
                        padding-bottom: 22vw;
                    }

                    @include mixin.pcSm {
                        padding-bottom: 0;
                    }
                }

                >.accordion-title>a,
                >a {
                    opacity: 1;

                    span {
                        background-color: mixin.$mainWhite;
                        color: mixin.$mainBlack;

                        @include mixin.pcSm {
                            background-color: inherit;
                            color: inherit;
                        }
                    }

                }

                .hover-visible {
                    display: grid;
                }

                .accordion-content {
                    max-height: 1000px;
                    transition: all .25s;
                    top: 60px;

                    @include mixin.pc {
                        top: 58px;
                    }

                    @include mixin.pcSm {
                        top: 0;
                        max-height: 0;
                    }
                }
            }

            &:hover,
            &.active {

                >.accordion-title>a,
                >a {
                    &::before {
                        width: 100%;

                        @include mixin.pcSm {
                            width: 2px;
                        }
                    }
                }
            }

            &.option {
                position: initial;

                @include mixin.pcSm {
                    position: relative;
                }

                .accordion-content {
                    position: absolute;
                    width: 100%;
                    margin: auto;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    @include mixin.pcSm {
                        position: relative;
                    }
                }
            }

            >.accordion-title>a,
            >a {
                position: relative;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    display: inline-block;

                    bottom: -8px;
                    left: 50%;
                    width: 0;
                    height: 2px;
                    transform: translateX(-50%);
                    background-color: mixin.$mainBlue;
                    transition: width .25s cubic-bezier(.4, 0, .2, 1) 0s;

                    @include mixin.pcSm {
                        content: none;
                    }


                }

            }

            >a {

                position: relative;

                &::after {
                    @include mixin.pcSm {
                        content: '';
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background-image: url(../images/icon/icon-allow01-blue.svg);
                        background-size: 20px 20px;
                        background-repeat: no-repeat;
                        background-position: right 34px top 52%;
                    }
                }
            }

            .accordion {
                &-title {
                    // pointer-events: auto;
                    position: relative;

                    &::before,
                    &::after {
                        display: none;

                        @include mixin.pcSm {
                            display: block;
                        }
                    }

                    &::before {
                        content: none;

                        @include mixin.pcSm {
                            content: "";
                            position: absolute;
                            display: block;
                            right: 44px;
                            top: 1.25em;
                            bottom: initial;
                            left: initial;
                            width: 2px;
                            height: 0.75em;
                            transform: initial;
                            transition: all 0.3s;
                        }
                    }

                    &::after {

                        @include mixin.pcSm {
                            right: 44px;
                        }
                    }
                }

                &-content {
                    top: calc(94px - 100vh);

                    @include mixin.pc {
                        top: calc(58px - 100vh);
                    }

                    @include mixin.pcSm {
                        top: 0;
                    }
                }


            }

            .hover-visible {
                display: none;

                @include mixin.pcSm {
                    display: grid;
                }
            }

            .toggle:checked+.accordion-title+.accordion-content {
                max-height: 1000px;
                transition: all 1.5s;
            }

        }
    }

}

.header-english,
.header-english-result {
    >.inner {
        box-shadow: 0 1px 1px mixin.$mainLightGray;
        border-radius: 0 0 10px 10px;
        position: relative;

        pointer-events: none;

        background-color: mixin.$mainWhite;
        // background-color: rgba(255, 255, 255, 0.48);
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-right-color: rgba(255, 255, 255, 0.2);
        border-bottom-color: rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        &:hover {
            background: mixin.$mainWhite;
        }

        >* {
            pointer-events: auto;
        }

    }

    .list-nav {
        >.list-item {
            &:hover {
                >.accordion-title {
                    padding-bottom: 15.4vw;

                    @include mixin.pcLg {
                        padding-bottom: 18vw;
                    }

                    @include mixin.pc {
                        padding-bottom: 22vw;
                    }

                    @include mixin.pc1080 {
                        padding-bottom: 0;
                    }
                }

                >.accordion-title>a,
                >a {
                    opacity: 1;

                    span {
                        background-color: mixin.$mainWhite;
                        color: mixin.$mainBlack;

                        @include mixin.pc1080 {
                            background-color: inherit;
                            color: inherit;
                        }
                    }

                }

                .hover-visible {
                    display: grid;
                }

                .accordion-content {
                    max-height: 1000px;
                    transition: all .25s;
                    top: 94px;

                    @include mixin.pc {
                        top: 58px;
                    }

                    @include mixin.pc1080 {
                        top: 0;
                        max-height: 0;
                    }
                }
            }

            &:hover,
            &.active {

                >.accordion-title>a,
                >a {
                    &::before {
                        width: 100%;

                        @include mixin.pc1080 {
                            width: 2px;
                        }
                    }
                }
            }

            &.option {
                position: initial;

                @include mixin.pc1080 {
                    position: relative;
                }

                .accordion-content {
                    position: absolute;
                    width: 100%;
                    margin: auto;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    @include mixin.pc1080 {
                        position: relative;
                    }
                }
            }

            >.accordion-title>a,
            >a {
                position: relative;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    display: inline-block;

                    bottom: -8px;
                    left: 50%;
                    width: 0;
                    height: 2px;
                    transform: translateX(-50%);
                    background-color: mixin.$mainBlue;
                    transition: width .25s cubic-bezier(.4, 0, .2, 1) 0s;

                    @include mixin.pc1080 {
                        content: none;
                    }


                }

            }

            >a {

                position: relative;

                &::after {
                    @include mixin.pc1080 {
                        content: '';
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background-image: url(../images/icon/icon-allow01-blue.svg);
                        background-size: 20px 20px;
                        background-repeat: no-repeat;
                        background-position: right 34px top 52%;
                    }
                }
            }

            .accordion {
                &-title {
                    // pointer-events: auto;
                    position: relative;

                    &::before,
                    &::after {
                        display: none;

                        @include mixin.pc1080 {
                            display: block;
                        }
                    }

                    &::before {
                        content: none;

                        @include mixin.pc1080 {
                            content: "";
                            position: absolute;
                            display: block;
                            right: 44px;
                            top: 1.25em;
                            bottom: initial;
                            left: initial;
                            width: 2px;
                            height: 0.75em;
                            transform: initial;
                            transition: all 0.3s;
                        }
                    }

                    &::after {

                        @include mixin.pc1080 {
                            right: 44px;
                        }
                    }
                }

                &-content {
                    top: calc(94px - 100vh);

                    @include mixin.pc {
                        top: calc(58px - 100vh);
                    }

                    @include mixin.pc1080 {
                        top: 0;
                    }
                }


            }

            .hover-visible {
                display: none;

                @include mixin.pc1080 {
                    display: grid;
                }
            }

            .toggle:checked+.accordion-title+.accordion-content {
                max-height: 1000px;
                transition: all 1.5s;
            }

        }
    }

}

.header-chinese,
.header-chinese-result {
    >.inner {
        box-shadow: 0 1px 1px mixin.$mainLightGray;
        border-radius: 0 0 10px 10px;
        position: relative;

        pointer-events: none;

        background-color: mixin.$mainWhite;
        // background-color: rgba(255, 255, 255, 0.48);
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-right-color: rgba(255, 255, 255, 0.2);
        border-bottom-color: rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        &:hover {
            background: mixin.$mainWhite;
        }

        >* {
            pointer-events: auto;
        }

    }

    .list-nav {
        >.list-item {
            &:hover {
                >.accordion-title {
                    padding-bottom: 15.4vw;

                    @include mixin.pcLg {
                        padding-bottom: 18vw;
                    }

                    @include mixin.pc {
                        padding-bottom: 22vw;
                    }

                    @include mixin.pcSm {
                        padding-bottom: 0;
                    }
                }

                >.accordion-title>a,
                >a {
                    opacity: 1;

                    span {
                        background-color: mixin.$mainWhite;
                        color: mixin.$mainBlack;

                        @include mixin.pcSm {
                            background-color: inherit;
                            color: inherit;
                        }
                    }

                }

                .hover-visible {
                    display: grid;
                }

                .accordion-content {
                    max-height: 1000px;
                    transition: all .25s;
                    top: 94px;

                    @include mixin.pc {
                        top: 58px;
                    }

                    @include mixin.pcSm {
                        top: 0;
                        max-height: 0;
                    }
                }
            }

            &:hover,
            &.active {

                >.accordion-title>a,
                >a {
                    &::before {
                        width: 100%;

                        @include mixin.pcSm {
                            width: 2px;
                        }
                    }
                }
            }

            &.option {
                position: initial;

                @include mixin.pcSm {
                    position: relative;
                }

                .accordion-content {
                    position: absolute;
                    width: 100%;
                    margin: auto;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    @include mixin.pcSm {
                        position: relative;
                    }
                }
            }

            >.accordion-title>a,
            >a {
                position: relative;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    display: inline-block;

                    bottom: -8px;
                    left: 50%;
                    width: 0;
                    height: 2px;
                    transform: translateX(-50%);
                    background-color: mixin.$mainBlue;
                    transition: width .25s cubic-bezier(.4, 0, .2, 1) 0s;

                    @include mixin.pcSm {
                        content: none;
                    }


                }

            }

            >a {

                position: relative;

                &::after {
                    @include mixin.pcSm {
                        content: '';
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background-image: url(../images/icon/icon-allow01-blue.svg);
                        background-size: 20px 20px;
                        background-repeat: no-repeat;
                        background-position: right 34px top 52%;
                    }
                }
            }

            .accordion {
                &-title {
                    // pointer-events: auto;
                    position: relative;

                    &::before,
                    &::after {
                        display: none;

                        @include mixin.pcSm {
                            display: block;
                        }
                    }

                    &::before {
                        content: none;

                        @include mixin.pcSm {
                            content: "";
                            position: absolute;
                            display: block;
                            right: 44px;
                            top: 1.25em;
                            bottom: initial;
                            left: initial;
                            width: 2px;
                            height: 0.75em;
                            transform: initial;
                            transition: all 0.3s;
                        }
                    }

                    &::after {

                        @include mixin.pcSm {
                            right: 44px;
                        }
                    }
                }

                &-content {
                    top: calc(94px - 100vh);

                    @include mixin.pc {
                        top: calc(58px - 100vh);
                    }

                    @include mixin.pcSm {
                        top: 0;
                    }
                }


            }

            .hover-visible {
                display: none;

                @include mixin.pcSm {
                    display: grid;
                }
            }

            .toggle:checked+.accordion-title+.accordion-content {
                max-height: 1000px;
                transition: all 1.5s;
            }

        }
    }

}

/*----------------------------------------------------
Header search
------------------------------------------------------*/
.header-common,
.header-result {
    .header-search {
        @include mixin.pc {
            .toggle:checked+.accordion-title {
                padding-bottom: 50px;

                .icon-search {
                    background: none;
                    position: relative;

                    &::before,
                    &::after {
                        content: "";
                        display: block;
                        height: 3px;
                        width: 22px;
                        border-radius: 3px;
                        background-color: mixin.$mainGreen;
                        transition: 0.5s;
                        position: absolute;
                    }

                    &::before {
                        bottom: 6.5px;
                        transform: rotate(45deg);
                    }

                    &::after {
                        top: 6.5px;
                        transform: rotate(-45deg);
                    }
                }
            }

            .toggle:checked+.accordion-title+.accordion-content {
                max-height: 40px;
                transition: all 0.5s;
                top: 40px;

            }

        }

        .accordion {
            &-title {
                display: none;

                @include mixin.pc {
                    display: block;
                }

                @include mixin.pcSm {
                    display: none;
                }

                &::before,
                &::after {
                    content: none;
                }

                .icon-search {}

            }

            &-content {
                max-height: 1000px;

                @include mixin.pc {
                    max-height: 0;

                    position: absolute;
                    top: 32px;

                    right: 0;
                    width: calc(var(--vw) * 100 - 70px);
                }

                @include mixin.pcSm {
                    max-height: 1000px;
                    top: 0;
                    width: 100%;
                    position: relative;
                }
            }
        }

    }
}

.header-english,
.header-english-result {
    .header-search {
        @include mixin.pcXxl {
            .toggle:checked+.accordion-title {
                padding-bottom: 50px;

                .icon-search {
                    background: none;
                    position: relative;

                    &::before,
                    &::after {
                        content: "";
                        display: block;
                        height: 3px;
                        width: 22px;
                        border-radius: 3px;
                        background-color: mixin.$mainGreen;
                        transition: 0.5s;
                        position: absolute;
                    }

                    &::before {
                        bottom: 6.5px;
                        transform: rotate(45deg);
                    }

                    &::after {
                        top: 6.5px;
                        transform: rotate(-45deg);
                    }
                }
            }

            .toggle:checked+.accordion-title+.accordion-content {
                max-height: 40px;
                transition: all 0.5s;
                top: 40px;

            }

        }

        .accordion {
            &-title {
                display: none;

                @include mixin.pcXxl {
                    display: block;
                }

                @include mixin.pc1080 {
                    display: none;
                }

                &::before,
                &::after {
                    content: none;
                }

            }

            &-content {
                max-height: 1000px;

                @include mixin.pcXxl {
                    max-height: 0;

                    position: absolute;
                    top: 32px;

                    right: 0;
                    width: calc(var(--vw) * 100 - 140px);

                }

                @include mixin.pc {
                    width: calc(var(--vw) * 100 - 70px);
                }

                @include mixin.pc1080 {
                    max-height: 1000px;
                    top: 0;
                    width: 100%;
                    position: relative;
                }
            }
        }

    }
}

.header-chinese,
.header-chinese-result {
    .header-search {
        @include mixin.pc {
            .toggle:checked+.accordion-title {
                padding-bottom: 50px;

                .icon-search {
                    background: none;
                    position: relative;

                    &::before,
                    &::after {
                        content: "";
                        display: block;
                        height: 3px;
                        width: 22px;
                        border-radius: 3px;
                        background-color: mixin.$mainGreen;
                        transition: 0.5s;
                        position: absolute;
                    }

                    &::before {
                        bottom: 6.5px;
                        transform: rotate(45deg);
                    }

                    &::after {
                        top: 6.5px;
                        transform: rotate(-45deg);
                    }
                }
            }

            .toggle:checked+.accordion-title+.accordion-content {
                max-height: 40px;
                transition: all 0.5s;
                top: 40px;

            }

        }

        .accordion {
            &-title {
                display: none;

                @include mixin.pc {
                    display: block;
                }

                @include mixin.pcSm {
                    display: none;
                }

                &::before,
                &::after {
                    content: none;
                }

                .icon-search {}

            }

            &-content {
                max-height: 1000px;

                @include mixin.pc {
                    max-height: 0;

                    position: absolute;
                    top: 32px;

                    right: 0;
                    width: calc(var(--vw) * 100 - 70px);
                }

                @include mixin.pcSm {
                    max-height: 1000px;
                    top: 0;
                    width: 100%;
                    position: relative;
                }
            }
        }

    }
}