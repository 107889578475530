@use "mixin";
@use "utility";

/*----------------------------------------------------
Breadcrumb
------------------------------------------------------*/
.breadcrumb {
    @include mixin.articleBodyLg;

    @include mixin.flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px 30px;
    padding-top: 20px;
    padding-bottom: 10px;

    @include mixin.pc {
        padding-top: 14px;
    }

    @include mixin.tab {
        padding-top: 14px;
    }

    @include mixin.sp {
        gap: 5px 16px;
        padding-top: 12px;
    }

    .list-item {
        position: relative;
        white-space: nowrap;
        font-size: 1.4rem;

        @include mixin.sp {
            font-size: 1.2rem;
        }

        &:not(:last-of-type) {
            &::before {
                content: "|";
                position: absolute;
                top: 0;
                right: -16.5px;

                @include mixin.sp {
                    right: -8px;
                }
            }
        }

        a {
            padding: 0;
            border-bottom: 1px dotted mixin.$mainBlue;
        }
    }
}

/*----------------------------------------------------
heading
------------------------------------------------------*/
.headingOne {
    &01 {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 24px;
        color: mixin.$mainBlue;
        position: relative;
        padding: 0;

        @include mixin.pcXl {
            font-size: 3.2rem;
        }

        @include mixin.tab {
            font-size: 2.8rem;
        }

        @include mixin.sp {
            font-size: 2.4rem;
        }

        &::before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background: url(../images/icon/icon-circle.svg) center/cover no-repeat;
            top: -68px;
            left: 10px;

            @include mixin.pcXl {
                top: -68px;
            }

            @include mixin.tab {
                top: -56px;
            }

            @include mixin.sp {
                top: -52px;
            }
        }

        &::after {
            content: attr(data-text);
            position: absolute;
            -webkit-text-stroke: 1px mixin.$mainLightGray;
            text-stroke: 1px mixin.$mainLightGray;
            color: transparent;
            font-size: 4rem;
            font-weight: 400;
            top: -64px;
            left: 0;

            @include mixin.pcXl {
                top: -64px;
            }

            @include mixin.tab {
                top: -52px;
            }

            @include mixin.sp {
                font-size: 3.2rem;
                top: -48px;
            }
        }
    }

    &02 {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 24px;
        color: mixin.$mainBlue;
        position: relative;
        padding: 220px 0 0;

        @include mixin.pcLg {
            font-size: 3.2rem;
            padding: 180px 0 0;
        }

        @include mixin.tab {
            font-size: 2.4rem;
            padding: 140px 0 0;
        }

        &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 70px;
            background: url(../images/common/headingone01.svg) center bottom/cover no-repeat;
            top: 0;
            left: 24px;

            @include mixin.tab {
                left: 14px;
            }

            @include mixin.sp {
                font-size: 4.8rem;
                left: 2px;
            }
        }

        &::after {
            content: attr(data-text);
            position: absolute;
            -webkit-text-stroke: 1px mixin.$mainLightGray;
            text-stroke: 1px mixin.$mainLightGray;
            color: rgba(255, 255, 255, .95);
            font-size: 10rem;
            font-weight: 400;
            top: 56px;
            left: 0;

            @include mixin.pcLg {
                font-size: 8rem;
            }

            @include mixin.tab {
                font-size: 5.6rem;
            }

            @include mixin.sp {
                font-size: 4.8rem;
            }
        }
    }

    &02-2 {
        margin-bottom: 24px;
        position: relative;
        padding: 64px 0 0;
        color: mixin.$mainBlue;
        // font-size: 10rem;
        font-size: 8rem;
        font-weight: 400;
        letter-spacing: normal;
        text-shadow: 0 0 3px mixin.$mainWhite, 0 0 2px mixin.$mainWhite, 0 0 6px mixin.$mainWhite, 0 0 6px mixin.$mainWhite;

        @include mixin.pcLg {
            font-size: 6.4rem;
        }

        @include mixin.tab {
            font-size: 5.6rem;
            padding: 64px 0 0;
        }

        @include mixin.sp {
            font-size: 4.8rem;
        }

        &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 70px;
            background: url(../images/common/headingone01.svg) center bottom/cover no-repeat;
            top: 0;
            left: 24px;

            @include mixin.pcLg {
                left: 16px;
            }

            @include mixin.tab {
                left: 12px;
            }

            @include mixin.sp {
                font-size: 4.8rem;
                left: 10px;
            }
        }

    }
}

.headingTwo {
    &01 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 5px;
        color: mixin.$mainWhite;
        position: relative;
        padding: 96px 0 0;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 88px;
            background: url(../images/common/headingtwo01.svg) center bottom/cover no-repeat;
            bottom: 38px;
            left: 0;

            @include mixin.pcLg {
                content: none;
            }

        }

        @include mixin.pc1728 {
            padding: 72px 0 0;
        }


        @include mixin.pcLg {
            font-size: 1.8rem;
            padding: 0;
        }
    }

    &02 {
        font-size: 3.2rem;
        font-weight: 700;
        margin-bottom: 24px;
        color: mixin.$mainBlue;
        position: relative;
        padding: 48px 0 0;

        @include mixin.pcXl {
            font-size: 2.8rem;
        }

        @include mixin.tab {
            font-size: 2.4rem;
            padding: 40px 0 0;
        }

        @include mixin.sp {
            font-size: 2rem;
        }

        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 40px;
            background-color: mixin.$mainBlue;
            top: 0;
            left: 18px;

            @include mixin.tab {
                left: 10px;
                height: 32px;
            }
        }

        &.pdf {
            gap: 8px;

            &::after {
                content: "";
                background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                background-position-x: 0px;
                background-position-y: 0px;
                background-size: auto;
                background-size: 24px 24px;
                background-position: center center;
                width: 32px;
                height: 40px;
                display: inline-block;
                order: -1;

                @include mixin.pcXl {
                    width: 28px;
                }

                @include mixin.tab {
                    width: 24px;
                }

                @include mixin.sp {
                    width: 20px;
                }
            }

            // +* {
            //     * {
            //         &::after {
            //             content: none !important;
            //         }
            //     }
            // }
        }
    }

    &03 {
        font-size: 2.4rem;
        margin-bottom: 24px;
        color: mixin.$mainBlue;
        font-weight: 700;
        position: relative;
        padding: 8px 0 8px 24px;
        display: block;
        border-top: 1px solid mixin.$mainLightGray;

        @include mixin.pcXl {
            font-size: 2.2rem;
            margin-bottom: 20px;
        }

        @include mixin.tab {
            font-size: 2rem;
            margin-bottom: 10px;
        }

        @include mixin.sp {
            font-size: 1.8rem;
        }

        &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background: url(../images/common/headingthree01.svg) center center/cover no-repeat;
            inset: 0;
            margin: auto 0;

        }

        a {
            position: relative;
            display: block;
            width: fit-content;
            font-weight: 700;

            &::after {
                content: '';
                display: inline-block;
                margin: auto;
                width: 30px;
                height: 22px;
                background-image: url(../images/icon/icon-allow01-blue.svg);
                background-size: 22px 22px;
                background-repeat: no-repeat;
                background-position: right top 2px;
                padding: 0 0 0 8px;
            }
        }


    }

    &04 {
        font-size: 2.4rem;
        margin-bottom: 24px;
        color: mixin.$mainBlue;
        font-weight: 700;
        position: relative;
        padding: 8px 0;
        display: block;
        border-top: 1px solid mixin.$mainLightGray;

        @include mixin.pcXl {
            font-size: 2.2rem;
            margin-bottom: 20px;
        }

        @include mixin.tab {
            font-size: 2rem;
            margin-bottom: 10px;
        }

        @include mixin.sp {
            font-size: 1.8rem;
        }

        a {
            position: relative;
            display: block;
            width: fit-content;
            font-weight: 700;

            &::after {
                content: '';
                display: inline-block;
                margin: auto;
                width: 30px;
                height: 22px;
                background-image: url(../images/icon/icon-allow01-blue.svg);
                background-size: 22px 22px;
                background-repeat: no-repeat;
                background-position: right top 2px;
                padding: 0 0 0 8px;
            }
        }

        &.pdf {
            gap: 8px;

            &::before {
                content: "";
                background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                background-position-x: 0px;
                background-position-y: 0px;
                background-size: auto;
                background-size: 24px 24px;
                background-position: center center;
                width: 24px;
                height: 24px;
                display: inline-block;
                order: -1;
            }

            +* {
                * {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    &05 {
        font-size: 3.2rem;
        margin-bottom: 40px;
        color: mixin.$mainBlue;
        font-weight: 700;
        position: relative;
        padding: 8px 0;
        display: block;

        @include mixin.pcXl {
            font-size: 2.8rem;
        }

        @include mixin.tab {
            font-size: 2.4rem;
            margin-bottom: 20px;
        }

        @include mixin.sp {
            font-size: 2rem;
        }

        a {
            position: relative;
            display: block;
            width: fit-content;
            font-weight: 700;

            &::after {
                content: '';
                display: inline-block;
                margin: auto;
                width: 30px;
                height: 22px;
                background-image: url(../images/icon/icon-allow01-blue.svg);
                background-size: 22px 22px;
                background-repeat: no-repeat;
                background-position: right top 2px;
                padding: 0 0 0 8px;
            }
        }

        &.pdf {
            gap: 8px;

            &::before {
                content: "";
                background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                background-position-x: 0px;
                background-position-y: 0px;
                background-size: auto;
                background-size: 32px 32px;
                background-position: center center;
                width: 32px;
                height: 32px;
                display: inline-block;
                order: -1;

                @include mixin.pcXl {
                    background-size: 28px 28px;
                    width: 28px;
                    height: 28px;
                }

                @include mixin.tab {
                    background-size: 24px 24px;
                    width: 24px;
                    height: 24px;
                }

                @include mixin.sp {
                    background-size: 20px 20px;
                    width: 20px;
                    height: 20px;
                }
            }

            +* {
                * {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

}

.headingThree {
    &01 {
        font-size: 2.4rem;
        margin-bottom: 24px;
        color: mixin.$mainBlue;
        font-weight: 700;
        position: relative;
        padding: 0 0 0 24px;
        display: block;
        width: fit-content;

        @include mixin.pcXl {
            font-size: 2.2rem;
            margin-bottom: 20px;
        }

        @include mixin.tab {
            font-size: 2rem;
            margin-bottom: 10px;
        }

        @include mixin.sp {
            font-size: 1.8rem;
        }

        &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background: url(../images/common/headingthree01.svg) center center/cover no-repeat;
            margin: 0;
            top: 12px;
            bottom: 0;
            left: 0;
            @include mixin.tab {
                top: 8px;
            }
        }

    }

}

.headingFour {
    &01 {
        font-size: 2.2rem;
        margin-bottom: 10px;
        color: mixin.$mainBlue;
        position: relative;
        font-weight: 700;

        @include mixin.pcXl {
            font-size: 2rem;
        }

        @include mixin.tab {
            font-size: 1.8rem;
        }

        @include mixin.sp {
            font-size: 1.6rem;
        }
    }
}

*[class^="heading"] {
    @include mixin.flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .logo {
        border-radius: 10px;
        background-color: mixin.$mainWhite;
        padding: 20px;
        display: inline-block;
        width: 25vw;
        height: fit-content;
        max-width: 320px;
        max-height: 400px;

        @include mixin.tab {
            width: 30vw;
            padding: 16px;
        }

        @include mixin.tabSm {
            width: 100%;
            padding: 10px;
        }

        img {}
    }
}

:where(body).ch {
    .headingOne {
      &01,
      &02 {
        &::after {
            font-family: mixin.$font-family-serif01;
        }
      }
    }
}

/*----------------------------------------------------
Button
------------------------------------------------------*/
*[class^="button-"] {
    &:hover {
        opacity: 1;
    }
}

a.button-common,
button.button-common {
    @include mixin.flex;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    height: 54px;
    border-radius: 9999px;
    background: transparent linear-gradient(305deg, mixin.$subPink 0%, mixin.$mainPink 100%) 0% 0% no-repeat padding-box;

    box-shadow: 0px 3px 6px #24437733;
    color: mixin.$mainWhite;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    cursor: pointer;

    position: relative;

    &::before {
        content: '';
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-image: url(../images/icon/icon-allow01.svg);
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: right 18px top 52%;
    }

    @include mixin.sp {
        width: 100%;
        max-width: 100%;
        font-size: 1.4rem;
        padding: 5px 20px;
    }

    &:hover {
        background: mixin.$mainRed;
        box-shadow: 0px 1px 3px #24437733;
    }

}

a.button-blue,
button.button-blue {
    @include mixin.flex;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    height: 54px;
    border-radius: 9999px;
    background: transparent linear-gradient(305deg, lighten(grayscale(mixin.$subBlue), 30%) 0%, lighten(grayscale(mixin.$mainBlue), 30%) 100%) 0% 0% no-repeat padding-box;

    box-shadow: 0px 3px 6px #24437733;
    color: mixin.$mainWhite;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    cursor: pointer;

    position: relative;

    &::before {
        content: '';
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-image: url(../images/icon/icon-allow01.svg);
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: right 18px top 52%;
    }

    @include mixin.sp {
        width: 100%;
        max-width: 100%;
        font-size: 1.4rem;
        padding: 5px 20px;
    }

    &:hover {
        background: mixin.$mainBlue;
        box-shadow: 0px 1px 3px #24437733;
    }

}

a.button-middle,
button.button-middle {
    @include mixin.flex;
    justify-content: center;
    width: 100%;
    max-width: 240px;
    height: 48px;
    border-radius: 9999px;
    background: transparent linear-gradient(305deg, mixin.$subPink 0%, mixin.$mainPink 100%) 0% 0% no-repeat padding-box;

    box-shadow: 0px 3px 6px #24437733;
    color: mixin.$mainWhite;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    cursor: pointer;

    position: relative;

    &::before {
        content: '';
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-image: url(../images/icon/icon-allow01.svg);
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: right 18px top 52%;
    }

    @include mixin.tab {
        width: 100%;
        max-width: 100%;
        font-size: 1.4rem;
        padding: 5px 20px;
    }

    &:hover {
        background: mixin.$mainRed;
        box-shadow: 0px 1px 3px #24437733;
    }

    &.blue {
        background: transparent linear-gradient(305deg, lighten(grayscale(mixin.$subBlue), 30%) 0%, lighten(grayscale(mixin.$mainBlue), 30%) 100%) 0% 0% no-repeat padding-box;

        box-shadow: 0px 3px 6px #24437733;
        color: mixin.$mainWhite;

        &:hover {
            background: mixin.$mainBlue;
            box-shadow: 0px 1px 3px #24437733;
        }
    }

}

a.button-small,
button.button-small {
    &01 {
        @include mixin.flex;
        justify-content: center;
        width: 140px;
        height: 32px;
        border-radius: 9999px;
        background-color: mixin.$subBlue;
        color: mixin.$mainWhite;
        border: 2px solid mixin.$subBlue;
        outline: 1px solid mixin.$mainWhite;
        font-weight: 700;
        font-size: 1.5rem;
        letter-spacing: 0.1em;
        padding: 0 16px 0 0;
        cursor: pointer;

        position: relative;

        &.modal {
            &::before {
                position: absolute;
                margin: auto 0;
                right: 8px;
                left: inherit;
                content: '＋';
                font-weight: 700;
                width: 22px;
                height: 22px;
                display: block;
                color: mixin.$mainWhite;
                background-image: none;
            }
        }

        &::before {
            content: '';
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-image: url(../images/icon/icon-allow01.svg);
            background-size: 22px 22px;
            background-repeat: no-repeat;
            background-position: right 6px top 52%;
        }

        @include mixin.sp {
            width: 100%;
            max-width: 100%;
            height: 36px;
            font-size: 1.4rem;
            padding: 0 20px;
        }

        &:hover {
            background-color: mixin.$mainWhite;
            color: mixin.$mainBlue;
            border: 2px solid mixin.$mainBlue;
            outline: none;

            &::before {
                background-image: url(../images/icon/icon-allow01-blue.svg);
            }

            &.modal {
                &::before {
                    color: mixin.$mainBlue;
                    background-image: none;
                }
            }
        }

        &.white {
            background-color: mixin.$mainWhite;
            color: mixin.$mainBlue;
            border: 2px solid mixin.$mainBlue;
            outline: none;

            &::before {
                background-image: url(../images/icon/icon-allow01-blue.svg);

            }

            &:hover {
                background-color: mixin.$mainBlue;
                color: mixin.$mainWhite;
                border: 2px solid mixin.$mainBlue;
                outline: 1px solid mixin.$mainWhite;

                &::before {
                    background-image: url(../images/icon/icon-allow01.svg);
                }
            }
        }

        &.print {
            &::before {
                content: '';
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-image: url(../images/icon/icon-printer.svg);
                background-size: 22px 22px;
                background-repeat: no-repeat;
                background-position: right 10px top 52%;
            }

            &:hover {
                background-color: mixin.$mainWhite;
                color: mixin.$mainBlue;
                border: 2px solid mixin.$mainBlue;
                outline: none;

                &::before {
                    background-image: url(../images/icon/icon-printer-blue.svg);
                }
            }
        }

        &.close {
            background-color: mixin.$mainWhite;
            color: mixin.$mainBlue;
            border: 2px solid mixin.$mainBlue;
            outline: none;

            &::before {
                position: absolute;
                margin: auto 0;
                right: 8px;
                left: inherit;
                content: '×';
                font-weight: 700;
                width: 22px;
                height: 22px;
                display: block;
                color: mixin.$mainBlue;
                background-image: none;
            }

            &:hover {
                background-color: mixin.$mainBlue;
                color: mixin.$mainWhite;
                border: 2px solid mixin.$mainBlue;
                outline: 1px solid mixin.$mainWhite;

                &::before {

                    color: mixin.$mainWhite;
                    background-image: none;
                }
            }
        }
    }

    &02 {
        display: inline-block;
        width: auto;
        height: 32px;
        border-radius: 9999px;
        background-color: #657b9f;
        color: #fff;
        border: 2px solid #657b9f;
        font-weight: 700;
        font-size: 1.5rem;
        letter-spacing: .1em;
        padding: 2px 32px 0 20px;
        cursor: pointer;
        position: relative;
        max-width: 400px;
        text-align: center;

        &::before {
            content: '';
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-image: url(../images/icon/icon-allow01.svg);
            background-size: 22px 22px;
            background-repeat: no-repeat;
            background-position: right 6px top 52%;
        }

        @include mixin.sp {
            width: 100%;
            max-width: 100%;
            height: 36px;
            font-size: 1.4rem;
            padding: 0 20px;
        }

        &:hover {
            background-color: mixin.$mainWhite;
            color: mixin.$mainBlue;
            border: 2px solid mixin.$mainBlue;

            &::before {
                background-image: url(../images/icon/icon-allow01-blue.svg);

            }
        }

        &.white {
            background-color: mixin.$mainWhite;
            color: mixin.$mainBlue;
            border: 2px solid mixin.$mainBlue;

            &::before {
                background-image: url(../images/icon/icon-allow01-blue.svg);

            }

            &:hover {
                background-color: mixin.$mainBlue;
                color: mixin.$mainWhite;
                border: 2px solid mixin.$mainBlue;

                &::before {
                    background-image: url(../images/icon/icon-allow01.svg);
                }
            }
        }

    }

}

:where(body).en {

    a.button-small,
    button.button-small {
        &01 {
            width: 168px;

            @include mixin.sp {
                width: 100%;
            }
        }
    }
}

a.button-rounded,
button.button-rounded {
    @include mixin.flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 24px 32px;
    border-radius: 10px;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);
    background-color: mixin.$mainWhite;
    border: 1px solid mixin.$mainBlue;

    @include mixin.sp {
        padding: 16px 24px;
    }

    &:hover {
        outline: 1px solid mixin.$mainBlue;
        box-shadow: none;
    }

    .title {
        font-size: 2rem;

        @include mixin.pcLg {
            font-size: 1.8rem;

        }

        @include mixin.tabSm {
            font-size: 1.6rem;

        }

        &.warning {
            background-image: url(../images/icon/icon-warning.svg);
            background-size: 22px 22px;
            background-repeat: no-repeat;
            background-position: left top;
            padding-left: 32px;
            width: auto;
            line-height: 22px;
        }
    }
}

a.button-search02,
button.button-search02 {
    @include mixin.flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: 12px 16px 12px 12px;
    border-radius: 10px;
    background-color: mixin.$mainWhite;
    box-shadow: 0 3px 6px mixin.$mainLightGray;

    .title {
        padding-left: 22px;
        position: relative;
        display: block;

        &::before {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background: url(../images/icon/icon-circle.svg) center bottom/cover no-repeat;
            inset: 0;
            margin: auto 0;

        }
    }

    &.active,
    &:hover {
        box-shadow: none;
        background: linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;

        .title {
            color: mixin.$mainWhite;

            &::before {
                background: url(../images/icon/icon-circle-white.svg) center bottom/cover no-repeat;
            }
        }
    }

    &.active {
        background: linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;
    }
}

a.button-search03,
button.button-search03 {
    position: relative;
    @include mixin.flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px 26px 20px 20px;
    border-radius: 10px;
    background-color: mixin.$mainWhite;
    box-shadow: 0 3px 6px mixin.$mainLightGray;

    .title {
        padding-left: 22px;
        position: relative;
        display: block;

        &::before {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background: url(../images/icon/icon-circle.svg) center bottom/cover no-repeat;
            inset: 0;
            margin: auto 0;

        }
    }

    .text-select {
        position: absolute;
        @include mixin.flex;
        justify-content: flex-start;
        gap: 8px;
        right: 14px;
        bottom: 5px;

        font-size: 1.5rem;

        @include mixin.tabSm {
            font-size: 1.2rem;
        }

        &::after {
            content: '＋';
            display: block;
        }
    }

    .text-more {
        position: absolute;
        @include mixin.flex;
        justify-content: flex-start;
        gap: 8px;
        right: 14px;
        bottom: 5px;

        font-size: 1.4rem;

        cursor: pointer;

        @include mixin.tabSm {
            font-size: 1.2rem;
        }

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background-image: url(../images/icon/icon-allow01-blue.svg);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &[href*="https:"],
    &[href*="http:"] {
        .text-more {
            &::after {
                background-image: url(../images/icon/icon-external.svg);
                background-size: 12px 12px;
            }
        }
    }

    &.active,
    &:hover {
        box-shadow: none;
        // background: url(../images/icon/icon-allow01.svg) right 10px center/22px 22px no-repeat, linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;
        background: linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;

        .title {
            color: mixin.$mainWhite;

            &::before {
                // background: url(../images/icon/icon-circle-white.svg) center bottom/cover no-repeat;
            }
        }

        .text-more {
            &::after {
                background-image: url(../images/icon/icon-allow01.svg);
            }
        }

        &[href*="https:"],
        &[href*="http:"] {
            .text-more {
                &::after {
                    background-image: url(../images/icon/icon-external.svg);
                }
            }
        }
    }

    &.active {
        background: linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;
    }
}

a.button-link,
button.button-link {
    position: relative;
    @include mixin.flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: 12px;
    border-radius: 10px;
    background-color: mixin.$mainWhite;
    box-shadow: 0 3px 6px mixin.$mainLightGray;

    &::after {
        content: none;
    }

    .title {
        // padding-left: 22px;
        position: relative;
        display: block;
    }

    &.active,
    &:hover {
        box-shadow: none;
        background: linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;

        .title {
            color: mixin.$mainWhite;
        }

        &::after {
            content: none;
        }

    }

    &.active {
        background: linear-gradient(147deg, mixin.$mainBlue 0%, mixin.$subBlue 100%) 0% 0% no-repeat;
    }


    .text-more {
        position: absolute;
        @include mixin.flex;
        justify-content: flex-start;
        gap: 8px;
        right: 14px;
        bottom: 5px;

        font-size: 1.4rem;

        cursor: pointer;

        @include mixin.tabSm {
            font-size: 1.2rem;
        }

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background-image: url(../images/icon/icon-allow01-blue.svg);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &[href*="https:"],
    &[href*="http:"] {
        .text-more {
            &::after {
                background-image: url(../images/icon/icon-external.svg);
                background-size: 12px 12px;
            }
        }

        &:hover {
            .text-more {
                &::after {
                    background-image: url(../images/icon/icon-external-white.svg);
                }
            }
        }
    }

    &[href*=".pdf"] {
        .text-more {
            &::after {
                background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                background-size: 13px 16px;
                margin: 0;
                width: 13px;
                height: 16px;
            }
        }

        &:hover {
            .text-more {
                &::after {
                    background-image: url(../images/icon/icon-pdf-white.svg);
                }
            }
        }
    }
}

/*----------------------------------------------------
Button Search
------------------------------------------------------*/
.button-movie-wrap,
.button-map-wrap,
.button-search-wrap {
    position: relative;
    width: 100%;
    cursor: pointer;

    &.small {
        max-width: 245px;

        @include mixin.tabXs {
            max-width: 208px;
        }

        @include mixin.sp {
            max-width: 100%;
        }
    }

    &.active {
        pointer-events: none;
        z-index: 1001;

        * {
            pointer-events: auto;
        }

        .button-movie-area,
        .button-map-area,
        .button-search-list-area {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: fit-content;
            z-index: 1002;

            animation-name: fadeInAnime;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            opacity: 0;

            &.right {
                left: 0;
                right: 0;
            }
        }

        .overlay {
            cursor: pointer;
        }
    }

    .button-movie-area,
    .button-map-area,
    .button-search-list-area {
        display: none;

        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;

        @include mixin.pcLg {
            margin-left: 40px;
            margin-right: 40px;
        }

        @include mixin.pc {
            margin-left: 20px;
            margin-right: 20px;
        }

        @include mixin.tab {
            margin-left: 15px;
            margin-right: 15px;
        }

        .button-search-list {
            overflow-y: auto;
        }

        >.button-area {

            a.button-small01,
            button.button-small01 {
                &.white {
                    display: block;
                }

                @include mixin.sp {
                    width: 140px;
                }
            }
        }

    }
}

.button-noicon,
.button-movie,
.button-map,
.button-pdf,
.button-search {
    position: relative;
    @include mixin.flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 630px;
    height: 100%;
    min-height: 75px;
    padding: 20px 32px;
    border-radius: 10px;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);
    background-color: mixin.$mainWhite;
    border: 1px solid mixin.$mainBlue;

    overflow-wrap: anywhere;
    word-break: normal;
    line-break: strict;

    @include mixin.tab {
        max-width: 100%;
    }

    @include mixin.tabSm {
        padding: 20px 24px;
    }

    &.small {
        max-width: 245px;
        height: 48px;
        padding: 0 24px;

        @include mixin.tabXs {
            max-width: 208px;
        }

        @include mixin.sp {
            max-width: 100%;
        }
    }

    &:hover {
        outline: 1px solid mixin.$mainBlue;
        box-shadow: none;
    }

    .title {
        padding-left: 40px;
        font-size: 1.8rem;
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: left top 52%;

        @include mixin.tabSm {
            font-size: 1.6rem;
        }

        &.youto {
            background-image: url(../images/icon/icon-hand.svg);
        }

        &.kinou {
            background-image: url(../images/icon/icon-kinou.svg);
        }

        &.yellow {
            background-image: url(../images/icon/icon-yellow.webp);

            br {
                @include mixin.pc1080 {
                    display: none;
                }

                @include mixin.tab {
                    display: block;
                }

                @include mixin.sp {
                    display: none;
                }
            }
        }

        &.cmj {
            background-image: url(../images/icon/icon-cmj.webp);
        }

        &.bussei {
            background-image: url(../images/icon/icon-info.svg);
        }

        &.seikei {
            background-image: url(../images/icon/icon-spanner.svg);
        }

        &.seihin {
            background-image: url(../images/icon/icon-measuring.svg);
        }

        &.list {
            background-image: url(../images/icon/icon-list.svg);
        }

        &.plan {
            background-image: url(../images/icon/icon-plan.svg);
        }

        &.movie {
            background-image: url(../images/icon/icon-movie.svg);
        }

        &.map {
            background-image: url(../images/icon/icon-map.svg);
        }

        &.inquiry {
            background-image: url(../images/icon/icon-inquiry.svg);
        }

        &.pdf {
            background-image: url(../images/icon/icon-pdf.svg);
            background-size: 22px 24px;
            background-position: left center;
        }
        
        &.search-seihin {
            background-image: url(../images/icon/icon-gear-blue.svg);
        }

        &.noicon {
            padding-left: 0;
            margin: auto 0 !important;
        }
    }

    .text-select {
        position: absolute;
        @include mixin.flex;
        justify-content: flex-start;
        gap: 8px;
        right: 14px;
        bottom: 5px;

        font-size: 1.5rem;

        @include mixin.tabSm {
            font-size: 1.2rem;
        }

        &::after {
            content: '＋';
            display: block;
        }
    }

    .text-more {
        position: absolute;
        @include mixin.flex;
        justify-content: flex-start;
        gap: 8px;
        right: 14px;
        bottom: 5px;

        font-size: 1.4rem;

        cursor: pointer;

        @include mixin.tabSm {
            font-size: 1.2rem;
        }

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background-image: url(../images/icon/icon-allow01-blue.svg);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .text-click {
        position: absolute;
        @include mixin.flex;
        justify-content: flex-start;
        gap: 8px;
        right: 14px;
        bottom: 5px;

        font-size: 1.5rem;

        @include mixin.tabSm {
            font-size: 1.2rem;
        }

        &::after {
            content: '＋';
            display: block;
        }
    }

    &[href*="mailto:"],
    &[href*="https:"],
    &[href*="http:"] {
        .text-more {
            &::after {
                background-image: url(../images/icon/icon-external.svg);
                background-size: 12px 12px;
            }
        }
    }

}

.button-search-lg {
    position: relative;
    @include mixin.flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 630px;
    height: 160px;
    border: 1px solid mixin.$mainBlue;
    border-radius: 10px;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);
    background-color: mixin.$mainWhite;

    overflow: hidden;

    @include mixin.tab {
        max-width: 100%;
        height: 100px;
    }

    @include mixin.sp {
        height: 120px;
    }

    &:hover {
        opacity: 1;
        outline: 1px solid mixin.$mainBlue;
        box-shadow: none;


        .title-area {

            &::before {
                opacity: 1;
            }

            &::after {
                opacity: 1;
            }

            .title {
                color: mixin.$mainWhite;

                &.list {
                    background-image: url(../images/icon/icon-list-white.svg);
                }
            }
        }

    }

    .title-area,
    .product-area {
        padding: 0 32px;
        height: 100%;
        @include mixin.flex;
        position: relative;

        @include mixin.tabSm {
            padding: 0 24px;
        }
    }

    .title-area {
        justify-content: flex-start;
        width: 45%;

        @include mixin.pcSm {
            width: 50%;
        }

        @include mixin.sp {
            width: 45%;
        }

        .triangle {
            content: '';
            position: absolute;
            margin: auto 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 114px;
            height: 114px;
            border-top: 1px solid mixin.$mainBlue;
            border-right: 1px solid mixin.$mainBlue;
            transform: rotate(45deg);
        }

        &::before {
            content: '';
            position: absolute;
            margin: auto 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 114px;
            height: 114px;
            border-top: 1px solid mixin.$mainBlue;
            border-right: 1px solid mixin.$mainBlue;
            transform: rotate(45deg);
            transition: all 1s ease-out;
            background: linear-gradient(45deg, transparent 0%, transparent 50%, mixin.$mainBlue 50%, mixin.$mainBlue 100%);
            opacity: 0;
        }

        &::after {
            content: '';
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            transition: all 1s ease-out;
            background: linear-gradient(to right, mixin.$mainBlue 0%, mixin.$mainBlue calc(100% - 58px), transparent calc(100% - 58px), transparent 100%);
            opacity: 0;
        }
    }

    .product-area {
        justify-content: center;
        gap: 24px;
        width: 55%;
        padding-left: 48px;

        @include mixin.pcSm {
            width: 50%;
            flex-direction: column;
            gap: 10px;
        }

        @include mixin.tab {
            flex-direction: row;
            gap: 20px;
        }

        @include mixin.sp {
            width: 50%;
            flex-direction: column;
        }

        img {
            display: block;
        }

    }

    .title {
        padding-left: 40px;
        font-size: 1.8rem;
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: left top 52%;
        z-index: 1;

        @include mixin.tabSm {
            font-size: 1.6rem;
            padding-left: 32px;
        }

        &.list {
            background-image: url(../images/icon/icon-list.svg);
        }
    }

    .text-more {
        position: absolute;
        @include mixin.flex;
        justify-content: flex-start;
        gap: 8px;
        right: 14px;
        bottom: 5px;
        color: mixin.$mainBlue;
        font-size: 1.4rem;

        cursor: pointer;

        @include mixin.tabSm {
            font-size: 1.2rem;
        }

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background-image: url(../images/icon/icon-allow01-blue.svg);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.button-movie-area,
.button-map-area,
.button-search-list-area {
    border-radius: 10px;
    background-color: mixin.$transparentMainBlue;
    border: 1px solid mixin.$mainWhite;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 1;
    max-height: 80vh;
    width: 100%;
    max-width: 1280px;
    padding: 40px 64px;

    @include mixin.pcLg {
        padding: 40px;
        width: calc(100% - 80px);
    }

    @include mixin.pc {
        width: calc(100% - 40px);
    }

    @include mixin.tab {
        width: calc(100% - 30px);
        padding: 20px;
    }

    @include mixin.sp {
        padding: 20px 10px;
    }

    &.small {
        max-width: 800px;
    }

    >*:not(:last-child) {
        margin-bottom: 20px;
    }

    .dlist-horizontal {
        dt {
            width: 110px;

            @include mixin.tabXs {
                width: 100%;
            }
        }

        dd {
            width: calc(100% - 118px);

            @include mixin.tabXs {
                width: 100%;
            }
        }
    }

    video {
        width: 100%;
        max-width: 100%;
        height: fit-content;
        max-height: calc(80vh - 100px);
    }

    .gmap {
        iframe {
            max-width: 600px;
            width: 100%;
            height: auto;
            aspect-ratio: 4/3;
            margin: 0 auto;
        }
    }

    >.button-area {
        justify-content: flex-end;

        @include mixin.sp {
            justify-content: center;
        }

        .button-small01.white {
            display: none;
        }
    }

}

:where(.button-movie-area, .button-map-area, .button-search-list-area) {
    &.small {
        .inner {
            >* {
                max-width: 600px;
                margin: 0 auto;
            }
        }
    }

    .inner {
        max-height: calc(80vh - 108px);
        overflow-y: auto;
        background-color: mixin.$mainWhite;
        padding: 40px;

        @include mixin.sp {
            padding: 20px;
        }

        >*:not(:last-child) {
            margin-bottom: 10px;
        }

    }
}

.button-search-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    max-height: calc(80vh - 88px);
    overflow-y: auto;

    @include mixin.pcLg {
        gap: 10px;
    }

    @include mixin.pcSm {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mixin.tab {
        grid-template-columns: 1fr;
    }

    .list-item {
        a {
            display: block;
            width: 100%;
            height: 100%;
            min-height: 112px;
            padding: 20px;
            border-radius: 10px;
            background-color: mixin.$mainWhite;
            box-shadow: 0 3px 6px mixin.$mainBlue;

            background-image: url(../images/icon/icon-allow01-blue.svg);
            background-size: 22px 22px;
            background-repeat: no-repeat;
            background-position: right 10px bottom 10px;

            @include mixin.tab {
                min-height: auto;
            }

            .title {
                padding-left: 22px;
                position: relative;
                @include mixin.flex;
                align-items: center;
                height: 100%;

                &::before {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    background: url(../images/icon/icon-circle.svg) center bottom/cover no-repeat;
                    top: 2px;
                    bottom: 0;
                    left: 0;
                    margin: auto 0;
                }
            }
        }
    }
}

/*----------------------------------------------------
Modal
------------------------------------------------------*/
.modal-block {
    border-radius: 10px;
    background-color: mixin.$transparentMainBlue;
    border: 1px solid mixin.$mainWhite;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 1;
    max-height: 80vh;
    width: 100%;
    max-width: 1280px;
    padding: 40px 64px;

    @include mixin.pcLg {
        padding: 40px;
        // width: calc(100% - 80px);
    }

    // @include mixin.pc {
    //     width: calc(100% - 40px);
    // }

    @include mixin.tab {
        // width: calc(100% - 30px);
        padding: 20px;
    }

    @include mixin.sp {
        padding: 20px 10px;
    }

    &.small {
        max-width: 800px;

        .inner {
            >* {
                max-width: 600px;
                margin: 0 auto;
            }
        }
    }

    .inner {
        max-height: calc(80vh - 108px);
        overflow-y: auto;
        background-color: mixin.$mainWhite;
        padding: 40px;

        @include mixin.sp {
            padding: 20px;
        }

        >*:not(:last-child) {
            margin-bottom: 20px;
        }

    }

    >*:not(:last-child) {
        margin-bottom: 20px;
    }

    .dlist-horizontal {
        dt {
            width: 110px;

            @include mixin.tabXs {
                width: 100%;
            }
        }

        dd {
            width: calc(100% - 118px);

            @include mixin.tabXs {
                width: 100%;
            }
        }
    }

    video {
        width: 100%;
        max-width: 100%;
        height: fit-content;
        max-height: calc(80vh - 100px);
    }

    .gmap {
        iframe {
            max-width: 600px;
            width: 100%;
            height: auto;
            aspect-ratio: 4/3;
            margin: 0 auto;
        }
    }

    >.button-area {
        justify-content: flex-end;

        @include mixin.sp {
            justify-content: center;
        }

        .button-small01.white {
            display: none;
        }
    }
}

.modal-block-wrap {
    background-color: transparent;
    width: 100%;
    max-width: 1280px;

    @include mixin.pcLg {
        width: calc(100% - 80px);
    }

    @include mixin.pc {
        width: calc(100% - 40px);
    }

    @include mixin.tab {
        width: calc(100% - 30px);
    }

    &.small {
        max-width: 800px;
    }
}

/*----------------------------------------------------
Link
------------------------------------------------------*/
a.link-common {
    overflow-wrap: break-word;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -24px;
        margin: auto 0;
        display: inline-block;
        margin: 0 0 0 1em;
        width: 24px;
        height: 24px;
        background: url(../images/icon/icon-allow01-blue.svg) 0 50% no-repeat;
    }
}

/*----------------------------------------------------
Download
------------------------------------------------------*/
.download-area {
    @include mixin.flex;
    align-items: flex-start;
    gap: 40px;

    @include mixin.tabSm {
        gap: 20px;
    }

    @include mixin.sp {
        gap: 10px;
    }
}

.download {
    @include mixin.flex;
    justify-content: center;
    flex-direction: column;
    width: 240px;
    height: 230px;
    border-radius: 10px;

    @include mixin.pcSm {
        width: 200px;
        height: 200px;
    }

    @include mixin.tabSm {
        width: 180px;
        height: 180px;
    }

    @include mixin.sp {
        max-width: 180px;
        max-height: 180px;
        width: calc(50vw - 20px);
        height: calc(50vw - 20px);
    }

    >.image {
        @include mixin.flex;
        justify-content: center;
        width: 140px;
        height: 140px;
        background-color: mixin.$mainWhite;

        @include mixin.pcSm {
            width: 120px;
            height: 120px;
        }

        @include mixin.tabSm {
            width: 100px;
            height: 100px;
        }
    }

    >.title {
        color: mixin.$mainWhite;
        text-align: center;
        font-size: 1.4rem;

        @include mixin.tab {
            font-size: 1.3rem;
        }

        @include mixin.sp {
            font-size: 1.2rem;
        }
    }

    >*:not(:last-child) {
        margin-bottom: 16px;
    }

    &.blue {
        background: linear-gradient(147deg, mixin.$subBlue 0%, mixin.$mainBlue 100%) 0% 0% no-repeat;
    }

    &.pink {
        background: linear-gradient(147deg, mixin.$subPink 0%, mixin.$mainPink 100%) 0% 0% no-repeat;
    }
}

/*----------------------------------------------------
画像
------------------------------------------------------*/
.img {
    overflow: hidden;
    width: fit-content;
    height: fit-content;

    &.right {
        float: right;
        max-width: 320px;
        width: 100%;
        padding: 10px 0 10px 40px;
        margin: 0;

        img {
            width: 100%;
            border-radius: 10px;
        }

        @include mixin.pc {
            max-width: 30%;
        }

        @include mixin.tabXs {
            float: none;
            max-width: 480px;
            padding: 10px 0;
            margin: 0 auto;
            order: 1000;
        }

        &.top {
            @include mixin.tabXs {
                order: -1;
            }
        }
    }

    &.left {
        float: left;
        max-width: 320px;
        width: 100%;
        padding: 10px 40px 10px 0;
        margin: 0;

        img {
            width: 100%;
            border-radius: 10px;
        }

        @include mixin.pc {
            max-width: 30%;
        }

        @include mixin.tabXs {
            float: none;
            max-width: 480px;
            padding: 10px 0;
            margin: 0 auto;
            order: 1000;
        }

        &.top {
            @include mixin.tabXs {
                order: -1;
            }
        }
    }

    &.center {
        display: block;
        max-width: 850px;
        width: 100%;
        margin: 0 auto;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            border-radius: 10px;
        }
    }

    &.center-large {
        display: block;
        width: 100%;
        margin: 0 auto;
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        img {
            width: 100%;
            height: auto;
            margin: 0 auto;
            border-radius: 10px;
        }
    }

    &.center-mx-lg {
        display: block;
        max-width: 850px;
        width: 100%;
        margin: 160px auto;
        text-align: center;

        @include mixin.pc {
            margin: 120px auto;
        }

        @include mixin.pcSm {
            margin: 80px auto;
        }

        @include mixin.tab {
            margin: 40px auto;
        }

        img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            border-radius: 10px;
        }
    }

    &.center-mx-md {
        display: block;
        max-width: 600px;
        width: 100%;
        margin: 40px auto;
        text-align: center;

        @include mixin.tab {
            margin: 20px auto;
        }

        img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            border-radius: 10px;
        }
    }

    &.u-flex {
        &-1 {
          @extend.u-flex-1;
        }
      
        &-2 {
          @extend.u-flex-2;
        }
      
        &-3 {
          @extend.u-flex-3;
        }
      
        &-4 {
          @extend.u-flex-4;
        }
      
        &-5 {
          @extend.u-flex-5;
        }
      
        &-6 {
          @extend.u-flex-6;
        }
      
        &-7 {
          @extend.u-flex-7;
        }
      
        &-8 {
          @extend.u-flex-8;
        }
      
        &-9 {
          @extend.u-flex-9;
        }
      
        &-10 {
          @extend.u-flex-10;
        }
      
        &-11 {
          @extend.u-flex-11;
        }
      
        &-12 {
          @extend.u-flex-12;
        }
      }
      

    >*:not(:last-child) {
        margin-bottom: 10px;
    }

    img {
        max-width: 850px;
        width: 100%;
        border-radius: 10px;
    }

    video {
        max-width: 850px;
        width: 100%;
    }

}

figcaption {
    text-align: center;

    >*:not(:last-child) {
        margin-bottom: 10px;
    }
}

/*----------------------------------------------------
Box
------------------------------------------------------*/
.box-common {
    @include mixin.flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-right-color: rgba(255, 255, 255, 0.2);
    border-bottom-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    padding: 40px;
    border-radius: 10px;

    @include mixin.pcLg {
        padding: 20px;
    }

    @include mixin.sp {
        padding: 10px;
    }

    >*:not(:last-child) {
        margin-bottom: 24px;
    }
}

.box-blue {
    @include mixin.flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: mixin.$transparentSubBlue;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-right-color: rgba(255, 255, 255, 0.2);
    border-bottom-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    padding: 40px;
    border-radius: 10px;

    @include mixin.pcLg {
        padding: 20px;
    }

    @include mixin.sp {
        padding: 10px;
    }

    .text {
        font-weight: 700;
    }

    >*:not(:last-child) {
        margin-bottom: 24px;
    }
}

.box-border {
    @include mixin.flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid mixin.$mainBlue;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    padding: 40px;
    border-radius: 10px;

    @include mixin.pcLg {
        padding: 20px;
    }

    @include mixin.sp {
        padding: 10px;
    }

    >*:not(:last-child) {
        margin-bottom: 24px;
    }
}

/*----------------------------------------------------
List
------------------------------------------------------*/
ul.list {
    &-horizontal {
        @include mixin.flex;

        .list-item {
            a {
                padding: 8px;
            }
        }
    }

    &-disc {
        >.list-item {
            position: relative;
            padding-left: 24px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::after {
                position: absolute;
                content: '';
                top: 6px;
                left: 0;
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 9999px;
                background-color: mixin.$mainBlue;

                // @include mixin.sp {
                //     top: 6px;
                // }
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-disc02 {
        >.list-item {
            position: relative;
            padding-left: 16px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::before {
                position: absolute;
                content: '';
                top: 12px;
                left: 6px;
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 9999px;
                background-color: mixin.$mainBlue;

                @include mixin.tab {
                    top: 10px;
                }

            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-circle {
        >.list-item {
            position: relative;
            display: block;
            padding-left: 24px;
            // @include mixin.flex;
            // justify-content: flex-start;
            // align-items: flex-start;
            // gap: 8px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }


            &::before {
                position: absolute;
                content: '';
                display: block;
                width: 14px;
                min-width: 14px;
                height: 14px;
                border-radius: 9999px;
                border: 1px solid mixin.$mainBlue;
                top: 6px;
                left: 0;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }

            >dl,
            ul {
                @include mixin.sp {
                    padding-left: 0;
                }
            }

        }
    }

    &-square {
        .list-item {
            position: relative;
            padding-left: 24px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::after {
                position: absolute;
                content: '';
                top: 6px;
                left: 0;
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 2px;
                background-color: mixin.$mainBlue;

                @include mixin.tab {
                    top: 8px;
                }

                @include mixin.tab {
                    top: 6px;
                }
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }

        +.square {
            padding-top: 20px;
            position: relative;

            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background-color: mixin.$mainBlue;
                top: -10px;
                left: 0;

                @include mixin.tab {
                    top: 0;
                }
            }
        }
    }

    &-number {
        counter-reset: numberList01;

        >.list-item {
            position: relative;
            margin-left: -8px;
            padding-left: 40px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::before {
                content: '（'counter(numberList01) '）';
                counter-increment: numberList01;

                @include mixin.flex;
                justify-content: center;
                width: 4rem;

                position: absolute;
                top: 0;
                left: 0;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-number02 {
        counter-reset: numberList02;

        >.list-item {
            position: relative;
            margin-left: -8px;
            padding-left: 40px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::before {
                content: counter(numberList02) '. ';
                counter-increment: numberList02;

                @include mixin.flex;
                justify-content: center;
                width: 4rem;

                position: absolute;
                top: 0;
                left: 0;
            }

            a {

                overflow-wrap: break-word;
                color: mixin.$mainBlue;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-number03 {
        counter-reset: numberList03;

        >.list-item {
            position: relative;
            margin-left: -8px;
            padding-left: 40px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::before {
                content: counter(numberList03, cjk-ideographic) '. ';
                counter-increment: numberList03;

                @include mixin.flex;
                justify-content: center;
                width: 4rem;

                position: absolute;
                top: 0;
                left: 0;
            }

            a {

                overflow-wrap: break-word;
                color: mixin.$mainBlue;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-number04 {
        counter-reset: numberList04;

        >.list-item {
            position: relative;
            margin-left: -8px;
            padding-left: 40px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::before {
                content: counter(numberList04, lower-alpha) '. ';
                counter-increment: numberList04;

                @include mixin.flex;
                justify-content: center;
                width: 4rem;

                position: absolute;
                top: 0;
                left: 0;
            }

            a {

                overflow-wrap: break-word;
                color: mixin.$mainBlue;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-number05 {
        counter-reset: numberList05;

        >.list-item {
            position: relative;
            margin-left: -8px;
            padding-left: 40px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::before {
                content: counter(numberList05, lower-roman) '. ';
                counter-increment: numberList05;

                @include mixin.flex;
                justify-content: center;
                width: 4rem;

                position: absolute;
                top: 0;
                left: 0;
            }

            a {

                overflow-wrap: break-word;
                color: mixin.$mainBlue;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-number06 {
        counter-reset: numberList06;

        >.list-item {
            position: relative;
            margin-left: -8px;
            padding-left: 56px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &::before {
                content: counter(numberList06) '.';
                counter-increment: numberList06;
                font-size: 4.6rem;
                font-family: mixin.$font-family-eng;
                color: mixin.$mainBlue;
                @include mixin.flex;
                align-items: flex-start;
                justify-content: center;
                width: 5rem;
                line-height: 1.2;

                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
            }

            a {
                overflow-wrap: break-word;

                .small {
                    font-size: 1.2rem;
                }

                &:hover {
                    color: mixin.$mainBlue;
                    text-decoration: underline;
                }
            }
        }
    }

    &-short-line {
        .list-item {
            position: relative;
            padding-left: 1em;

            &:not(:last-child) {
                margin-bottom: 8px;
            }


            &::after {
                position: absolute;
                content: '-';
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
                display: block;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-note {
        counter-reset: noteNumberList01;
        font-size: 1.4rem;

        &:not(:last-child) {
            margin-bottom: 8px;

        }

        @include mixin.sp {
            font-size: 1.2rem;
        }

        .list-item {
            position: relative;
            padding-left: 2rem;

            &:not(:last-child) {
                margin-bottom: 8px;

            }

            &::after {
                position: absolute;
                content: counter(noteNumberList01)' ）';
                counter-increment: noteNumberList01;

                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
                display: block;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-note02 {
        font-size: 1.4rem;

        @include mixin.sp {
            font-size: 1.2rem;
        }

        .list-item {
            position: relative;
            padding-left: 1.5em;
            text-indent: -1.5em;

            &:not(:last-child) {
                margin-bottom: 8px;

            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-note03 {
        counter-reset: noteNumberList03;
        font-size: 1.4rem;

        &:not(:last-child) {
            margin-bottom: 8px;

        }

        @include mixin.sp {
            font-size: 1.2rem;
        }

        .list-item {
            position: relative;
            padding-left: 3em;

            &:not(:last-child) {
                margin-bottom: 8px;

            }

            &::after {
                position: absolute;
                content: '注'counter(noteNumberList03)'）';
                counter-increment: noteNumberList03;

                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
                display: block;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-note04 {
        counter-reset: noteNumberList04;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .list-item {
            position: relative;
            padding-left: 2.4rem;

            &:not(:last-child) {
                margin-bottom: 8px;

            }

            &::after {
                position: absolute;
                content: "*"counter(noteNumberList04);
                counter-increment: noteNumberList04;

                width: 2.4rem;
                height: 2.4rem;
                top: 0;
                left: 2px;
                margin: 0;
                display: block;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }


    &-note-large {
        counter-reset: noteNumberList02;

        .list-item {
            position: relative;
            padding-left: 2.4rem;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &::after {
                position: absolute;
                content: counter(noteNumberList02)' ）';
                counter-increment: noteNumberList02;

                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
                display: block;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-note-large02 {
        &:not(:last-child) {
            margin-bottom: 8px;

        }

        .list-item {
            position: relative;
            padding-left: 1.6rem;

            @include mixin.sp {
                padding-left: 1.4rem;
            }

            &:not(:last-child) {
                margin-bottom: 8px;

            }

            &::after {
                position: absolute;
                content: '*';
                width: 1.6rem;
                height: 1.6rem;
                top: 3px;
                left: 2px;
                margin: 0;
                display: block;

                @include mixin.sp {
                    width: 1.4rem;
                    height: 1.4rem;
                    top: 2px;
                }
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-hover {
        li {
            a {
                width: 100%;
                display: block;

                &:hover {
                    background-color: mixin.$mainBlue;
                }

            }
        }
    }

    &-common {
        >.list-item {
            position: relative;

            &:not(:last-child) {
                margin-bottom: 10px;

                // @include mixin.tab {
                //     margin-bottom: 10px;
                // }
            }

            &.indent {
                padding-left: 1em;
                text-indent: -1em;
            }

            a {
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"]:not([class^="button-"]) {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                &.allow01 {
                    @include mixin.flex;
                    justify-content: flex-start;
                    gap: 8px;

                    &::after {
                        content: "》";
                        display: block;
                    }

                    &[href*="https:"],
                    &[href*="http:"],
                    &[href*=".pdf"] {
                        &::after {
                            content: '';
                        }
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }

            >ul,
            dl {
                padding-left: 16px;
            }

            >dl,
            ul {
                padding-left: 16px;
            }
        }
    }

    &-even {
        @include mixin.tab {
            line-height: 1.5;
        }

        .list-item {
            &:first-child {
                a {
                    border-radius: 10px 10px 0 0;
                }
            }

            &:last-child {
                a {
                    border-radius: 0 0 10px 10px;
                }
            }

            &:only-child {
                a {
                    border-radius: 10px;
                }
            }

            &:nth-child(even) {
                a {
                    background-color: rgba(lighten(saturate(mixin.$mainBlue, 40%), 40%), 0.1);

                    &:hover {
                        opacity: 1;
                        background-color: rgba(lighten(saturate(mixin.$mainBlue, 40%), 40%), 0.2);
                    }
                }

            }

            &:not(:last-child) {
                margin-bottom: 1px;
            }

            a {
                position: relative;
                width: 100%;
                @include mixin.flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 8px;
                padding: 24px;
                background-color: mixin.$transparentSubBlue;

                @include mixin.tab {
                    padding: 16px;
                }

                @include mixin.sp {
                    padding: 12px;
                }

                &:hover {
                    opacity: 1;
                    background-color: rgba(lighten(saturate(mixin.$mainBlue, 40%), 40%), 0.2);
                }

                .list-tag {
                    width: 56px;
                    height: 22px;

                    font-size: 1.4rem;
                    @include mixin.flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid mixin.$mainBlue;
                    border-radius: 3px;
                    margin: 2px 0 0;
                    padding: 0 0 0 2px;

                    @include mixin.tab {
                        height: 20px;
                        font-size: 1.2rem;
                    }

                    &.new {
                        border-color: mixin.$mainRed;
                        color: mixin.$mainRed;
                    }

                }

                .title {
                    @include mixin.flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 8px;
                    width: calc(100% - 32px);
                    overflow: hidden;

                    @include mixin.sp {
                        width: calc(100% - 28px);
                    }

                }

                &[href*=".pdf"] {
                    &::before {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 24px 24px;
                        background-position: center center;
                        width: 24px;
                        height: 24px;
                        display: inline-block;

                        @include mixin.sp {
                            background-size: 20px 20px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                .text-more {
                    position: absolute;
                    @include mixin.flex;
                    justify-content: flex-start;
                    gap: 8px;
                    right: 14px;
                    bottom: 5px;

                    font-size: 1.4rem;

                    cursor: pointer;

                    @include mixin.tabSm {
                        font-size: 1.2rem;
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-image: url(../images/icon/icon-allow01-blue.svg);
                        background-size: 20px 20px;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    .text-more {
                        &::after {
                            background-image: url(../images/icon/icon-external.svg);
                            background-size: 12px 12px;
                        }
                    }
                }
            }
        }
    }

    &-even02 {
        @include mixin.tab {
            line-height: 1.5;
        }

        .list-item {
            &:first-child {
                a {
                    border-radius: 10px 10px 0 0;
                }
            }

            &:last-child {
                a {
                    border-radius: 0 0 10px 10px;
                }
            }

            &:only-child {
                a {
                    border-radius: 10px;
                }
            }

            &:nth-child(even) {
                a {
                    background-color: rgba(lighten(saturate(mixin.$mainBlue, 40%), 40%), 0.1);

                    &:hover {
                        opacity: 1;
                        background-color: rgba(lighten(saturate(mixin.$mainBlue, 40%), 40%), 0.2);
                    }
                }

            }

            &:not(:last-child) {
                margin-bottom: 1px;
            }

            a {
                position: relative;
                width: 100%;
                @include mixin.flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 8px;
                padding: 10px 24px;
                background-color: mixin.$transparentSubBlue;

                @include mixin.tab {
                    padding: 10px 16px;
                }

                @include mixin.sp {
                    padding: 10px 12px;
                }

                &:hover {
                    opacity: 1;
                    background-color: rgba(lighten(saturate(mixin.$mainBlue, 40%), 40%), 0.2);
                }

                .list-tag {
                    width: 56px;
                    height: 22px;

                    font-size: 1.4rem;
                    @include mixin.flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid mixin.$mainBlue;
                    border-radius: 3px;
                    margin: 2px 0 0;
                    padding: 0 0 0 2px;

                    @include mixin.tab {
                        height: 20px;
                        font-size: 1.2rem;
                    }

                    &.new {
                        border-color: mixin.$mainRed;
                        color: mixin.$mainRed;
                    }

                }

                .title {
                    @include mixin.flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 8px;
                    width: calc(100% - 32px);
                    overflow: hidden;

                    @include mixin.sp {
                        width: calc(100% - 28px);
                    }

                }

                // &[href*=".pdf"] {
                //     .text-more {
                //         &::after {
                //             background-image: url(../images/icon/icon-pdf.svg);
                //         }
                //     }
                // }

                .text-more {
                    position: absolute;
                    @include mixin.flex;
                    justify-content: flex-start;
                    gap: 8px;
                    right: 14px;
                    bottom: 5px;

                    font-size: 1.4rem;

                    cursor: pointer;

                    @include mixin.tabSm {
                        font-size: 1.2rem;
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-image: url(../images/icon/icon-allow01-blue.svg);
                        background-size: 20px 20px;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    .text-more {
                        &::after {
                            background-image: url(../images/icon/icon-external.svg);
                            background-size: 12px 12px;
                        }
                    }
                }
            }
        }
    }
}

ul {
    &[class^="list-"] {
        text-align: left;
    }
}

:where(body).en {
    ul.list-note03 {
        .list-item {
            padding-left: 4em;
            &::after {
                content: 'Note'counter(noteNumberList03)'）';
            }

        }
    }

}

/*----------------------------------------------------
dList
------------------------------------------------------*/
dl.dlist {
    &-number {
        margin: 0;
        padding-left: 24px;
        position: relative;

        counter-reset: numberDlist01;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        dt {
            @include mixin.flex;
            justify-content: flex-start;
            font-size: 2rem;
            text-indent: -2.4rem;
            color: mixin.$mainBlue;
            margin-bottom: 4px;

            @include mixin.tab {
                font-size: 1.6rem;
            }

            &::before {
                content: counter(numberDlist01) '. ';
                counter-increment: numberDlist01;
                @include mixin.flex;
                justify-content: flex-start;
                width: 2.4rem;
            }
        }

        dd {
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-number02 {
        margin: 0;
        padding-left: 40px;
        position: relative;

        counter-reset: numberDlist02;

        &:not(:last-child) {
            margin-bottom: 40px;
        }


        >dt {
            @include mixin.flex;
            justify-content: flex-start;
            text-indent: -4rem;
            color: mixin.$mainBlue;
            margin-bottom: 4px;

            @include mixin.tab {
                font-size: 1.6rem;
            }

            &::before {
                content: "（"counter(numberDlist02) "）";
                counter-increment: numberDlist02;
                @include mixin.flex;
                width: 4rem;
            }
        }

        >dd {
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }

            >dl,
            ul {
                @include mixin.sp {
                    padding-left: 0;
                }
            }
        }
    }

    &-number03 {
        margin: 0;

        position: relative;

        counter-reset: numberDlist01;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        >dt {
            @include mixin.flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 1.6rem;
            font-weight: 700;
            padding-left: 24px;
            color: mixin.$mainBlue;
            position: relative;
            margin-bottom: 4px;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: counter(numberDlist01) '. ';
                counter-increment: numberDlist01;
                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 2.4rem;
            }
        }

        >dd {
            margin: 0;
            padding-left: 24px;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }

            >dl,
            ul {
                @include mixin.sp {
                    padding-left: 0;
                }
            }
        }
    }

    &-horizontal {
        width: 100%;
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: wrap;
        margin: 0;

        @include mixin.sp {
            align-items: flex-start;
        }

        &:not(:last-of-type) {
            margin-bottom: 20px;
            margin-left: 0;

            @include mixin.sp {
                margin-bottom: 10px;
            }
        }

        dt {
            font-weight: 700;
            margin: 0;
            width: 100px;

            @include mixin.sp {
                padding-top: 2px;
                width: 100%;
            }

        }

        dd {
            margin: 0;
            width: calc(100% - 108px);

            @include mixin.sp {
                width: 100%;
            }
        }

        >.dlist-item {
            width: 100%;
            @include mixin.flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: flex-start;
        }
    }

    &-common {
        margin: 0;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        >dt {
            font-weight: 700;
            margin-bottom: 4px;
        }

        >dd {
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            a {

                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                @include mixin.flex;
                justify-content: flex-start;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }

    &-large {
        margin: 0;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        >dt {
            font-weight: 700;
            margin-bottom: 16px;
            font-size: 2.2rem;

            @include mixin.pcXl {
                font-size: 2rem;
            }

            @include mixin.tab {
                font-size: 1.8rem;
            }

            @include mixin.sp {
                font-size: 1.6rem;
            }

            a:not([class*="button-"]) {
                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                width: fit-content;
                gap: 8px;
                font-weight: 700;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }

        >dd {
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            a:not([class*="button-"]) {
                color: mixin.$mainBlue;
                overflow-wrap: break-word;
                position: relative;
                width: fit-content;
                gap: 8px;

                &[href*=".pdf"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-pdf.svg) 0 0 no-repeat;
                        background-size: 13px 16px;
                        margin: 0 0 0 1em;
                        width: 13px;
                        height: 16px;
                        display: inline-block;
                    }
                }

                &[href*="https:"],
                &[href*="http:"] {
                    &::after {
                        content: '';
                        background: url(../images/icon/icon-external.svg) 0 0 no-repeat;
                        background-size: 12px;
                        margin: 0 0.5em;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                }

                .small {
                    font-size: 1.2rem;
                    color: mixin.$mainBlue;
                }
            }
        }
    }
}

/*----------------------------------------------------
Table
------------------------------------------------------*/
.table-common {
    width: 100%;
    border-collapse: separate;
    margin-bottom: 40px;

    @include mixin.tabXs {
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    tbody {
        width: 100%;
        border: 1px solid rgba(208, 208, 208, .4);
        box-shadow: 0 1px 1px mixin.$mainLightGray;
        border-radius: 10px;

        .thead {
            +tr {

                th,
                td {
                    border-top: none;
                }
            }
        }

        tr {
            width: 100%;

            th,
            td {
                border-top: 1px solid mixin.$mainLightGray;
            }

            &:first-child {

                th,
                td {
                    &:first-child {
                        border-radius: 10px 0 0 0;

                        @include mixin.tabXs {
                            border-radius: 10px 10px 0 0;
                        }
                    }

                    &:last-child {
                        border-radius: 0 10px 0 0;

                        @include mixin.tabXs {
                            border-radius: 0;
                        }
                    }
                }
            }

            &:last-child {

                th,
                td {
                    &:first-child {
                        border-radius: 0 0 0 10px;

                        @include mixin.tabXs {
                            border-radius: 0;
                        }
                    }

                    &:last-child {
                        border-radius: 0 0 10px 0;

                        @include mixin.tabXs {
                            border-radius: 0 0 10px 10px;
                        }
                    }
                }

            }

            &:only-child {
                th,
                td {
                    &:first-child {
                        border-radius: 10px 0 0 10px;

                        @include mixin.tabXs {
                            border-radius: 10px 10px 0 0;
                        }
                    }

                    &:last-child {
                        border-radius: 0 10px 10px 0;

                        @include mixin.tabXs {
                            border-radius: 0 0 10px 10px;
                        }
                    }
                }
            }
        }
    }

    .thead {
        @include mixin.tabXs {
            display: none;
        }
    }

    tr {
        >*:first-child {
            border-left: 1px solid mixin.$mainLightGray;
        }

        >*:last-child {
            border-right: 1px solid mixin.$mainLightGray;
        }

        &.table-th-two {
            th {
                vertical-align: top;

                &:first-child {
                    border-right: none;

                    @include mixin.tabXs {
                        border-right: 1px solid mixin.$mainLightGray;
                    }
                }

                &:last-of-type {
                    border-right: 1px solid mixin.$mainLightGray;
                    border-top: none;
                    border-left: none;

                    @include mixin.tabXs {
                        border-top: 1px solid mixin.$mainLightGray;
                        border-left: 1px solid mixin.$mainLightGray;
                    }
                }

                +th:last-of-type {
                    border-top: 1px solid mixin.$mainLightGray;

                    @include mixin.tabXs {
                        border-top: none;
                        padding: 0 12px 8px;
                    }
                }
            }
        }
    }

    th,
    td {
        padding: 8px 12px;
        text-align: center;
        height: auto;

        @include mixin.tabXs {
            width: 100% !important;
            @include mixin.flex;
            justify-content: flex-start;
            border-right: 1px solid mixin.$mainLightGray;
            border-left: 1px solid mixin.$mainLightGray;
        }

        &.vertical {
            box-sizing: 1.6rem;
            writing-mode: vertical-rl;

            @include mixin.tabXs {
                box-sizing: auto;
                writing-mode: horizontal-tb;
            }
        }

        >*:not([class*="flex"], br) {
            display: block;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    th {
        block-size: 1.6rem;
        background-color: rgba(218, 238, 246, .4);
        white-space: pre;
        height: auto;

        +th {
            border-top: 1px solid mixin.$mainLightGray;

            &:nth-of-type(2) {
                border-left: none;

                @include mixin.tabXs {
                    border-left: 1px solid mixin.$mainLightGray;
                }
            }
        }

        &:not(:first-child) {
            border-left: 1px solid mixin.$mainLightGray;
        }

        &:first-child {
            border-right: 1px solid mixin.$mainLightGray;
        }
    }

    td {
        background-color: rgba(255, 255, 255, 0.48);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        &.active {
            -webkit-backdrop-filter: none;
            backdrop-filter: none;
        }

        &:not(:first-of-type) {
            border-left: 1px solid mixin.$mainLightGray;
        }

        &::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            margin-right: 10px;
        }
    }

    caption {
        font-weight: 700;
        margin-bottom: 10px;
        text-align: left;
        position: relative;
        padding-left: 24px;

        &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 1px;
            border-radius: 2px;
            top: 12.5px;
            left: 0;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            background-color: mixin.$mainBlue;
        }
    }

    &.th-left {
        th {
            text-align: left;
        }
    }

    &.td-left {
        td {
            text-align: left;
        }
    }

    &.th-right {
        th {
            text-align: right;
        }
    }

    &.td-right {
        td {
            text-align: right;
        }
    }
}

/*----------------------------------------------------
Icon
------------------------------------------------------*/
:where(.pager, .result-list, .form-list) {
    .icon {
        &-requirement {
            font-size: 1.2rem;
            width: 32px;
            height: 20px;
            @include mixin.flex;
            justify-content: center;
            color: mixin.$mainWhite;
            background-color: mixin.$mainRed;
            border: 1px solid mixin.$mainRed;

            @include mixin.sp {
                font-size: 1rem;
                width: 30px;
                height: 15px;
            }
        }
    }
}

.icon {
    &-search {
        width: 16px;
        height: 16px;
        @include mixin.flex;
        justify-content: center;
        align-items: center;
        background: url(../images/icon/icon-search.svg) center top 55%/cover no-repeat;
        position: relative;

    }
}

/*----------------------------------------------------
input
------------------------------------------------------*/
.radio {
    &-common {
        @include mixin.flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0;

        &:not(:first-of-type) {
            margin-left: 40px;
        }

        &.head-itemumn {
            flex-direction: column;
            align-items: flex-start;

            @include mixin.sp {
                flex-direction: row;
            }

            li {
                &:not(:last-of-type) {
                    margin-right: 16px;
                }

                &:not(:first-of-type) {
                    margin-left: 0;
                }
            }
        }

        &.hidden-sp {
            @include mixin.flex;

            @include mixin.sp {
                display: none;
            }
        }

        li {
            margin-top: 2px;
            margin-bottom: 2px;

            &:not(:last-of-type) {
                margin-right: 16px;

                @include mixin.sp {
                    margin-right: 10px;
                }
            }

        }

        input[type="radio"].radio-input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 1px solid #bfbfbf;
            border-radius: 0;
            background-color: #fff;
            outline: none;
            vertical-align: bottom;

            display: none;

        }

        .radio-description {
            position: relative;
            padding-left: 20px;
            cursor: pointer;
            transition: all 0.2s;
            white-space: nowrap;
        }

        .radio-description::before {
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid mixin.$mainBlue;
            background-color: #fff;

            position: absolute;
            top: 50%;
            display: block;
            border-radius: 100%;
            transition: all 0.2s;
            transform: translateY(-50%);
            content: "";
        }

        .radio-description::after {
            left: 3px;
            width: 10px;
            height: 10px;
            background-color: mixin.$mainBlue;
            opacity: 0;

            position: absolute;
            top: 50%;
            display: block;
            border-radius: 100%;
            transition: all 0.2s;
            transform: translateY(-50%);
            content: "";
        }

        .radio-input:checked+.radio-description::after {
            opacity: 1;
        }
    }
}

.check {
    &-common {
        @include mixin.flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0;

        &:not(:first-of-type) {
            margin-left: 40px;
        }

        &.head-itemumn {
            &-one {
                flex-direction: column;
                align-items: flex-start;

                li {
                    width: 100%;

                    &:not(:first-of-type) {
                        margin-left: 0;
                    }

                    .check-description {
                        width: auto;
                    }
                }
            }

            &-two {
                li {
                    width: 50%;
                    margin-right: 0;

                    &:not(:first-of-type) {
                        margin-left: 0;
                    }

                    .check-description {
                        width: auto;
                    }
                }
            }

            &-four {
                li {
                    width: 25%;
                    margin-right: 0;

                    &:not(:first-of-type) {
                        margin-left: 0;
                    }

                    .check-description {
                        width: auto;
                        font-size: 1.75rem;
                    }
                }
            }
        }

        &.hidden-sp {
            @include mixin.flex;

            @include mixin.sp {
                display: none;
            }
        }

        dt {
            font-weight: 700;
            margin-right: 20px;

            @include mixin.sp {
                margin-right: 40px;
            }

        }

        dd {
            margin: 0 20px 0 0;
        }

        li {
            margin-top: 2px;
            margin-bottom: 2px;
            margin-right: 10px;
        }

        input[type="checkbox"].check-input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 1px solid #bfbfbf;
            border-radius: 0;
            background-color: #fff;
            outline: none;
            vertical-align: bottom;

            display: none;
        }

        .check-description {
            position: relative;
            padding-left: 24px;
            cursor: pointer;
            transition: all 0.2s;
            width: 100%;
            display: inline-block;
            font-size: 1.8rem;
            letter-spacing: 0.01em;
            @include mixin.flex;
            justify-content: flex-start;

            @include mixin.sp {
                font-size: 1.4rem;
            }
        }

        .check-description::before {
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid mixin.$mainBlue;
            background-color: mixin.$mainWhite;

            position: absolute;
            top: 50%;
            display: block;
            border-radius: 2px;
            transition: all 0.2s;
            transform: translateY(-50%);
            content: "";
        }

        .check-description::after {
            left: 3.75px;
            width: 10px;
            height: 10px;
            background: url(../images/icon/icon-check.svg) no-repeat 0 0/10px auto;
            opacity: 0;
            position: absolute;
            top: 55%;
            display: block;
            transition: all 0.2s;
            transform: translateY(-50%);
            content: "";
        }

        .check-input:checked+.check-description::before {
            background-color: mixin.$mainBlue;
        }

        .check-input:checked+.check-description::after {
            opacity: 1;
        }
    }

    &-small {
        input[type="checkbox"].check-input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 1px solid #bfbfbf;
            border-radius: 0;
            background-color: #fff;
            outline: none;
            vertical-align: bottom;

            display: none;
        }

        .check-description {
            position: relative;
            padding-left: 24px;
            cursor: pointer;
            transition: all 0.2s;
            width: auto;
            display: inline-block;
            font-size: 1.4rem;
            letter-spacing: 0.01em;

            @include mixin.sp {
                font-size: 1.4rem;
            }
        }

        .check-description::before {
            left: 0;
            width: 14px;
            height: 14px;
            border: 1px solid mixin.$mainBlue;
            background-color: mixin.$mainWhite;

            position: absolute;
            top: 50%;
            display: block;
            border-radius: 2px;
            transition: all 0.2s;
            transform: translateY(-50%);
            content: "";
        }

        .check-description::after {
            left: 3.75px;
            width: 8px;
            height: 8px;
            background: url(../images/common/icon/icon_check.svg) no-repeat 0 0/8px auto;
            opacity: 0;
            position: absolute;
            top: 50%;
            display: block;
            transition: all 0.2s;
            transform: translateY(-50%);
            content: "";
        }

        .check-input:checked+.check-description::before {
            background-color: mixin.$mainBlue;
        }

        .check-input:checked+.check-description::after {
            opacity: 1;
        }
    }

}

.pulldown {
    &-common {
        position: relative;
        width: 140px;
        max-width: 100%;
        height: 40px;


        @include mixin.sp {
            width: 100%;
            max-width: 100%;
            height: 36px;
        }

        select {
            width: 140px;
            max-width: 100%;
            height: 40px;
            padding: 0 36px 0 8px;
            font-size: 1.8rem;
            letter-spacing: 0.01em;
            text-align: center;
            // font-weight: 700;

            @include mixin.sp {
                width: 100%;
                max-width: 100%;
                height: 36px;
                font-size: 1.4rem;
                padding-left: 5px;

            }
        }

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 40px;
            height: 40px;
            border-radius: 0 6px 6px 0;
            background-color: mixin.$mainBlue;
            pointer-events: none;

            @include mixin.sp {
                width: 36px;
                height: 36px;
            }
        }

        &::after {
            content: "▼";
            font-size: 10px;
            color: mixin.$mainWhite;
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    &-middle {
        position: relative;
        width: 280px;
        max-width: 100%;
        height: 40px;


        @include mixin.sp {
            width: 100%;
            max-width: 100%;
            height: 36px;
        }

        select {
            width: 280px;
            max-width: 100%;
            height: 40px;
            padding: 0 36px 0 8px;
            font-size: 1.8rem;
            letter-spacing: 0.01em;
            text-align: left;
            // font-weight: 700;

            @include mixin.sp {
                width: 100%;
                max-width: 100%;
                height: 36px;
                font-size: 1.4rem;
                padding-left: 5px;

            }
        }

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 40px;
            height: 40px;
            border-radius: 0 6px 6px 0;
            background-color: mixin.$mainBlue;
            pointer-events: none;

            @include mixin.sp {
                width: 36px;
                height: 36px;
            }
        }

        &::after {
            content: "▼";
            font-size: 10px;
            color: mixin.$mainWhite;
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    &-large {
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 40px;


        @include mixin.sp {
            width: 100%;
            max-width: 100%;
            height: 36px;
        }

        select {
            width: 100%;
            max-width: 100%;
            height: 40px;
            padding: 0 36px 0 8px;
            font-size: 1.8rem;
            letter-spacing: 0.01em;

            @include mixin.sp {
                width: 100%;
                max-width: 100%;
                height: 36px;
                font-size: 1.4rem;
                padding-left: 5px;

            }
        }

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 40px;
            height: 40px;
            border-radius: 0 6px 6px 0;
            background-color: mixin.$mainBlue;
            pointer-events: none;

            @include mixin.sp {
                width: 36px;
                height: 36px;
            }
        }

        &::after {
            content: "▼";
            font-size: 10px;
            color: mixin.$mainWhite;
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }
}

.text-input {
    &-common {
        width: 100%;
        max-width: 100%;
        height: 40px;

        @include mixin.sp {
            height: 36px;
        }

        +button.button-or,
        +a.button-or {
            &.plus {
                font-size: 1.4rem;
                padding-left: 1px;
                font-weight: 700;
            }
        }
    }

    &-middle {
        width: 280px;
        max-width: 100%;
        height: 40px;

        @include mixin.sp {
            width: 100%;
            height: 36px;
        }
    }

    &-semimiddle {
        width: 200px;
        max-width: 100%;
        height: 40px;

        @include mixin.sp {
            width: 100%;
            height: 36px;
        }
    }

    &-small {
        width: 80px;
        max-width: 100%;
        height: 40px;

        @include mixin.sp {
            height: 36px;
        }
    }

    &-xsmall {
        width: 60px;
        max-width: 100%;
        height: 40px;

        @include mixin.sp {
            height: 36px;
        }
    }
}

.textarea-input {
    &-common {
        width: 100%;
    }
}

// 日付用
.date-block {
    @include mixin.flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 0;

    @include mixin.sp {
        width: 100%;
    }

    .date-area {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-end;

        &:not(:first-of-type) {
            margin-left: 36px;

            @include mixin.sp {
                margin-left: 0;
                margin-top: 10px;
            }

        }

        &:nth-of-type(2) {
            span {
                @include mixin.sp {
                    margin-left: auto;
                    margin-right: auto;
                    display: none;
                }
            }
        }

        &:last-of-type {
            .u-sp {
                display: none;

                @include mixin.sp {
                    @include mixin.flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 36px;
                    margin: 0 10px;
                }
            }

        }

        span {
            display: block;
            margin-left: 8px;
        }

        input {
            &:not(:first-of-type) {
                margin-left: 20px;

                @include mixin.sp {
                    margin-left: 10px;
                }
            }

        }
    }
}


.text-area {
    @include mixin.flex;
    position: relative;
    // margin-right: 8px;

    @include mixin.pcXl {
        margin-right: 0;
    }

    input.text-input-search {
        font-size: 1.8rem;
        max-width: 100%;
        width: 100%;
        height: 60px;
        padding-right: 36px;
        box-shadow: inset 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);

        &::placeholder {
            color: mixin.$mainGreen;
            opacity: 1;
        }

        &:focus-visible {
            outline: none;
            border: 2px solid mixin.$mainGreen;
        }
    }
}

// マーズファインダー
// 検索用
.mf-search-box,
.mf_finder_searchBox {
    margin: 0;

    .mf-search-bar,
    .mf_finder_searchBox_items {
        @include mixin.flex;
        position: relative;
        padding: 0;
        border-radius: 6px;

        @include mixin.pcXl {
            margin-right: 0;
        }

        .mf-search-bar_input,
        input.mf_finder_searchBox_query_input {
            border-radius: 6px !important;
            border: 1px solid mixin.$mainLightGray !important;
            background-color: mixin.$mainWhite !important;
            font-size: 1.8rem;
            max-width: 100%;
            width: 100%;
            height: 60px;
            min-height: auto;
            padding: 5px 36px 6px 10px;
            box-shadow: inset 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);
            color: mixin.$mainGreen;

            &::placeholder {
                color: mixin.$mainGreen;
                opacity: 1;
            }

            &:focus-visible {
                outline: none;
                border: 2px solid mixin.$mainGreen;
            }
        }
        .mf-search-bar_input_alt-btn,
        .mf_finder_searchBox_submit {
            position: absolute;
            @include mixin.flex;
            justify-content: center;
            margin: auto 0;
            padding: 5px 8px 6px;
            width: 40px;
            height: auto;
            top: 0;
            bottom: 0;
            right: 0;
            border: none;
            background-color: transparent;
            cursor: pointer;

            &::before {
                display: block;
                content: "";
                width: 16px;
                height: 16px;
                background: url(../images/icon/icon-search.svg) center/cover no-repeat;
            }

            .mf-search-bar_input_alt-btn_icon {
                display: none;
            }
        }
    }
}

// TOPぺージ上書き用
.top-main .mf-search-box .mf-search-bar .mf-search-bar_input {
    border-radius: 6px !important;
    border: 1px solid mixin.$mainLightGray !important;
    background-color: mixin.$mainWhite !important;
    font-size: 1.8rem;
    max-width: 100%;
    width: 100%;
    height: 60px;
    min-height: auto;
    padding: 5px 36px 6px 10px;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .16);
    color: mixin.$mainGreen;

    &::placeholder {
        color: mixin.$mainGreen;
        opacity: 1;
    }

    &:focus-visible {
        outline: none;
        border: 2px solid mixin.$mainGreen;
    }
}

/*----------------------------------------------------
card
------------------------------------------------------*/
.card {
    &-horizontal {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            background-color: mixin.$mainLightGray;

        }

        .img {
            overflow: hidden;
            width: 108px;
            height: 76px;

            margin: 0;
            padding: 0;

            @include mixin.sp {
                width: 80px;
                height: 80px;
            }

            img {
                width: 100%;
                height: auto;

                @include mixin.sp {
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                }
            }

        }

        p.title {
            width: calc(100% - 108px);
            @include mixin.flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0 40px;

            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;

            /* 行数 */
            @include mixin.sp {
                width: calc(100% - 80px);
                padding: 0 10px;
            }
        }
    }

    &-horizontal02 {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: center;
        background-color: mixin.$mainWhite;
        border: 1px solid mixin.$mainLightGray;
        width: 100%;
        height: 80px;
        border-radius: 4px;

        &:hover {
            -webkit-box-shadow: 0 0 6px 0 rgba(110, 110, 110, 0.13);
            box-shadow: 0 0 6px 0 rgba(110, 110, 110, 0.13);
            background-color: mixin.$mainLightGray;
        }

        .img {
            overflow: hidden;
            width: 200px;
            height: 100%;
            margin: 0;
            padding: 0;

            @include mixin.sp {
                width: 80px;
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                aspect-ratio: 5/2;

                @include mixin.sp {
                    aspect-ratio: 1/1;
                }
            }

        }

        p.title {
            padding: 0 24px;
            margin-bottom: 0;
        }
    }

    &-horizontal03 {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            .img {
                img {
                    transform: scale(1.1);
                }
            }
        }

        .img {
            overflow: hidden;
            width: 100px;
            height: 100px;
            border-radius: 6px;
            margin: 0 20px 0 0;
            padding: 0;

            img {
                width: 100%;
                height: auto;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                transition: transform .5s cubic-bezier(.57, .15, .13, .97);
            }

        }

        .description {
            width: calc(100% - 120px);
            padding: 0;

            time {
                font-weight: 500;
            }

            p.title {
                margin: 0 0 8px;
            }
        }

        .list-tag {
            color: mixin.$mainWhite;
            background-color: mixin.$mainBlue;
            font-size: 1.4rem;
            padding: 2px 4px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

    }

    &-horizontal04 {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 60px;
        border: 1px solid mixin.$mainLightGray;

        overflow: hidden;
        border-radius: 10px;

        @include mixin.tab {
            height: 100%;
        }

        .img {
            overflow: hidden;
            width: 60px;
            height: 100%;
            margin: 0;
            padding: 0;

            @include mixin.flex;
            justify-content: center;

            @include mixin.tab {
                width: 30%;
            }

            img {
                width: 32px;
                height: auto;
            }

        }

        p.title {
            padding: 0 20px;
            margin-bottom: 0;

            @include mixin.tab {
                padding: 10px;
            }
        }
    }

    &-horizontal05 {
        position: relative;
        padding: 4px 10px 4px 34px;
        border: 1px solid mixin.$mainLightGray;
        border-radius: 4px;
        width: 100%;
        height: fit-content;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &::after {
            position: absolute;
            content: '';
            top: 14px;
            left: 10px;
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 2px;
            background-color: mixin.$mainBlue;

            @include mixin.tab {
                top: 12px;
            }

            @include mixin.sp {
                top: 8px;
            }

        }

        &:hover {
            background-color: mixin.$mainLightGray;

        }

    }

    &-vertical {
        @include mixin.flex;
        align-items: flex-start;
        flex-direction: column;
        border: 2px solid mixin.$mainBlue;
        background-color: mixin.$mainWhite;
        border-radius: 6px;
        height: 400px;
        font-family: mixin.$font-family-serif01;

        @include mixin.pc {
            height: 280px;
        }

        @include mixin.tab {
            height: auto;
            min-height: 100%;
        }

        &:hover {
            .img {
                img {
                    transform: scale(1.1);
                }
            }

            .more-text {
                color: mixin.$mainBlue;
                background-color: mixin.$mainWhite;
            }
        }

        .img {
            overflow: hidden;
            width: 100%;
            height: 190px;
            margin: 0;
            padding: 0;

            @include mixin.pc {
                height: auto;
                aspect-ratio: 3/2;
            }

            img {
                width: 100%;
                height: 190px;
                object-fit: cover;
                transition: transform .5s cubic-bezier(.57, .15, .13, .97);

                @include mixin.pc {
                    height: auto;
                    aspect-ratio: 3/2;
                }
            }

        }

        p.title {
            padding: 36px 24px;
            margin-bottom: auto;
            width: 100%;
            font-size: 1.8rem;

            @include mixin.pc {
                padding: 20px 10px;
                font-size: 1.6rem;
                margin-bottom: 0;
            }

        }

        .more-text {
            @include mixin.flex;
            justify-content: center;

            width: 100%;
            height: 60px;
            color: mixin.$mainWhite;
            background-color: mixin.$mainBlue;
            border-top: 2px solid mixin.$mainBlue;
            border-radius: 0 0 4px 4px;

            @include mixin.pc {
                height: 40px;
            }

        }
    }

    &-vertical02 {
        @include mixin.flex;
        align-items: flex-start;
        flex-direction: column;

        &:hover {
            .img {
                img {
                    transform: scale(1.1);
                }
            }
        }

        .img {
            overflow: hidden;
            width: 100%;
            height: auto;
            aspect-ratio: 7/5;
            border-radius: 6px;
            margin: 0 0 18px;
            padding: 0;

            @include mixin.tab {
                height: auto;
                aspect-ratio: 3/2;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform .5s cubic-bezier(.57, .15, .13, .97);

            }

        }

        p.title {
            width: 100%;
            margin-bottom: 0;
        }
    }

    &-vertical03 {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-shadow: 6px 6px 6px mixin.$mainBlue;
        background-color: mixin.$mainWhite;
        border-radius: 6px;
        height: auto;
        min-height: 100%;
        padding: 40px 20px;
        font-family: mixin.$font-family-serif01;
        transition: transform .5s cubic-bezier(.57, .15, .13, .97);

        &:hover {
            transform: scale(1.025);
        }

        @include mixin.tab {
            padding: 20px;
        }

        @include mixin.sp {
            padding: 10px 10px 20px;
        }


        .img {
            width: 100%;
            max-width: 140px;
            margin: 0 auto 40px;
            padding: 0;
            @include mixin.flex;
            align-items: flex-start;
            flex-direction: column;

            @include mixin.tab {
                margin-bottom: 10px;
            }

            img {
                width: 100%;
                height: auto;
                margin: auto;

                @include mixin.sp {
                    width: 60%;
                }
            }

        }

        .title {
            padding: 0;
            margin-bottom: 16px;
            width: 100%;
            font-size: 3rem;
            color: mixin.$mainBlue;
            text-align: center;
            line-height: 1.5;

            @include mixin.pc {
                font-size: 2.2rem;
            }

            @include mixin.sp {
                margin-bottom: 4px;
            }
        }

        .description {
            @include mixin.flex;
            justify-content: center;
            text-align: left;
            width: 100%;
            margin-bottom: 0;
        }
    }

    &-vertical04 {
        position: relative;
        width: 100%;
        height: 100%;

        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }

        }

        .title {
            @include mixin.flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            position: absolute;
            bottom: 60px;
            left: 20px;
            right: 0;

            @include mixin.tab {
                left: 2vw;

                bottom: 40px;
            }

            @include mixin.sp {


                bottom: 20px;
            }

            >* {
                display: inline-block;
                color: #fff;
                width: auto;
                font-size: 2.2rem;
                margin: 0;

                padding: 0 6px;
                background: -webkit-linear-gradient(315deg, rgb(91, 91, 91) 0%, rgb(48, 48, 48) 100%);
                background: linear-gradient(135deg, rgb(91, 91, 91) 0%, rgb(48, 48, 48) 100%);

                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }

            .small {
                font-size: 1.6rem;
                margin-bottom: 10px;
            }
        }
    }

    &-vertical05 {
        @include mixin.flex;
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        min-height: 100%;

        &:hover {
            .img {
                img {
                    transform: scale(1.1);
                }
            }

            p.title {
                &::after {
                    right: 8px;
                }
            }
        }

        .img {
            width: 100%;
            height: 80px;
            margin: 0 auto 20px;
            padding: 0;
            @include mixin.flex;
            align-items: flex-start;
            flex-direction: column;

            @include mixin.tab {
                margin-bottom: 10px;
            }

            img {
                width: 100%;
                height: 80px;
                margin: auto;
                object-fit: contain;
                transition: transform .5s cubic-bezier(.57, .15, .13, .97);

                @include mixin.sp {
                    width: 60%;
                }
            }

        }

        p.title {
            width: 100%;
            text-align: center;
            color: mixin.$mainBlue;
            padding: 10px;
            border-bottom: 1px solid mixin.$mainBlue;
            position: relative;
            font-size: 1.7rem;

            &::after {
                content: '→';
                position: absolute;
                right: 16px;
                margin: auto 0;
                transition: transform .5s cubic-bezier(.57, .15, .13, .97);

            }

            @include mixin.tab {
                font-size: 1.4rem;
            }
        }
    }

    &-vertical06 {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        background-color: mixin.$mainWhite;
        color: mixin.$mainBlue;
        border: 2px solid mixin.$mainBlue;
        position: relative;

        border-radius: 6px;
        height: 200px;
        min-height: 100%;
        padding: 20px;
        font-family: mixin.$font-family-serif01;


        @include mixin.tab {
            height: auto;
        }

        @include mixin.sp {
            padding: 10px 0;
        }

        &:hover {
            background-color: mixin.$mainBlue;

            .description {
                opacity: 1;
                visibility: visible;
                z-index: 1;
            }
        }

        .img {
            width: 100%;
            height: 80px;
            margin: 0 auto auto;
            padding: 0;
            @include mixin.flex;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 100%;
                height: 80px;
                margin: auto;
                object-fit: contain;
                transition: transform .5s cubic-bezier(.57, .15, .13, .97);

                @include mixin.sp {
                    width: 60%;
                }
            }

        }

        p.title {
            width: 100%;
            text-align: center;
            color: mixin.$mainBlue;
            padding: 10px;
            font-weight: 700;
            position: relative;
            font-size: 1.7rem;
            line-height: 1.5;

            @include mixin.tab {
                font-size: 1.4rem;
                margin: auto;
            }
        }

        .description {
            @include mixin.flex;
            justify-content: center;
            flex-direction: column;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            background-color: rgba(60, 41, 99, 0.95);

            transition: transform .5s cubic-bezier(.57, .15, .13, .97);
            visibility: hidden;
            z-index: -1;
            color: mixin.$mainWhite;


            @media (max-width: 991px) {
                font-size: 1.4rem;
            }

            p {
                width: 80%;
                margin: auto;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &.button-transparent {
                    &:not(:first-child) {
                        margin: 0 auto auto;
                    }
                }
            }

        }

    }

    &-vertical07 {
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        background-color: mixin.$mainWhite;
        color: mixin.$mainBlue;
        border: 2px solid mixin.$mainBlue;
        position: relative;

        border-radius: 6px;
        height: 200px;
        min-height: 100%;
        padding: 20px 0;
        font-family: mixin.$font-family-serif01;
        box-shadow: 3px 3px 3px mixin.$mainLightGray;

        transition: transform .5s cubic-bezier(.57, .15, .13, .97);

        @include mixin.tab {
            height: auto;
        }

        @include mixin.sp {
            padding: 10px 0;
        }

        &:hover {
            transform: scale(1.025);

        }

        .img {
            width: 100%;
            height: 80px;
            margin: auto;
            padding: 0 20px;
            @include mixin.flex;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 100%;
                height: 80px;
                margin: auto;
                object-fit: contain;
                transition: transform .5s cubic-bezier(.57, .15, .13, .97);

                @include mixin.sp {
                    width: 60%;
                }
            }

        }

        p.title {
            width: 100%;
            text-align: center;
            color: mixin.$mainBlue;
            padding: 10px;
            font-weight: 700;
            position: relative;
            line-height: 1.5;

            @include mixin.tab {
                margin: auto;
            }

            @include mixin.sp {
                padding: 10px 0;
            }

            span {
                font-size: 1.4rem;

                @include mixin.sp {
                    font-size: 1.2rem;
                }
            }
        }

    }


}

ul[class^="card-"] {
    line-height: 1.5;
}