@use "mixin";

/*----------------------------------------------------
Container
------------------------------------------------------*/
.l-container {
    @include mixin.articleBody;
}

.l-container-lg {
    @include mixin.articleBodyLg;
}

.l-container-fluid {
    @include mixin.articleBodyFluid;
}

/*----------------------------------------------------
contents-wrap (page)
------------------------------------------------------*/
.contents-wrap {
    padding: 94px 0 240px;
    position: relative;
    z-index: 1;

    @include mixin.pc {
        padding: 56px 0 200px;
    }

    @include mixin.pcSm {
        padding: 52px 0 160px;
    }

    @include mixin.tab {
        padding: 52px 0 120px;
    }

}

/*----------------------------------------------------
top-contents-wrap (top)
------------------------------------------------------*/
.top-contents-wrap {
    padding: 0 0 240px;
    position: relative;
    overflow: hidden;

    @include mixin.pc {
        padding: 0 0 200px;
    }

    @include mixin.pcSm {
        padding: 0 0 160px;
    }

    @include mixin.tab {
        padding: 0 0 120px;
    }
}

/*----------------------------------------------------
Grid Area
------------------------------------------------------*/
.grid {
    &-area {
        &-column {
            &-one {
                display: grid;
                grid-template-columns: 1fr;
                gap: 40px;

                @include mixin.tab {
                    margin-bottom: 20px;
                    gap: 10px 20px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }


            }

            &-two {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                justify-content: space-between;

                @include mixin.tab {
                    grid-template-columns: 1fr;
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }


            }

            &-two02 {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 40px;
                justify-content: space-between;

                @include mixin.tab {
                    margin-bottom: 20px;
                    gap: 20px;
                }

                @include mixin.sp {
                    grid-template-columns: 1fr;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            &-two03 {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 60px;
                justify-content: space-between;

                @include mixin.pc {
                    gap: 40px;
                }

                @include mixin.tab {
                    gap: 20px;
                }

                @include mixin.sp {
                    gap: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

            }

            &-three {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 40px;
                justify-content: space-between;

                @include mixin.tab {
                    margin-bottom: 20px;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                }

                @include mixin.sp {
                    grid-template-columns: 1fr;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            &-three02 {
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(3, 1fr);

                @include mixin.pcSm {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include mixin.tabXs {
                    grid-template-columns: 1fr;

                }

            }

            &-three03 {
                display: grid;
                gap: 20px;
                grid-template-columns: repeat(3, 1fr);

                @include mixin.pcSm {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include mixin.tabSm {
                    gap: 10px;
                }

                @include mixin.tabXs {
                    grid-template-columns: 1fr;

                }

            }

            &-four {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px 13px;
                justify-content: space-between;

                @include mixin.tab {
                    margin-bottom: 20px;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                }

                @include mixin.sp {
                    gap: 10px;
                    grid-template-columns: 1fr;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            &-four02 {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 10px 13px;
                justify-content: space-between;

                @include mixin.tab {
                    margin-bottom: 20px;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 10px;
                }

                @include mixin.tabXs {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include mixin.sp {
                    // gap: 10px;
                    grid-template-columns: 1fr;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            &-five {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 60px 13px;
                justify-content: space-between;

                @include mixin.tab {
                    margin-bottom: 20px;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px 20px;
                }

                @include mixin.sp {
                    grid-template-columns: 1fr;
                    gap: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }


            }

            &-five02 {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 60px 13px;
                justify-content: space-between;

                @include mixin.tab {
                    margin-bottom: 20px;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px 20px;
                }

                @include mixin.sp {
                    gap: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }


            }
        }

        &-pickup {
            display: grid;
            grid-template-columns: 2fr repeat(4, 1fr);
            gap: 30px;

            @include mixin.pc {
                grid-template-columns: repeat(4, 1fr);
                gap: 20px;
            }

            @include mixin.tab {
                grid-template-columns: repeat(3, 1fr);
            }

            @include mixin.sp {
                grid-template-columns: repeat(2, 1fr);
            }

            >* {
                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 1;
                    grid-row-end: 3;

                    @include mixin.pc {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }

                    @include mixin.tab {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }

                }

            }

            a {
                text-decoration: none;
            }

            .card-vertical04 {
                position: relative;

                @include mixin.sp {
                    aspect-ratio: 3/2;
                }

                .title {
                    @include mixin.pc {
                        bottom: 40px;
                    }

                    @include mixin.sp {
                        bottom: 20px;
                    }

                    >* {
                        @media (max-width: 1500px) {
                            font-size: 1.8rem;
                        }

                        @media (max-width: 520px) {
                            font-size: 1.6rem;
                        }
                    }

                    .small {
                        @media (max-width: 1500px) {
                            font-size: 1.4rem;
                        }

                        @media (max-width: 520px) {
                            font-size: 1.2rem;
                        }
                    }
                }

                .pickup-badge {
                    position: absolute;
                    top: 30px;
                    left: 0;

                    @include mixin.flex;
                    justify-content: center;
                    width: auto;
                    height: 40px;
                    padding: 0 24px;
                    background-color: mixin.$mainBlue;
                    z-index: 1;
                    color: mixin.$mainWhite;
                    font-weight: 700;
                    font-size: 2.4rem;
                    font-family: mixin.$font-family-eng;

                    @media (max-width: 520px) {
                        top: 20px;

                        height: 28px;
                        font-size: 1.8rem;
                    }
                }
            }

            .card-vertical02 {
                .img {
                    @media (max-width: 1125px) {
                        aspect-ratio: 3/2;
                    }
                }
            }
        }
    }

}

*[class^="grid-area-column-"] {

    >*:not(.button-pdf):not(.button-search):not(.button-movie):not(.button-search-wrap):not(.button-movie-wrap),
    >.list-item {
        >*:not(:last-child) {
            margin-bottom: 10px;
        }

        &.area-two {
            grid-area: 1 / 2 / 2 / 4;

            @include mixin.tab {
                grid-area: 2 / 1 / 3 / 3;
            }

            @include mixin.sp {
                grid-area: auto;
            }
        }
    }

}

/*----------------------------------------------------
Flex Area
------------------------------------------------------*/
.flex {
    &-area {
        &-column {
            &-one {
                @include mixin.flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 40px;

                @include mixin.tab {
                    gap: 20px;
                }

                >* {
                    width: 100%;

                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }


            }

            &-two {
                @include mixin.flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 10px 20px;

                >* {
                    width: calc(50% - 10px);

                    @include mixin.tab {
                        width: 100%;
                    }

                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }



            }

            &-two02 {
                @include mixin.flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 40px;

                @include mixin.tab {
                    gap: 20px;
                }


                >* {
                    width: calc(50% - 20px);

                    @include mixin.tab {
                        width: calc(50% - 10px);
                    }

                    @include mixin.sp {
                        width: 100%;
                    }

                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }



            }

            &-three {
                @include mixin.flex;
                align-items: stretch;
                justify-content: center;
                flex-wrap: wrap;
                gap: 30px;

                @include mixin.tab {
                    justify-content: space-between;
                    gap: 20px;
                }

                >* {
                    width: calc(33.3333% - 20px);


                    @include mixin.tab {
                        width: calc(50% - 10px);

                    }

                    @include mixin.sp {
                        width: 100%;
                    }

                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            &-four {
                @include mixin.flex;
                align-items: stretch;
                justify-content: center;
                flex-wrap: wrap;
                gap: 30px 13px;

                @include mixin.tab {
                    justify-content: space-between;
                    gap: 20px;
                }

                @include mixin.sp {
                    gap: 10px;
                }

                >* {
                    width: calc(25% - 10px);

                    @include mixin.tab {
                        width: calc(50% - 10px);
                    }

                    @include mixin.sp {
                        width: 100%;
                    }

                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }


            }

            &-five {
                @include mixin.flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 60px 10px;

                @include mixin.tab {

                    gap: 30px 20px;
                }

                @include mixin.sp {
                    gap: 10px;
                }

                >* {
                    width: calc(20% - 10px);

                    @include mixin.tab {
                        width: calc(50% - 10px);
                    }

                    @include mixin.sp {
                        width: 100%;
                    }

                }

                &.l-container-lg {
                    @include mixin.tab {
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }


            }
        }

    }
}

*[class^="flex-area-column-"] {

    >*:not(.button-pdf):not(.button-search):not(.button-movie):not(.button-search-wrap):not(.button-movie-wrap),
    >.list-item {
        >*:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}