@use "mixin";

/*----------------------------------------------------
Flow
------------------------------------------------------*/
.u-flex {
    @include mixin.flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    @include mixin.sp {
        gap: 10px;
    }

    &-1 {
        width: 8.333%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-2 {
        width: 16.666%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-3 {
        width: 25%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-4 {
        width: 33.333%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-5 {
        width: 41.666%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-6 {
        width: 50%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-7 {
        width: 58.333%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-8 {
        width: 66.666%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-9 {
        width: 75%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-10 {
        width: 83.333%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-11 {
        width: 91.666%;

        @include mixin.tab {
            width: 100%;
        }
    }

    &-12 {
        width: 100%;

        @include mixin.tab {
            width: 100%;
        }
    }
}

/*----------------------------------------------------
Text color
------------------------------------------------------*/
.text {
    &-red {
        color: mixin.$mainRed;
    }
}

/*----------------------------------------------------
Text align
------------------------------------------------------*/
.text {
    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }
}

/*----------------------------------------------------
vertical align
------------------------------------------------------*/
.u-vertical {
    &-super {
        vertical-align: super;
        font-size: 1.2rem;
        @include mixin.tab {
            font-size: 1rem;
        }
    }

    &-sub {
        vertical-align: sub;
        font-size: 1.2rem;
        @include mixin.tab {
            font-size: 1rem;
        }
    }
}

/*----------------------------------------------------
Utility
------------------------------------------------------*/
/* 幅（%） */
.u-w010par {
    width: 10% !important;
}

.u-w015par {
    width: 15% !important;
}

.u-w020par {
    width: 20% !important;
}

.u-w025par {
    width: 25% !important;
}

.u-w030par {
    width: 30% !important;
}

.u-w035par {
    width: 35% !important;
}

.u-w040par {
    width: 40% !important;
}

.u-w045par {
    width: 45% !important;
}

.u-w050par {
    width: 50% !important;
}

.u-w055par {
    width: 55% !important;
}

.u-w060par {
    width: 60% !important;
}

.u-w065par {
    width: 65% !important;
}

.u-w070par {
    width: 70% !important;
}

.u-w075par {
    width: 75% !important;
}

.u-w080par {
    width: 80% !important;
}

.u-w085par {
    width: 85% !important;
}

.u-w090par {
    width: 90% !important;
}

.u-w095par {
    width: 95% !important;
}

.u-w100par {
    width: 100% !important;
}

/* 幅（px） */
.u-w010 {
    width: 10px !important;
}

.u-w020 {
    width: 20px !important;
}

.u-w030 {
    width: 30px !important;
}

.u-w040 {
    width: 40px !important;
}

.u-w050 {
    width: 50px !important;
}

.u-w060 {
    width: 60px !important;
}

.u-w070 {
    width: 70px !important;
}

.u-w080 {
    width: 80px !important;
}

.u-w090 {
    width: 90px !important;
}

.u-w100 {
    width: 100px !important;
}

.u-w150 {
    width: 150px !important;
}

.u-w200 {
    width: 200px !important;
}

.u-w250 {
    width: 250px !important;
}

.u-w300 {
    width: 300px !important;
}

.u-w350 {
    width: 350px !important;
}

.u-w400 {
    width: 400px !important;
}

.u-w450 {
    width: 450px !important;
}

.u-w500 {
    width: 500px !important;
}

.u-w550 {
    width: 550px !important;
}

.u-w600 {
    width: 600px !important;
}

.u-w650 {
    width: 650px !important;
}

.u-w700 {
    width: 700px !important;
}

.u-w750 {
    width: 750px !important;
}

.u-w800 {
    width: 800px !important;
}

.u-w850 {
    width: 850px !important;
}

.u-w900 {
    width: 900px !important;
}

.u-w950 {
    width: 950px !important;
}

.u-w1000 {
    width: 1000px !important;
}

.u-mw60 {
    min-width: 60px !important;
}

.u-mw100 {
    min-width: 100px !important;
}

/* margin-top */
.u-mt00 {
    margin-top: 0px !important;
}

.u-mt05 {
    margin-top: 5px !important;
}

.u-mt10 {
    margin-top: 10px !important;
}

.u-mt15 {
    margin-top: 15px !important;
}

.u-mt20 {
    margin-top: 20px !important;
}

.u-mt25 {
    margin-top: 25px !important;
}

.u-mt30 {
    margin-top: 30px !important;
}

.u-mt35 {
    margin-top: 35px !important;
}

.u-mt40 {
    margin-top: 40px !important;
}

.u-mt45 {
    margin-top: 45px !important;
}

.u-mt50 {
    margin-top: 50px !important;
}

.u-mt60 {
    margin-top: 60px !important;
}

.u-mt70 {
    margin-top: 70px !important;
}

.u-mt80 {
    margin-top: 80px !important;
}

.u-mt90 {
    margin-top: 90px !important;
}

.u-mt100 {
    margin-top: 100px !important;
}

.u-mt110 {
    margin-top: 110px !important;
}

.u-mt120 {
    margin-top: 120px !important;
}

/* margin-right */
.u-mr00 {
    margin-right: 0px !important;
}

.u-mr05 {
    margin-right: 5px !important;
}

.u-mr10 {
    margin-right: 10px !important;
}

.u-mr15 {
    margin-right: 15px !important;
}

.u-mr20 {
    margin-right: 20px !important;
}

.u-mr25 {
    margin-right: 25px !important;
}

.u-mr30 {
    margin-right: 30px !important;
}

.u-mr35 {
    margin-right: 35px !important;
}

.u-mr40 {
    margin-right: 40px !important;
}

.u-mr45 {
    margin-right: 45px !important;
}

.u-mr50 {
    margin-right: 50px !important;
}

.u-mr60 {
    margin-right: 60px !important;
}

.u-mr70 {
    margin-right: 70px !important;
}

.u-mr80 {
    margin-right: 80px !important;
}

.u-mr90 {
    margin-right: 90px !important;
}

.u-mr100 {
    margin-right: 100px !important;
}

/* margin-bottom */
.u-mb00 {
    margin-bottom: 0 !important;
}

.u-mb05 {
    margin-bottom: 5px !important;
}

.u-mb10 {
    margin-bottom: 10px !important;
}

.u-mb15 {
    margin-bottom: 15px !important;
}

.u-mb20 {
    margin-bottom: 20px !important;
}

.u-mb25 {
    margin-bottom: 25px !important;
}

.u-mb30 {
    margin-bottom: 30px !important;
}

.u-mb35 {
    margin-bottom: 35px !important;
}

.u-mb40 {
    margin-bottom: 40px !important;
}

.u-mb45 {
    margin-bottom: 45px !important;
}

.u-mb50 {
    margin-bottom: 50px !important;
}

.u-mb60 {
    margin-bottom: 60px !important;
}

.u-mb70 {
    margin-bottom: 70px !important;
}

.u-mb80 {
    margin-bottom: 80px !important;
}

.u-mb90 {
    margin-bottom: 90px !important;
}

.u-mb100 {
    margin-bottom: 100px !important;
}

/* margin-left */
.u-ml00 {
    margin-left: 0 !important;
}

.u-ml05 {
    margin-left: 5px !important;
}

.u-ml10 {
    margin-left: 10px !important;
}

.u-ml15 {
    margin-left: 15px !important;
}

.u-ml20 {
    margin-left: 20px !important;
}

.u-ml25 {
    margin-left: 25px !important;
}

.u-ml30 {
    margin-left: 30px !important;
}

.u-ml35 {
    margin-left: 35px !important;
}

.u-ml40 {
    margin-left: 40px !important;
}

.u-ml45 {
    margin-left: 45px !important;
}

.u-ml50 {
    margin-left: 50px !important;
}

.u-ml60 {
    margin-left: 60px !important;
}

.u-ml70 {
    margin-left: 70px !important;
}

.u-ml80 {
    margin-left: 80px !important;
}

.u-ml90 {
    margin-left: 90px !important;
}

.u-ml100 {
    margin-left: 100px !important;
}

.u-ml1em {
    margin-left: 1em !important;
}

/* margin-all */
.ma00 {
    margin: 0 !important;
}

/* padding-top */
.u-pt00 {
    padding-top: 0 !important;
}

.u-pt10 {
    padding-top: 10px !important;
}

.u-pt15 {
    padding-top: 15px !important;
}

.u-pt20 {
    padding-top: 20px !important;
}

.u-pt25 {
    padding-top: 25px !important;
}

.u-pt30 {
    padding-top: 30px !important;
}

.u-pt35 {
    padding-top: 35px !important;
}

.u-pt40 {
    padding-top: 40px !important;
}

.u-pt45 {
    padding-top: 45px !important;
}

.u-pt50 {
    padding-top: 50px !important;
}

.u-pt60 {
    padding-top: 60px !important;
}

.u-pt70 {
    padding-top: 70px !important;
}

.u-pt80 {
    padding-top: 80px !important;
}

.u-pt90 {
    padding-top: 90px !important;
}

.u-pt100 {
    padding-top: 100px !important;
}

/* padding-right */
.u-pr00 {
    padding-right: 0 !important;
}

.u-pr10 {
    padding-right: 10px !important;
}

.u-pr15 {
    padding-right: 15px !important;
}

.u-pr20 {
    padding-right: 20px !important;
}

.u-pr25 {
    padding-right: 25px !important;
}

.u-pr30 {
    padding-right: 30px !important;
}

.u-pr35 {
    padding-right: 35px !important;
}

.u-pr40 {
    padding-right: 40px !important;
}

.u-pr45 {
    padding-right: 45px !important;
}

.u-pr50 {
    padding-right: 50px !important;
}

.u-pr60 {
    padding-right: 60px !important;
}

.u-pr70 {
    padding-right: 70px !important;
}

.u-pr80 {
    padding-right: 80px !important;
}

.u-pr90 {
    padding-right: 90px !important;
}

.u-pr100 {
    padding-right: 100px !important;
}

/* padding-bottom */
.u-pb00 {
    padding-bottom: 0 !important;
}

.u-pb10 {
    padding-bottom: 10px !important;
}

.u-pb15 {
    padding-bottom: 15px !important;
}

.u-pb20 {
    padding-bottom: 20px !important;
}

.u-pb25 {
    padding-bottom: 25px !important;
}

.u-pb30 {
    padding-bottom: 30px !important;
}

.u-pb35 {
    padding-bottom: 35px !important;
}

.u-pb40 {
    padding-bottom: 40px !important;
}

.u-pb45 {
    padding-bottom: 45px !important;
}

.u-pb50 {
    padding-bottom: 50px !important;
}

.u-pb60 {
    padding-bottom: 60px !important;
}

.u-pb70 {
    padding-bottom: 70px !important;
}

.u-pb80 {
    padding-bottom: 80px !important;
}

.u-pb90 {
    padding-bottom: 90px !important;
}

.u-pb100 {
    padding-bottom: 100px !important;
}

/* padding-left */
.u-pl00 {
    padding-left: 0 !important;
}

.u-pl10 {
    padding-left: 10px !important;
}

.u-pl15 {
    padding-left: 15px !important;
}

.u-pl20 {
    padding-left: 20px !important;
}

.u-pl25 {
    padding-left: 25px !important;
}

.u-pl30 {
    padding-left: 30px !important;
}

.u-pl35 {
    padding-left: 35px !important;
}

.u-pl40 {
    padding-left: 40px !important;
}

.u-pl45 {
    padding-left: 45px !important;
}

.u-pl50 {
    padding-left: 50px !important;
}

.u-pl60 {
    padding-left: 60px !important;
}

.u-pl70 {
    padding-left: 70px !important;
}

.u-pl80 {
    padding-left: 80px !important;
}

.u-pl90 {
    padding-left: 90px !important;
}

.u-pl100 {
    padding-left: 100px !important;
}

/* padding-all */
.u-pa00 {
    padding: 0 !important;
}

/* flex justify-content */
.u-alignItemsStart {
    align-items: flex-start !important;
}

.u-alignItemsCenter {
    align-items: center !important;
}

.u-alignItemsEnd {
    align-items: flex-end !important;
}

/* flex justify-content */
.u-justifyStart {
    justify-content: flex-start !important;
}

.u-justifyCenter {
    justify-content: center !important;
}

.u-justifyEnd {
    justify-content: flex-end !important;
}

/* font-size */
.u-text10 {
    font-size: 1rem !important;
}

.u-text12 {
    font-size: 1.2rem !important;
}

.u-text14 {
    font-size: 1.4rem !important;
}

.u-text16 {
    font-size: 1.6rem !important;
}

.u-text18 {
    font-size: 1.8rem !important;

    @include mixin.tab {
        font-size: 1.4rem !important;
    }
}

.u-text20 {
    font-size: 2rem !important;

    @include mixin.tab {
        font-size: 1.8rem !important;
    }
}

.u-text24 {
    font-size: 2.4rem !important;

    @include mixin.tab {
        font-size: 1.6rem !important;
    }
}

/* font-weight */
.u-bold {
    font-weight: 700 !important;

    >li,
    p {
        font-weight: 700 !important;
    }
}


/* vertical-align */
.u-vaTop {
    vertical-align: top !important;
}

.u-vaMiddle {
    vertical-align: middle !important;
}

.u-vaBottom {
    vertical-align: bottom !important;
}

/* float */
.u-floatLeft {
    float: left !important;
}

.u-floatRight {
    float: right !important;
}

/* text-indent */
.u-indent1em {
    text-indent: -1em !important;
    padding-left: 1em !important;
}

.u-indent2em {
    text-indent: -2em !important;
    padding-left: 2em !important;
}

.u-indent3em {
    text-indent: -3em !important;
    padding-left: 3em !important;
}

.u-indent4em {
    text-indent: -4em !important;
    padding-left: 4em !important;
}

.u-indent5em {
    text-indent: -5em !important;
    padding-left: 5em !important;
}

/* add 2023/03/09 */
.u-indent9em {
    padding-left: 9.5em !important;
}

/* text-indent */
.u-lineHeight2 {
    line-height: 2 !important;
}

@include mixin.pc {
    .u-floatNoneTb {
        float: none !important;
    }
}

@include mixin.tab {

    /* 幅（%） */
    .u-w050parSp {
        width: 50% !important;
    }

    .u-w055parSp {
        width: 55% !important;
    }

    .u-w060parSp {
        width: 60% !important;
    }

    .u-w065parSp {
        width: 65% !important;
    }

    .u-w070parSp {
        width: 70% !important;
    }

    .u-w100parSp {
        width: 100% !important;
    }

    /* margin-top */
    .u-mt00Sp {
        margin-top: 0px !important;
    }

    .u-mt05Sp {
        margin-top: 5px !important;
    }

    .u-mt10Sp {
        margin-top: 10px !important;
    }

    .u-mt15Sp {
        margin-top: 15px !important;
    }

    .u-mt20Sp {
        margin-top: 20px !important;
    }

    .u-mt25Sp {
        margin-top: 25px !important;
    }

    .u-mt30Sp {
        margin-top: 30px !important;
    }

    .u-mt35Sp {
        margin-top: 35px !important;
    }

    .u-mt40Sp {
        margin-top: 40px !important;
    }

    .u-mt45Sp {
        margin-top: 45px !important;
    }

    .u-mt50Sp {
        margin-top: 50px !important;
    }

    .u-mt60Sp {
        margin-top: 60px !important;
    }

    .u-mt70Sp {
        margin-top: 70px !important;
    }

    .u-mt80Sp {
        margin-top: 80px !important;
    }

    .u-mt90Sp {
        margin-top: 90px !important;
    }

    .u-mt100Sp {
        margin-top: 100px !important;
    }

    .u-mt110Sp {
        margin-top: 110px !important;
    }

    .u-mt120Sp {
        margin-top: 120px !important;
    }

    /* margin-bottom */
    .u-mb00Sp {
        margin-bottom: 0 !important;
    }

    .u-mb05Sp {
        margin-bottom: 5px !important;
    }

    .u-mb10Sp {
        margin-bottom: 10px !important;
    }

    .u-mb15Sp {
        margin-bottom: 15px !important;
    }

    .u-mb20Sp {
        margin-bottom: 20px !important;
    }

    .u-mb25Sp {
        margin-bottom: 25px !important;
    }

    .u-mb30Sp {
        margin-bottom: 30px !important;
    }

    .u-mb35Sp {
        margin-bottom: 35px !important;
    }

    .u-mb40Sp {
        margin-bottom: 40px !important;
    }

    .u-mb45Sp {
        margin-bottom: 45px !important;
    }

    .u-mb50Sp {
        margin-bottom: 50px !important;
    }

    .u-mb60Sp {
        margin-bottom: 60px !important;
    }

    .u-mb70Sp {
        margin-bottom: 70px !important;
    }

    .u-mb80Sp {
        margin-bottom: 80px !important;
    }

    .u-mb90Sp {
        margin-bottom: 90px !important;
    }

    .u-mb100Sp {
        margin-bottom: 100px !important;
    }

    /* margin-right */
    .u-mr00Sp {
        margin-right: 0 !important;
    }

    .u-mr05Sp {
        margin-right: 5px !important;
    }

    .u-mr10Sp {
        margin-right: 10px !important;
    }

    .u-mr15Sp {
        margin-right: 15px !important;
    }

    .u-mr20Sp {
        margin-right: 20px !important;
    }

    .u-mr25Sp {
        margin-right: 25px !important;
    }

    .u-mr30Sp {
        margin-right: 30px !important;
    }

    .u-mr35Sp {
        margin-right: 35px !important;
    }

    .u-mr40Sp {
        margin-right: 40px !important;
    }

    .u-mr45Sp {
        margin-right: 45px !important;
    }

    .u-mr50Sp {
        margin-right: 50px !important;
    }

    .u-mr60Sp {
        margin-right: 60px !important;
    }

    .u-mr70Sp {
        margin-right: 70px !important;
    }

    .u-mr80Sp {
        margin-right: 80px !important;
    }

    .u-mr90Sp {
        margin-right: 90px !important;
    }

    .u-mr100Sp {
        margin-right: 100px !important;
    }


    /* padding-top */
    .u-pt00Sp {
        padding-top: 0 !important;
    }

    .u-pt10Sp {
        padding-top: 10px !important;
    }

    .u-pt15Sp {
        padding-top: 15px !important;
    }

    .u-pt20Sp {
        padding-top: 20px !important;
    }

    .u-pt25Sp {
        padding-top: 25px !important;
    }

    .u-pt30Sp {
        padding-top: 30px !important;
    }

    .u-pt35Sp {
        padding-top: 35px !important;
    }

    .u-pt40Sp {
        padding-top: 40px !important;
    }

    .u-pt45Sp {
        padding-top: 45px !important;
    }

    .u-pt50Sp {
        padding-top: 50px !important;
    }

    .u-pt60Sp {
        padding-top: 60px !important;
    }

    .u-pt70Sp {
        padding-top: 70px !important;
    }

    .u-pt80Sp {
        padding-top: 80px !important;
    }

    .u-pt90Sp {
        padding-top: 90px !important;
    }

    .u-pt100Sp {
        padding-top: 100px !important;
    }

    /* padding-right */
    .u-pr00Sp {
        padding-right: 0 !important;
    }

    .u-pr10Sp {
        padding-right: 10px !important;
    }

    .u-pr15Sp {
        padding-right: 15px !important;
    }

    .u-pr20Sp {
        padding-right: 20px !important;
    }

    .u-pr25Sp {
        padding-right: 25px !important;
    }

    .u-pr30Sp {
        padding-right: 30px !important;
    }

    .u-pr35Sp {
        padding-right: 35px !important;
    }

    .u-pr40Sp {
        padding-right: 40px !important;
    }

    .u-pr45Sp {
        padding-right: 45px !important;
    }

    .u-pr50Sp {
        padding-right: 50px !important;
    }

    .u-pr60Sp {
        padding-right: 60px !important;
    }

    .u-pr70Sp {
        padding-right: 70px !important;
    }

    .u-pr80Sp {
        padding-right: 80px !important;
    }

    .u-pr90Sp {
        padding-right: 90px !important;
    }

    .u-pr100Sp {
        padding-right: 100px !important;
    }

    /* padding-bottom */
    .u-pb00Sp {
        padding-bottom: 0 !important;
    }

    .u-pb10Sp {
        padding-bottom: 10px !important;
    }

    .u-pb15Sp {
        padding-bottom: 15px !important;
    }

    .u-pb20Sp {
        padding-bottom: 20px !important;
    }

    .u-pb25Sp {
        padding-bottom: 25px !important;
    }

    .u-pb30Sp {
        padding-bottom: 30px !important;
    }

    .u-pb35Sp {
        padding-bottom: 35px !important;
    }

    .u-pb40Sp {
        padding-bottom: 40px !important;
    }

    .u-pb45Sp {
        padding-bottom: 45px !important;
    }

    .u-pb50Sp {
        padding-bottom: 50px !important;
    }

    .u-pb60Sp {
        padding-bottom: 60px !important;
    }

    .u-pb70Sp {
        padding-bottom: 70px !important;
    }

    .u-pb80Sp {
        padding-bottom: 80px !important;
    }

    .u-pb90Sp {
        padding-bottom: 90px !important;
    }

    .u-pb100Sp {
        padding-bottom: 100px !important;
    }

    /* padding-left */
    .u-pl00Sp {
        padding-left: 0 !important;
    }

    .u-pl10Sp {
        padding-left: 10px !important;
    }

    .u-pl15Sp {
        padding-left: 15px !important;
    }

    .u-pl20Sp {
        padding-left: 20px !important;
    }

    .u-pl25Sp {
        padding-left: 25px !important;
    }

    .u-pl30Sp {
        padding-left: 30px !important;
    }

    .u-pl35Sp {
        padding-left: 35px !important;
    }

    .u-pl40Sp {
        padding-left: 40px !important;
    }

    .u-pl45Sp {
        padding-left: 45px !important;
    }

    .u-pl50Sp {
        padding-left: 50px !important;
    }

    .u-pl60Sp {
        padding-left: 60px !important;
    }

    .u-pl70Sp {
        padding-left: 70px !important;
    }

    .u-pl80Sp {
        padding-left: 80px !important;
    }

    .u-pl90Sp {
        padding-left: 90px !important;
    }

    .u-pl100Sp {
        padding-left: 100px !important;
    }

    /* padding-all */
    .u-pa00Sp {
        padding: 0 !important;
    }

    /* font-size */
    .u-text10Sp {
        font-size: 1rem !important;
    }

    .u-text12Sp {
        font-size: 1.2rem !important;
    }

    .u-text14Sp {
        font-size: 1.4rem !important;
    }

    .u-text16Sp {
        font-size: 1.6rem !important;
    }

    .u-text18Sp {
        font-size: 1.8rem !important;
    }

    .u-text24Sp {
        font-size: 2.4rem !important;
    }
}

/*----------------------------------------------------
block hidden
------------------------------------------------------*/
.u-pc {
    display: block;

    @include mixin.tab {
        display: none;
    }
}

.u-tab {
    display: none;

    @include mixin.tab {
        display: block;
    }
}

.u-sp {
    display: none;

    @include mixin.sp {
        display: block;
    }
}

.u-tabSm {
    display: none;

    @include mixin.tabSm {
        display: block;
    }
}

.u-tabXs {
    display: none;

    @include mixin.tabXs {
        display: block;
    }
}