@use "mixin";

/*--------------------------------------------------------------------------------------------------------
layout
----------------------------------------------------------------------------------------------------------*/
/*----------------------------------------------------
footer
------------------------------------------------------*/
.footer-english,
.footer-chinese,
.footer-common {
    .inner {
        >.head {
            @include mixin.articleBody;

            width: 100%;
            padding-top: 80px;
            padding-bottom: 80px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 40px;

            @include mixin.pcSm {
                padding: 0;
                @include mixin.flex;
                align-items: flex-start;
                flex-direction: column;
                align-items: center;
                gap: 0;
            }

        }

        >.foot {
            @include mixin.articleBody;
        }
    }

}

/*--------------------------------------------------------------------------------------------------------
module
----------------------------------------------------------------------------------------------------------*/
/*----------------------------------------------------
footer
------------------------------------------------------*/
.footer-english,
.footer-chinese,
.footer-common {
    background-color: mixin.$mainBlue;

    .col {
        @include mixin.pcSm {
            width: 100%;
        }
    }

    .row {
        .head-item {
            width: 100%;

            @include mixin.pcSm {
                margin: 0;
            }

            &:not(:last-child) {
                margin-bottom: 0;

                @include mixin.pcSm {
                    margin-bottom: 0;
                }
            }

            >*:not(:last-child):not(.accordion-title) {
                margin-bottom: 30px;

                @include mixin.pcSm {
                    margin-bottom: 0;
                }
            }

            >label,
            >.sub-title {
                width: 100%;
                display: inline-block;
                font-size: 1.8rem;
                margin: 0;

                @media (max-width: 991px) {
                    font-size: 1.6rem;
                }

                @include mixin.pcSm {
                    width: auto;
                    display: inline-block;

                    background-color: inherit;
                    color: mixin.$mainWhite;

                    @include mixin.flex;
                    justify-content: flex-start;
                    // height: 50px;
                    height: 72px;
                    font-size: 1.8rem;
                    padding: 0 0 0 20px;
                    font-weight: 400;
                }

                &:not(:last-child) {
                    margin-bottom: 4px;

                    @include mixin.pcSm {
                        margin-bottom: 0;
                    }
                }
            }

            >label .sub-title,
            >.sub-title {
                font-weight: 700;
                color: mixin.$mainWhite;
            }

            >.sub-title {
                @include mixin.pcSm {
                    &::after {
                        content: "";
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background-image: url(../images/icon/icon-allow01.svg);
                        background-size: 20px 20px;
                        background-repeat: no-repeat;
                        background-position: right 34px top 52%;
                    }
                }
            }
        }

        .head-item>.sub-title,
        .accordion-title {
            margin-bottom: 8px;

            @include mixin.pcSm {
                margin: 0;
                // border-bottom: 1px solid mixin.$subBlue;
            }

        }

        .sub-list {
            @include mixin.flex;
            align-items: flex-start;
            flex-wrap: wrap;
            line-height: 1.5;

            .list-item {
                width: 100%;
                position: relative;
                padding-left: 1em;

                @include mixin.pcSm {
                    padding-left: 0;
                    width: 100%;

                    background-image: none;
                }

                a {
                    display: inline-block;
                    padding: 3px;
                    color: mixin.$mainWhite;
                    position: relative;

                    @include mixin.pcSm {
                        width: 100%;
                        // height: 50px;
                        height: 64px;
                        padding: 0 15px;
                        font-size: 1.6rem;
                        @include mixin.flex;
                        align-items: center;

                        &::after {
                            content: "";
                            position: absolute;
                            margin: auto;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            background-image: url(../images/icon/icon-allow01.svg);
                            background-size: 20px 20px;
                            background-repeat: no-repeat;
                            background-position: right 34px top 52%;
                        }
                    }


                }
            }

            br {
                display: none;

                @media (max-width: 991px) {
                    display: inline-block;
                }

                @include mixin.pcSm {
                    display: none;
                }
            }
        }

        .accordion-title+.sub-list {
            @include mixin.pcSm {
                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                background-color: mixin.$subBlue;
            }

            .list-item {

                &:last-child {
                    @include mixin.pcSm {
                        border-bottom: none;
                    }
                }

                a {
                    @include mixin.pcSm {
                        width: 100%;
                        // height: 50px;
                        height: 64px;
                        padding: 0 0 0 30px;
                        font-size: 1.6rem;
                        @include mixin.flex;
                        align-items: center;
                        color: mixin.$mainWhite;

                        // border-bottom: 1px solid mixin.$darkBlue;
                    }

                    &::before {
                        content: none;
                    }

                    &::after {
                        content: none;
                    }


                }
            }
        }

    }

    .copy {
        color: mixin.$mainWhite;
        text-align: center;
        padding: 20px 0;
        font-size: 1.4rem;

        @include mixin.pcSm {
            padding: 10px 0;
            font-size: 1.2rem;
        }

        br {
            display: none;

            @include mixin.tabXs {
                display: block;
            }
        }
    }

    .foot {
        @include mixin.pcSm {
            background-color: mixin.$darkBlue;
        }
    }
}

/*----------------------------------------------------
contact
------------------------------------------------------*/
.contact-english,
.contact-chinese,
.contact {
    background-color: mixin.$subBlue;

    >.inner {
        padding-top: 0;
        padding-bottom: 60px;
    }

    .headingTwo02 {
        color: mixin.$mainWhite;

        @include mixin.pc {
            padding: 56px 0 0;
        }



        &::before {
            background-color: mixin.$mainWhite;

            @include mixin.pc {
                height: 48px;
            }
        }
    }

    .flex-area {
        max-width: 1120px;
        margin: 0 auto;

        @include mixin.flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        @include mixin.tab {
            justify-content: center;
            gap: 20px 10px;
        }

        >* {
            width: calc(33.3333% - 20px);

            @include mixin.pc {
                width: calc(33.3333% - 20px);
            }

            @include mixin.tab {
                width: calc(50% - 10px);
            }

            @include mixin.sp {
                width: 100%;
            }

        }

    }

    button.button-contact,
    a.button-contact {
        @include mixin.flex;
        justify-content: center;
        width: 100%;
        max-width: 320px;
        height: 54px;
        border-radius: 9999px;
        background-color: mixin.$mainWhite;
        border: 1px solid mixin.$mainBlue;
        box-shadow: 0px 3px 6px #24437733;
        color: mixin.$mainBlue;
        font-weight: 700;
        font-size: 1.6rem;
        letter-spacing: 0.1em;
        cursor: pointer;

        position: relative;

        @include mixin.tab {
            max-width: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-image: url(../images/icon/icon-allow01-blue.svg);
            background-size: 24px 24px;
            background-repeat: no-repeat;
            background-position: right 18px top 52%;
        }

        @include mixin.sp {
            width: 100%;
            max-width: 100%;
            // height: 36px;
            font-size: 1.4rem;
            padding: 5px 20px;
        }

        &:hover {
            background: mixin.$mainBlue;
            color: mixin.$mainWhite;
            box-shadow: 0px 1px 3px #24437733;
            outline: 1px solid mixin.$mainWhite;

            &::before {
                background-image: url(../images/icon/icon-allow01.svg);
            }
        }

    }

}

/*--------------------------------------------------------------------------------------------------------
State
----------------------------------------------------------------------------------------------------------*/
/*----------------------------------------------------
Footer accordion
------------------------------------------------------*/
.footer-english,
.footer-chinese,
.footer-common {
    .accordion-title {

        &::before,
        &::after {
            display: none;
            background-color: mixin.$mainWhite;

            @include mixin.pcSm {
                display: block;
            }
        }

        &::before {
            content: none;

            @include mixin.pcSm {
                content: "";
                position: absolute;
                display: block;
                right: 44px;
                top: 1.25em;
                bottom: initial;
                left: initial;
                width: 2px;
                height: 0.75em;
                transform: initial;
                transition: all 0.3s;
            }
        }

        &::after {

            @include mixin.pcSm {
                right: 44px;
            }
        }
    }

    .sub-list {
        &.accordion-content {
            -webkit-backface-visibility: visible;
            backface-visibility: visible;
            max-height: inherit;
            overflow: visible;

            @include mixin.pcSm {
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                max-height: 0;
                overflow: hidden;
            }


        }
    }

    .toggle:checked+.accordion-title+.sub-list.accordion-content {
        @include mixin.pcSm {
            max-height: 1000px;
            transition: all 0.5s;
        }
    }
}