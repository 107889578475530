@use "mixin";

/*----------------------------------------------------
contents-wrap
------------------------------------------------------*/
.contents-wrap {
    background-image: url(../images/common/bg-mv.svg), ;
    background-repeat: no-repeat;
    background-size: 120vw;
    background-position: center top -8vw;

    @include mixin.pc1728 {
        background-position: center top -5vw;
    }

    @include mixin.pcXl {
        background-position: center top -2vw;
        background-size: 116vw;
    }

    @include mixin.pcSm {
        background-position: center top 1vw;
    }

    @include mixin.pcXs {
        background-position: center top 5vw;
    }

    @include mixin.tab {
        background-position: right -24vw top -5vw;
        background-size: 160vw;
    }

    @include mixin.tabSm {
        background-position: right -184px top -10px;
        background-size: 1220px;
    }

    @include mixin.tabXs {
        background-position: right -24vw top -5vw;
    }

    @include mixin.sp {
        background-position: right -48vw top -64px;
    }

}

/*----------------------------------------------------
page-header
------------------------------------------------------*/
.page-header {
    padding-top: 140px;
    padding-bottom: 120px;

    position: relative;

    @include mixin.pcXxl {
        padding-top: 108px;
        padding-bottom: 80px;
    }

    @include mixin.tab {
        padding-top: 88px;
        padding-bottom: 40px;
    }

    @include mixin.sp {
        padding-top: 68px;
    }

    .headingOne01 {
        margin-bottom: 0;

        &:not(:last-child) {
            @include mixin.tabSm {
                margin-bottom: 20px;
            }
        }
    }

    .image {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        overflow: hidden;
        z-index: -1;

        width: 20vw;
        height: 20vw;
        max-width: 400px;
        max-height: 400px;
        animation: fluidPageMv 30s ease 0s infinite;

        @include mixin.tab {
            width: 35vw;
            height: 35vw;
            bottom: 40px;
        }

        .swiper.pageSwiper {
            width: 100%;
            height: 100%;

            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }

    .logo {
        position: absolute;
        bottom: 100px;
        right: 0;
        overflow: hidden;

        width: 25vw;
        height: fit-content;
        max-width: 320px;
        max-height: 400px;
        padding: 20px;

        border-radius: 10px;
        background-color: mixin.$mainWhite;

        @include mixin.pcXxl {
            bottom: 32px;
        }

        @include mixin.pcXl {
            right: 20px;
        }

        @include mixin.pc {
            bottom: 20px;
        }

        @include mixin.tab {
            width: 30vw;
            bottom: inherit;
            top: 32px;
            bottom: inherit;
            padding: 16px;
        }

        @include mixin.tabSm {
            position: inherit;
            top: inherit;
            right: inherit;
            width: 100%;
            height: auto;
            max-width: 600px;
            max-height: inherit;
            padding: 10px;
        }

    }
}

@keyframes fluidPageMv {

    0%,
    100% {
        border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    }

}

/*----------------------------------------------------
page-main
------------------------------------------------------*/
.page-main {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: calc(var(--vw) * 100);
        height: 240px;
        margin: 0 calc(50% - 50vw);
        background: linear-gradient(180deg, rgba(mixin.$bgBlue, 0) 0%, rgba(mixin.$bgBlue, 0.4) 100%) 0% 0% no-repeat;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        clip-path: polygon(100% 0, 100% 28%, calc(50% - 624px) 100%, 0 36%, 0 0);
        overflow: hidden;

        @include mixin.pcLg {
            clip-path: polygon(100% 0, 100% 28%, calc(50% - 582px) 100%, 0 40%, 0 0);
        }

        @include mixin.pc {
            clip-path: polygon(100% 0, 100% 28%, 38px 100%, 0 40%, 0 0);
            top: -40px;
        }

        @include mixin.pcSm {
            clip-path: polygon(100% 0, 100% 36%, 38px 100%, 0 64%, 0 0);
            top: 0;
        }

        @include mixin.tab {
            clip-path: polygon(100% 0, 100% 36%, 26px 100%, 0 72%, 0 0);
            top: 100px;
        }

        @include mixin.sp {
            top: 200px;
        }
    }

    .page-block-common {
        @include mixin.articleBody;

        .lead-area {
            width: calc(100% - 20vw);
            min-width: calc(100% - 400px);
            margin-top: -64px;
            text-align: justify;
            text-shadow: 1px 1px 10px mixin.$mainWhite, 1px 1px 2px mixin.$mainWhite, 1px 1px 4px mixin.$mainWhite;

            @include mixin.pcXxl {
                margin-top: -40px;
            }

            @include mixin.tab {
                margin-top: 0;
                padding-top: 2.5vw;
                width: 100%;
                min-width: 100%;
            }

            @include mixin.tabXs {
                padding-top: 0;
            }

            >*:not(:last-child) {
                margin-bottom: 24px;

                @include mixin.pcXxl {
                    margin-bottom: 16px;
                }

                @include mixin.tab {
                    margin-bottom: 14px;
                }
            }
        }
    }

    .page-block-lg {
        @include mixin.articleBodyLg;
    }

    .page-block-lead {
        max-width: 1000px;
        width: 100%;
    }

    >*[class^="page-block-"] {
        position: relative;

        &.bg-pink-prev {
            z-index: 2;
        }

        &.bg-pink {
            &::before {
                content: "";
                position: absolute;
                width: calc(var(--vw) * 100);
                height: 240px;
                margin: 0 calc(50% - 50vw);
                background: linear-gradient(180deg, rgba(mixin.$subPink, 0) 0%, rgba(mixin.$subPink, 0.2) 100%) 0% 0% no-repeat;
                top: -280px;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: -1;

                clip-path: polygon(100% 0, 100% 28%, calc(50% - 624px) 100%, 0 36%, 0 0);
                overflow: hidden;

                @include mixin.pcLg {
                    clip-path: polygon(100% 0, 100% 28%, calc(50% - 582px) 100%, 0 40%, 0 0);
                }

                @include mixin.pc {
                    clip-path: polygon(100% 0, 100% 28%, 38px 100%, 0 40%, 0 0);
                }

                @include mixin.pcSm {
                    clip-path: polygon(100% 0, 100% 36%, 38px 100%, 0 64%, 0 0);
                }

                @include mixin.tab {
                    clip-path: polygon(100% 0, 100% 36%, 26px 100%, 0 72%, 0 0);
                }
            }
        }

        &.search-list-area-include {
            z-index: 1;
        }

        &:not(:last-child) {
            margin-bottom: 120px;

            @include mixin.pc {
                margin-bottom: 80px;
            }

            @include mixin.pcSm {
                margin-bottom: 64px;
            }
        }

        >.clearfix>*:not(figure),
        >*:not(.iframe-wrap) ,
        >*:not(:last-child) {
            margin-bottom: 32px;

            @include mixin.pcXl {
                margin-bottom: 24px;
            }

            @include mixin.tab {
                margin-bottom: 16px;
            }
        }

        >.clearfix>.headingTwo02-prev,
        >.headingTwo02-prev {
            margin-bottom: 120px;

            @include mixin.pc {
                margin-bottom: 80px;
            }

            @include mixin.pcSm {
                margin-bottom: 64px;
            }
        }

        >.headingThree01-prev {
            margin-bottom: 120px;

            @include mixin.pc {
                margin-bottom: 80px;
            }

            @include mixin.pcSm {
                margin-bottom: 64px;
            }
        }

        >.headingFour01-prev {
            margin-bottom: 80px;

            @include mixin.pc {
                margin-bottom: 64px;
            }

            @include mixin.pcSm {
                margin-bottom: 40px;
            }
        }

        &.product-feature {
            >*:not(:last-child) {
                margin-bottom: 32px;
            }
        }

        .flex-area {
            @include mixin.flex;
            align-items: flex-start;
            gap: 160px;

            @include mixin.pc {
                gap: 80px;
            }

            @include mixin.pcSm {
                flex-direction: column;
                gap: 40px;
            }

            @include mixin.tab {
                gap: 20px;
            }

            .description {
                >*:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
        }

        .product-logo {
            @include mixin.flex;
            justify-content: flex-start;

            >img {
                display: block;
                width: 100%;
                max-width: 305px;
            }
        }

       .brand-compare-frame {
            width: 100%;
            height: 1400px;
        }
    }

    >*[class^="page-block-"].mb-md {
        &:not(:last-child) {
            margin-bottom: 160px;

            @include mixin.pc {
                margin-bottom: 120px;
            }

            @include mixin.pcSm {
                margin-bottom: 80px;
            }
        }
    }

    >*[class^="page-block-"].mb-lg {
        &:not(:last-child) {
            margin-bottom: 200px;

            @include mixin.pc {
                margin-bottom: 160px;
            }

            @include mixin.pcSm {
                margin-bottom: 80px;
            }
        }
    }
}

/*----------------------------------------------------
box
------------------------------------------------------*/
.page-block-lg {
    >*[class^="box-"] {
        padding: 80px 96px;

        @include mixin.pcXxl {
            padding: 60px;
        }

        @include mixin.pcXl {
            padding: 40px 60px;
        }

        @include mixin.pc {
            padding: 40px;
        }

        @include mixin.tabXs {
            padding: 40px 20px;
        }

        p,
        li {
            font-size: 1.8rem;
            font-weight: 700;

            @include mixin.pcXxl {
                font-size: 1.6rem;
            }

            @include mixin.tab {
                font-size: 1.4rem;
            }
        }
    }
}

/*----------------------------------------------------
button
------------------------------------------------------*/
.button-block {
    max-width: 1240px;
    margin: 0 auto;
    @include mixin.flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    @include mixin.tab {
        justify-content: space-between;
        gap: 10px;
    }

    @include mixin.sp {
        padding-left: 15px;
        padding-right: 15px;
    }

    >* {
        width: calc(33.3333% - 20px);

        @include mixin.tab {
            width: calc(50% - 10px);
            margin: 0 auto;
        }

        @include mixin.sp {
            width: 100%;
        }

    }
}

.button-block-wrap {
    max-width: 1240px;
    margin: 0 auto;

    p {
        text-align: center;
        font-size: 1.4rem;
    }

    >*:not(:last-child) {
        margin-bottom: 10px;
    }
}

.button-area {
    width: auto;
    margin: 0 auto;
    @include mixin.flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    @include mixin.tab {
        justify-content: space-between;
        gap: 10px;
    }
}