@use "mixin";


/*----------------------------------------------------
ステップバー
------------------------------------------------------*/
.progressbar {
    max-width: 584px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 0;
    @include mixin.flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    gap: 160px;

    @include mixin.tabXs {
        gap: 40px;
    }

    li {
        position: relative;
        width: 88px;
        padding-top: 45px;
        // color: mixin.$mainLightGray;
        color: darken(mixin.$mainLightGray, 30%);
        text-align: center;
        counter-increment: steps;

        @include mixin.tabXs {
            width: 33%;
            font-size: 1.4rem;
            line-height: 1.4;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 50%;
            display: inline-block;
            width: 34px;
            height: 34px;
            margin-left: -17px;
            // border: 1px solid mixin.$mainLightGray;
            border: 1px solid darken(mixin.$mainLightGray, 30%);
            border-radius: 50%;
            line-height: 32px;
            font-size: 1.3rem;
            // color: mixin.$mainLightGray;
            color: darken(mixin.$mainLightGray, 30%);
            text-align: center;
            content: counter(steps);
        }

        &::after {
            content: "";
            position: absolute;
            top: 18px;
            left: -144px;
            display: inline-block;
            width: 126px;
            height: 2px;
            margin-top: -1px;
            border-top: 1px solid darken(mixin.$mainLightGray, 30%);

            @include mixin.tabXs {
                left: -39.2%;
                width: 42%;
            }
        }

        &:first-child {
            &::after {
                content: none;
            }
        }

        &.active,
        &.complete {
            font-weight: 700;
            color: mixin.$mainBlue;

            &::before {
                font-weight: 700;
                border-color: mixin.$mainBlue;
                color: mixin.$mainBlue;
            }

            &::after {
                border-top: 1px solid mixin.$mainBlue;
            }
        }
    }
}


/*----------------------------------------------------
Form / 表系 
------------------------------------------------------*/
.form-list {
    width: 100%;

    >.list-item {
        width: 100%;
        @include mixin.flex;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0 1px 1px mixin.$mainLightGray;
        background-color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-right-color: rgba(255, 255, 255, 0.2);
        border-bottom-color: rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        padding: 32px 20px 24px;

        &:first-child {
            border-radius: 10px 10px 0 0;
        }

        &:last-child {
            border-radius: 0 0 10px 10px;
        }

        &:only-child {
            border-radius: 10px;
        }

        @include mixin.tab {
            flex-direction: column;
            align-items: flex-start;
            padding: 10px;
        }

        // &:last-of-type {
        //     border-bottom: 1px solid mixin.$mainWhite;
        // }

        &.error {
            padding: 32px 20px 8px;

            @include mixin.tab {
                padding: 10px 10px 5px;
            }
        }

        >dt {
            width: 320px;
            height: 40px;
            margin-right: 10px;
            font-weight: 700;
            // text-align: right;
            @include mixin.flex;
            align-items: flex-start;
            justify-content: flex-start;

            @include mixin.tab {
                width: 100%;
                height: auto;
                margin-bottom: 8px;
                align-items: center;
            }

            span {
                font-weight: 700;

                &:not(:last-of-type) {
                    margin-right: 10px;
                }

                &.title {
                    font-size: 1.8rem;

                    @include mixin.sp {
                        font-size: 1.6rem;
                    }

                    span {
                        font-size: 1.4rem;
                        display: block;
                        color: mixin.$subBlue;

                        @include mixin.tab {
                            display: inline-block;
                            margin: 0 0 0 8px;
                        }
                    }
                }

                .icon-requirement {
                    margin: 5px 0 0;

                    @include mixin.tab {
                        margin: 0;
                    }
                }
            }
        }

        >dd {
            width: calc(100% - 320px);

            @include mixin.tab {
                width: 100%;
            }

            >*:not(:last-child) {
                margin-bottom: 5px;
            }

            .text {
                &:first-child {
                    padding-top: 10px;

                    @include mixin.tab {
                        padding-top: 0;
                    }
                }
            }

            >.text-input-common {
                margin-bottom: 10px;

                +.error-text {
                    margin-top: -5px;
                }
            }

            >.textarea-input-common {
                margin-bottom: 10px;

                +.error-text {
                    margin-top: -5px;
                }
            }
        }

        .list-horizontal {
            justify-content: flex-start;
            gap: 8px;
        }

        .dlist-horizontal {
            @include mixin.tab {
                width: 100%;
            }

            .dlist-item {
                width: 330px;
                @include mixin.flex;
                justify-content: flex-start;
                align-items: flex-start;

                @include mixin.tab {
                    width: 100%;
                }

                &:not(:last-child) {
                    @include mixin.tab {
                        margin-bottom: 10px;
                    }
                }

                dt {
                    width: 40px;
                    text-align: right;
                    margin-right: 10px;
                    margin-top: 9px;

                    @include mixin.tab {
                        margin-top: 5px;
                    }
                }

                dd {
                    margin-right: 0;
                    width: calc(100% - 50px);

                    >*:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    .text-input-middle {
                        width: 264px;

                        @include mixin.tab {
                            width: 100%
                        }

                        +.error-text {
                            margin-top: -5px;
                        }
                    }


                }
            }
        }

    }
}

.form-list>.list-item.details {
    @include mixin.flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 20px 5px;

    @include mixin.tab {
        padding: 10px 10px 5px;
    }

    >dd {
        .dlist-horizontal {
            .dlist-item {
                width: 100%;
                align-items: flex-start;
            }

            dt {
                width: 100px;
                text-align: left;
                margin-right: 0;
            }

            dd {
                @include mixin.tab {
                    width: calc(100% - 1.6rem);
                }
            }
        }
    }
}

.form-list :where(.error-text) {
    font-size: 1.4rem;
}

.form-list> :where(.list-item.name, .list-item.kananame) dd .text span {
    margin-right: 1em;
}

.form-list .list-item>dd> :where(.pulldown-large, input, textarea) {
    &:last-child {
        margin-bottom: 8px;
    }
}

.form-list .list-item>dd>p.text:last-child {
    margin-bottom: 15px;

    @include mixin.tab {
        margin-bottom: 10px;
    }
}

.box-consideration {
    padding: 20px;
    @include mixin.flex;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 0 1px 1px mixin.$mainLightGray;
    background-color: rgba(208, 208, 208, 0.48);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-right-color: rgba(255, 255, 255, 0.2);
    border-bottom-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    border-radius: 10px;

    .check-common {
        pointer-events: auto;

    }
}

.button-plus {
    @include mixin.flex;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    height: 54px;
    border-radius: 9999px;
    box-shadow: 0px 3px 6px #24437733;
    background-color: mixin.$mainWhite;
    border: 1px solid mixin.$mainBlue;
    color: mixin.$mainBlue;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    cursor: pointer;

    position: relative;

    &::before {
        content: "";
        position: absolute;
        right: 8%;
        top: 1.25em;
        width: 2px;
        height: 0.75em;
        background-color: mixin.$mainBlue;
        transition: all 0.3s;
    }

    &::after {
        content: "";
        position: absolute;
        right: 8%;
        top: 1.25em;
        width: 2px;
        height: 0.75em;
        background-color: mixin.$mainBlue;
        transition: all 0.3s;
        transform: rotate(90deg);
    }

    @include mixin.sp {
        width: 100%;
        max-width: 100%;
        font-size: 1.4rem;
        padding: 5px 20px;
    }

    &:hover {
        background: mixin.$subBlue;
        color: mixin.$mainWhite;
        box-shadow: 0px 1px 3px #24437733;

        &::before,
        &::after {
            background-color: mixin.$mainWhite;
        }
    }

}

/*----------------------------------------------------
注意事項
------------------------------------------------------*/
.notes {
    height: 280px;
    padding: 20px 20px 64px;
    border-radius: 10px;
    border: 2px solid mixin.$mainLightGray;
    background-color: mixin.$mainWhite;
    overflow-y: auto;

    .notes-block {
        &:not(:last-child) {
            margin-bottom: 24px
        }
    }

    .notes-block-child {
        &:not(:last-child) {
            margin-bottom: 20px
        }
    }
}

.box-agree {
    padding: 20px;
    @include mixin.flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: mixin.$subBlue;
    border-radius: 10px;
    pointer-events: none;

    &:hover,
    &:active {
        background-color: mixin.$mainBlue;
    }

    .check-common {
        pointer-events: auto;

        .check-input:checked+.check-description::before {
            border: 1px solid mixin.$subBlue;
        }

        .check-description {
            color: mixin.$mainWhite;
        }
    }
}

/*----------------------------------------------------
進む・戻るボタン
------------------------------------------------------*/
.form-block-common {
    .button {
        &-prev {
            @include mixin.flex;
            justify-content: center;
            width: 100%;
            max-width: 400px;
            height: 54px;
            border-radius: 9999px;

            box-shadow: 0px 3px 6px #24437733;
            font-weight: 700;
            font-size: 1.6rem;
            letter-spacing: 0.1em;
            cursor: pointer;

            position: relative;

            background-color: mixin.$mainWhite;
            border: 1px solid mixin.$mainBlue;
            color: mixin.$mainBlue;

            @include mixin.sp {
                width: 100%;
                max-width: 100%;
                font-size: 1.4rem;
                padding: 5px 20px;
            }

            &:hover {
                background: mixin.$subBlue;
                color: mixin.$mainWhite;
                box-shadow: 0px 1px 3px #24437733;
            }
        }

        &-next {
            @include mixin.flex;
            justify-content: center;
            width: 100%;
            max-width: 400px;
            height: 54px;
            border-radius: 9999px;

            box-shadow: 0px 3px 6px #24437733;
            font-weight: 700;
            font-size: 1.6rem;
            letter-spacing: 0.1em;
            cursor: pointer;

            position: relative;


            background: mixin.$mainBlue;
            border: 1px solid mixin.$mainBlue;
            color: mixin.$mainWhite;

            @include mixin.sp {
                width: 100%;
                max-width: 100%;
                font-size: 1.4rem;
                padding: 5px 20px;
            }

            &:hover {
                background-color: mixin.$subBlue;
                box-shadow: 0px 1px 3px #24437733;
            }
        }
    }
}


/*----------------------------------------------------
Error系 
------------------------------------------------------*/
.error-text {
    color: mixin.$mainRed;
}

textarea.error,
input.error,
select.error {
    background-color: mixin.$subPink;
    border-color: mixin.$mainRed;

    &:focus-visible {
        outline-color: mixin.$mainRed;
    }
}

.box-alert {
    @include mixin.flex;
    flex-direction: column;
    box-shadow: 0 1px 1px mixin.$mainLightGray;
    background-color: rgba(251, 183, 165, 0.6);
    border: 1px solid rgba(251, 183, 165, 0.4);
    border-right-color: rgba(251, 183, 165, 0.2);
    border-bottom-color: rgba(251, 183, 165, 0.2);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    padding: 20px;
    border-radius: 10px;

    @include mixin.sp {
        padding: 10px;
    }

    .text {
        color: mixin.$mainRed;
        font-weight: 700;
        background-image: url(../images/icon/icon-alert.svg);
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: left top;
        padding-left: 32px;
        width: 100%;
        line-height: 22px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

    }

    .text-detail {
        padding-left: 34px;
        color: mixin.$mainRed;

        @include mixin.sp {
            padding-left: 0;
        }
    }
}

/*----------------------------------------------------
contact page
------------------------------------------------------*/
.form-block-common {

    >.progressbar {
        &:not(:last-child) {
            margin-bottom: 64px;

            @include mixin.sp {
                margin-bottom: 32px;
            }
        }
    }

    >.box-consideration-prev,
    >.form-list-prev {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    >*:not(:last-child) {
        margin-bottom: 120px;

        @include mixin.pc {
            margin-bottom: 80px;
        }

        @include mixin.pcSm {
            margin-bottom: 64px;
        }
    }

    .button-block-wrap-prev {
        margin-bottom: 60px;

        @include mixin.pc {
            margin-bottom: 40px;
        }

        @include mixin.pcSm {
            margin-bottom: 32px;
        }
    }

    .caution-block {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            // width: calc( var(--vw) * 100 );
            width: calc(var(--vw) * 100);
            height: 20.8vw;
            margin: 0 calc(50% - 50vw);
            top: -24vw;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;

            background-image: url(../images/common/bg-pink.svg);
            background-repeat: no-repeat;
            background-size: calc(var(--vw) * 100) auto;
            background-position: center bottom;

        }

        >*:not(:last-child) {
            margin-bottom: 24px;
        }
    }

}