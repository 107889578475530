.page-main>[class^="page-block-"]>.iframe-wrap {
    margin-bottom: 0;
}

.iframe-wrap {
    overflow-x: auto;
    width: 100%;

    .brand-compare-frame {
        min-width: 590px;
    }
}

table.ResultsTable {
    tr.headerrow {
        td.summary {

            &:nth-last-of-type(1),
            &:nth-last-of-type(2) {
                width: 100px;
            }
        }
    }
}

.en {
    .iframe-wrap {
        .brand-compare-frame {
            min-width: 660px;
        }
    }
}

.ch {
    .iframe-wrap {
        .brand-compare-frame {
            min-width: 600px;
        }
    }
}