@use "mixin";

/*----------------------------------------------------
News list
------------------------------------------------------*/
.news-list {
    @include mixin.tab {
        line-height: 1.5;
    }

    .list-item {
        &:first-child {
            a {
                border-radius: 10px 10px 0 0;
            }
        }

        &:last-child {
            a {
                border-radius: 0 0 10px 10px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 1px;
        }

        a {
            width: 100%;
            @include mixin.flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 5px 20px;
            padding: 24px;
            background-color: mixin.$transparentSubBlue;

            @include mixin.pc {
                gap: 5px 10px;
            }

            @include mixin.tab {
                gap: 5px;
            }

            &:hover {
                opacity: 1;
                background-color: mixin.$transparentSubBlueAlpha;
            }

            time {
                width: 140px;
                white-space: nowrap;
            }

            .list-tag {
                width: 56px;
                height: 22px;

                font-size: 1.4rem;
                @include mixin.flex;
                align-items: center;
                justify-content: center;
                border: 1px solid mixin.$mainBlue;
                border-radius: 3px;
                margin: 2px 0 0;
                padding: 0 0 0 2px;

                @include mixin.tab {
                    height: 20px;
                    font-size: 1.2rem;
                }

                &.news {
                    border-color: mixin.$mainRed;
                    color: mixin.$mainRed;
                }

            }

            .description {
                width: calc(100% - 236px);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;

                @include mixin.tab {
                    width: 100%;
                }

            }
        }
    }
}