@use "mixin";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --vw: 1vw;
}

:where(html) {
  -webkit-text-size-adjust: none;
  color-scheme: dark light;
  font-size: 62.5%;
  box-sizing: border-box;
}

@supports not (min-block-size: 100dvb) {
  :where(html) {
    block-size: 100%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :where(html:focus-within) {
    scroll-behavior: smooth;
  }
}

:where(body) {
  block-size: 100%;
  block-size: 100dvb;
  line-height: 1.5;
  font-family: mixin.$font-family-serif01;
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
  background-color: mixin.$mainWhite;

  @include mixin.tabSm {
    font-size: 1.4rem;
  }

  &.ch {
    font-family: mixin.$font-family-ch;
  }

  &.en {
    font-family: mixin.$font-family-eng;
  }
}

:where(h1, h2, h3, h4, h5, h6, p, th, td, li, dt, dd, a, span, select, figcaption) {
  font-weight: 400;
  letter-spacing: 0.1em;
  color: mixin.$mainBlue;
}

.en :where(h1, h2, h3, h4, h5, h6, p, th, td, li, dt, dd, a, span, select, figcaption) {
  letter-spacing: normal;
}

:where(article, aside, footer, header, nav, section, main, img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}

:where(button, label, select, summary, [role='button'], [role='option']) {
  cursor: pointer;
  background-color: inherit;
  border: none;
}

:where(a, a>span, a:hover, a:visited) {
  color: mixin.$mainBlue;
  text-decoration: none;
}

:where(a:hover, button:hover:not([disabled]), [role='button']:hover) {
  opacity: .7;
}

:where(:disabled) {
  cursor: not-allowed;
}

:where(ul, ol) {
  list-style: none;
}

:where(address) {
  font-style: normal;
}

:where(table) {
  border-collapse: collapse;
  border-spacing: 0;
}

:where(img, picture, svg) {
  max-inline-size: 100%;
  block-size: auto;
  transform: translate(0, 0);
  -webkit-backface-visibility: hidden;
  vertical-align: top;
  image-rendering: -webkit-optimize-contrast;
}

:where(form) {
  width: 100%;
  max-width: 100%;
}

:where(input, button, textarea, select) {
  font: inherit;
  font-size: 2rem;
  color: inherit;
  padding: 5px 10px 6px;
  border-radius: 6px;
  border: 1px solid mixin.$mainLightGray;
  background-color: mixin.$mainWhite;

  @include mixin.sp {
    font-size: 1.6rem;
  }

  &::placeholder {
    color: mixin.$mainBlue;
    opacity: .5;
  }

  &:focus-visible {
    outline: none;
    border: 2px solid mixin.$subBlue;
  }

}

:where([hidden]:not([hidden='until-found'])) {
  display: none !important;
}

// 主にmodal
:where(dialog) {
  margin: auto;
  background-color: mixin.$mainWhite;
  border: none;
  width: auto;
  max-inline-size: 1040px;
  max-block-size: calc(100% - 80px);
  max-block-size: calc(100dvb - 80px);
  inline-size: calc(100% - 40px);

  @include mixin.sp {
    max-block-size: calc(100% - 80px);
    max-block-size: calc(100dvb - 80px);
  }

  &:not([open]) {
    min-block-size: 0;
    min-inline-size: 0;
  }

  &::backdrop {
    // background-color: rgba(64, 64, 64, 0.8);
    background-color: rgba(255, 255, 255, 0.48);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
}

.clearfix {
  display: block;
  *zoom: 1;

  &::before {
    content: "";
    display: block;
    clear: both;
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  @include mixin.tabXs {
    @include mixin.flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}